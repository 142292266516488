import account from './endpoints/account'
import badgeLevels from './endpoints/badgeLevels'
import badges from './endpoints/badges'
import categories from './endpoints/categories'
import categoryIcons from './endpoints/categoryIcons'
import challengeAwardTypes from "./endpoints/challengeAwardTypes";
import challengeRewardTypes from "./endpoints/challengeRewardTypes";
import challengeImages from './endpoints/challengeImages'
import challenges from './endpoints/challenges'
import challengeTypes from './endpoints/challengeTypes'
import coachingItems from './endpoints/coachingItems'
import collaboratorBadgeLevels from './endpoints/collaboratorBadgeLevels'
import collaboratorBadgeSummary from './endpoints/collaboratorBadgeSummary'
import collaboratorChallenges from './endpoints/collaboratorChallenges'
import collaboratorChallengeSummaries from './endpoints/collaboratorChallengeSummaries'
import collaboratorData from './endpoints/collaboratorData'
import collaboratorGoals from './endpoints/collaboratorGoals'
import collaboratorGoalSummaries from './endpoints/collaboratorGoalSummaries'
import collaboratorRewardOrderItems from "./endpoints/collaboratorRewardOrderItems";
import collaboratorRewardOrders from "./endpoints/collaboratorRewardOrders";
import collaboratorRewardOrderSummaries from "./endpoints/collaboratorRewardOrderSummaries";
import collaborators from './endpoints/collaborators'
import configs from './endpoints/configs'
import goalDefinitionLevels from './endpoints/goalDefinitionLevels'
import goalDefinitionLevelsByTeam from './endpoints/goalDefinitionLevelsByTeam'
import goalDefinitionLevelsByCollaborator from './endpoints/goalDefinitionLevelsByCollaborator'
import goalDefinitions from './endpoints/goalDefinitions'
import goalDefinitionRepartitions from './endpoints/goalDefinitionRepartitions'
import goalDefinitionPointRepartitions from './endpoints/goalDefinitionPointRepartitions'
import goalDefinitionPointRepartitionModes from './endpoints/goalDefinitionPointRepartitionModes'
import goalPoints from './endpoints/goalPoints'
import goals from './endpoints/goals'
import goalTypes from './endpoints/goalTypes'
import importLogs from './endpoints/importLogs'
import inAppNotifications from './endpoints/inAppNotifications'
import kpis from './endpoints/kpis'
import levelIcons from './endpoints/levelIcons'
import levels from './endpoints/levels'
import mails from './endpoints/mails'
import managers from './endpoints/managers'
import metabase from './endpoints/metabase'
import partners from "./endpoints/partners";
import periodicities from './endpoints/periodicities'
import periods from './endpoints/periods'
import rewardCategories from "./endpoints/rewardCategories";
import rewardCategoryIcons from './endpoints/rewardCategoryIcons'
import rewardImages from './endpoints/rewardImages'
import rewards from "./endpoints/rewards";
import rewardTypes from "./endpoints/rewardTypes";
import roles from './endpoints/roles'
import systemImages from './endpoints/systemImages'
import superManagers from './endpoints/superManagers'
import teamChallenges from './endpoints/teamChallenges'
import teamChallengeSummaries from './endpoints/teamChallengeSummaries'
import teamCollaboratorChallenges from './endpoints/teamCollaboratorChallenges'
import teamCollaboratorChallengeSummaries from './endpoints/teamCollaboratorChallengeSummaries'
import teamCollaboratorGoals from './endpoints/teamCollaboratorGoals'
import teamCollaboratorGoalSummaries from './endpoints/teamCollaboratorGoalSummaries'
import teamGoals from './endpoints/teamGoals'
import teamGoalSummaries from './endpoints/teamGoalSummaries'
import teamRewardOrderItems from "./endpoints/teamRewardOrderItems";
import teamRewardOrders from "./endpoints/teamRewardOrders";
import teamRewardOrderSummaries from "./endpoints/teamRewardOrderSummaries";
import teams from './endpoints/teams'
import teamGroups from './endpoints/teamGroups'
import tokens from './endpoints/tokens'
import userGoals from './endpoints/userGoals'
import users from './endpoints/users'
import units from './endpoints/units'

const api = {
    account,
    badgeLevels,
    badges,
    categories,
    categoryIcons,
    challengeAwardTypes,
    challengeRewardTypes,
    challengeImages,
    challenges,
    challengeTypes,
    coachingItems,
    collaboratorBadgeLevels,
    collaboratorBadgeSummary,
    collaboratorChallenges,
    collaboratorChallengeSummaries,
    collaboratorData,
    collaboratorGoals,
    collaboratorGoalSummaries,
    collaboratorRewardOrderItems,
    collaboratorRewardOrders,
    collaboratorRewardOrderSummaries,
    collaborators,
    configs,
    goalDefinitionLevels,
    goalDefinitionLevelsByCollaborator,
    goalDefinitionLevelsByTeam,
    goalDefinitions,
    goalDefinitionRepartitions,
    goalDefinitionPointRepartitions,
    goalDefinitionPointRepartitionModes,
    goalPoints,
    goals,
    goalTypes,
    importLogs,
    inAppNotifications,
    kpis,
    levelIcons,
    levels,
    mails,
    managers,
    metabase,
    partners,
    periodicities,
    periods,
    rewardCategories,
    rewardCategoryIcons,
    rewardImages,
    rewards,
    rewardTypes,
    roles,
    systemImages,
    superManagers,
    teamChallenges,
    teamChallengeSummaries,
    teamCollaboratorChallenges,
    teamCollaboratorChallengeSummaries,
    teamCollaboratorGoals,
    teamCollaboratorGoalSummaries,
    teamGoals,
    teamGoalSummaries,
    teamRewardOrderItems,
    teamRewardOrders,
    teamRewardOrderSummaries,
    teams,
    teamGroups,
    tokens,
    userGoals,
    users,
    units
};

export default api
