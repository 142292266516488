import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { AppBarSubTitle, DataTable, Loader, MainLayoutComponent, GridLink, RoundedTabs, RoundedTab } from '../../../../components'
import {injectIntl} from 'react-intl'
import { SubHeader } from './components'
import { AdminMetabase } from '../AdminMetabase'
import * as kpiListActions from '../../../../services/Kpis/KpiList/actions'
import * as configListActions from '../../../../services/Configs/ConfigList/actions'

class AdminReportList extends MainLayoutComponent {

    constructor(props) {
      super(props);
      this.state = {
        tabValue: 0
      }
    }

    componentDidMount() {
        const {intl} = this.props
        this.props.handleTitle(intl.formatMessage({id: 'admin.title'}))
        this.props.handleSubHeader(<SubHeader handleChangeTab={ this.handleChangeTab }/>)
        this.props.activateReturn()
        this.props.kpiListActions.getKpiList()
        this.props.configListActions.getPermanentConfigList()
    }

    handleChangeTab = (value) => {
      this.setState({
        ...this.state,
        tabValue: value
      })
    }

    renderLoader() {
        return <Loader centered />
    }

    renderData() {
        const {intl} = this.props
        const { kpis } = this.props.kpiList
        const { configs } = this.props.configList
        const MTBS = configs && configs.find(c => c.code === 'MTBS')


        const columns = [
            { name: 'id', label: intl.formatMessage({id: 'admin.report.columns.kpi_ref'}) },
            { name: 'name', label: intl.formatMessage({id: 'admin.report.columns.kpi_name'}) },
            { name: 'unit.name', label: intl.formatMessage({id: 'admin.report.columns.result_unit'}) },
            { name: 'manual', label: intl.formatMessage({id: 'admin.report.columns.format'}), options: {
                customBodyRender: value => {
                    return value ? intl.formatMessage({id: 'admin.report.columns.format_manual'}) : intl.formatMessage({id: 'admin.report.columns.format_standard'})
                }
            } },
            { name: 'periodicity.description', label: intl.formatMessage({id: 'admin.report.columns.periodicity'}) },
            { name: 'category.name', label: intl.formatMessage({id: 'admin.report.columns.category'}) }
        ]
        const options = {
            selectableRows: 'none',
            onRowClick: (colData, cellMeta) => { this.props.history.push(`/admin/reports/${colData[0]}`) }
        }
        return (
            <React.Fragment>

              {
                this.state.tabValue === 0 && <DataTable data={kpis} columns={columns} options={options} />
              }
              {
                this.state.tabValue === 1 && <AdminMetabase MTBS={ MTBS } />
              }
            </React.Fragment>
        )
    }

    render() {
        const { kpis, loading } = this.props.kpiList
        const { configs, loading: configLoading } = this.props.configList

        return (
            <div>
                { loading && configLoading && this.renderLoader() }
                { !loading && !configLoading && kpis && this.renderData() }
            </div>
        )
    }
}

const mapStateToProps = ({ kpiList, configList }) => ({
    kpiList,
    configList
})

const mapDispatchToProps = (dispatch) => ({
    kpiListActions: bindActionCreators(kpiListActions, dispatch),
    configListActions: bindActionCreators(configListActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AdminReportList))
