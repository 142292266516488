import React, {useCallback, useEffect, useMemo, useState} from 'react'
import isHotkey from 'is-hotkey'
import isUrl from 'is-url'
import {Editable, withReact, useSlate, Slate} from 'slate-react'
import {createEditor, Editor, Element as SlateElement, Transforms, Range} from 'slate'
import {withHistory} from 'slate-history'
import {Code, FormatBold, FormatItalic, FormatListBulleted, FormatListNumbered, FormatQuote, FormatUnderlined, LooksOne, LooksTwo, Link, LinkOff, PlayCircleFilled} from '@material-ui/icons'
import {Button, Icon, Toolbar} from './components'
import * as Resources from '../../../../Resources'
import {useIntl} from 'react-intl'
import _ from 'lodash'

import './styles.css'

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const RichText = ({initial, readOnly, onChange, padding, ...props}) => {
    const intl = useIntl()
    const [value, setValue] = useState(initial)
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const editor = useMemo(() => withLinks(withHistory(withReact(createEditor()))), [])

    useEffect(() => {
        onChange(initial)
    }, [])

    function handleValueChange(newValue) {
        setValue(newValue)
        if (onChange) onChange(newValue)
    }

    return (
        <div className='rich-text'>
            <Slate editor={editor} value={value} onChange={handleValueChange}>
                <div style={!readOnly ? {
                    backgroundColor: '#ffffff',
                    margin: 'auto',
                    padding: padding || '20px'
                } : null}>
                    {!readOnly && _.get(props, 'displayTools', true) && <Toolbar>
                        <MarkButton format="bold" icon={<FormatBold />} />
                        <MarkButton format="italic" icon={<FormatItalic />} />
                        <MarkButton format="underline" icon={<FormatUnderlined />} />
                        <MarkButton format="code" icon={<Code />} />
                        <BlockButton format="heading-one" icon={<LooksOne />} />
                        <BlockButton format="heading-two" icon={<LooksTwo />} />
                        <BlockButton format="block-quote" icon={<FormatQuote />} />
                        <BlockButton format="numbered-list" icon={<FormatListNumbered />} />
                        <BlockButton format="bulleted-list" icon={<FormatListBulleted />} />
                        <LinkButton format="link" icon={<Link />} />
                        <RemoveLinkButton format="link-off" icon={<LinkOff />} />
                        <EmbedButton format="embed" icon={<PlayCircleFilled />} />
                    </Toolbar>}
                    <Editable
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        placeholder={!readOnly && intl.formatMessage({id: "common.rich_text_placeholder"})}
                        spellCheck

                        onKeyDown={event => {
                            for (const hotkey in HOTKEYS) {
                                if (isHotkey(hotkey, event)) {
                                    event.preventDefault()
                                    const mark = HOTKEYS[hotkey]
                                    toggleMark(editor, mark)
                                }
                            }
                        }}
                        readOnly={readOnly}
                    />
                </div>
            </Slate>
        </div>
    )
}

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format)
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n =>
            LIST_TYPES.includes(
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
            ),
        split: true,
    })
    const newProperties = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
    Transforms.setNodes(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    })

    return !!match
}

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

const VideoElement = ({ attributes, children, url }) => {
  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <div
          style={{
            padding: '75% 0 0 0',
            position: 'relative',
          }}
        >
          <iframe
            src={url}
            frameBorder="0"
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      </div>
      {children}
    </div>
  )
}

const Element = ({ attributes, children, element }) => {
    switch (element.type) {
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>
        case 'list-item':
            return <li {...attributes}>{children}</li>
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>
        case 'link':
            return (
              <a {...attributes} target='_blank' href={element.url}>
                {children}
              </a>
            )
        case 'embed':
            return (
              <div {...attributes}>
                <VideoElement children={children} url={element.url} />
              </div>
            )
        default:
            return <p {...attributes}>{children}</p>
    }
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            active={isBlockActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    )
}

const MarkButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            active={isMarkActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    )
}


// LINKS

const withLinks = editor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}




const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
  return !!link
}

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
}

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const LinkButton = () => {
  const editor = useSlate()
  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={event => {
        event.preventDefault()
        const url = window.prompt('Entrez l\'url du lien:')
        if (!url) return
        insertLink(editor, url)
      }}
    >
      <Link />
    </Button>
  )
}

const insertEmbed = (editor, url) => {
  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const embed = {
    type: 'embed',
    url,
    children: isCollapsed ? [{ text: '' }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, embed)
  } else {
    Transforms.wrapNodes(editor, embed, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const EmbedButton = () => {
  const editor = useSlate()
  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={event => {
        event.preventDefault()
        const url = window.prompt('Entrez l\'url de la vidéo:')
        if (!url) return
        insertEmbed(editor, url)
      }}
    >
      <PlayCircleFilled />
    </Button>
  )
}

const RemoveLinkButton = () => {
  const editor = useSlate()

  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={event => {
        if (isLinkActive(editor)) {
          unwrapLink(editor)
        }
      }}
    >
      <LinkOff />
    </Button>
  )
}


export default RichText
