import React from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { DarkTag, DefaultTitle, InfoText, Avatar } from '..'
import * as Resources from '../../Resources'
import {useIntl} from 'react-intl'

const styles = {
    root: {
        padding: 16
    },
    avatar: {
        width: 41,
        height: 41
    },
    citation: {
        marginTop: 16
    }
}

const Collaborator = ({ user, ...props }) => {
    const intl = useIntl()
    const { classes } = props
    const photoSrc = user.photo ? user.photo : '/assets/img/user/avatar.svg'

    return (
        <div className={classes.root}>
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <Avatar src={photoSrc} className={classes.avatar} entityId={ user.id } fallbackName={ user.fullname } />
                </Grid>
                <Grid item container xs>
                    <Grid item container xs={12}>
                        <Grid item xs>
                            <DefaultTitle>{user.firstname} {user.lastname}</DefaultTitle>
                        </Grid>
                        <Grid item>
                            <DarkTag>{user.role.name}</DarkTag>
                        </Grid>
                    </Grid>
                    <Grid xs={12}>
                        <InfoText>{user.team ? user.team.name : intl.formatMessage({id: "user.profile.no_team"})}</InfoText>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default withStyles(styles)(Collaborator)
