import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { useClearCache } from 'react-clear-cache'
import {Account, Divider, DrawerButton, List, Logo} from './components'
import {faBook, faBullseye, faChartLine, faGift, faListUl, faQuestion, faRandom, faRocket, faSignOutAlt, faTools, faTrophy, faUsers} from '@fortawesome/free-solid-svg-icons'
import * as Resources from '../../../../../../../../Resources'
import {FormattedMessage, FormattedDate} from 'react-intl'
import * as collaboratorRewardOrdersActions from '../../../../../../../../services/CollaboratorRewardOrders/CollaboratorRewardOrderCount/actions'
import * as teamRewardOrderCountActions from '../../../../../../../../services/TeamRewardOrders/TeamRewardOrderCount/actions'
import * as systemImageListActions from '../../../../../../../../services/SystemImages/SystemImageList/actions'

const DrawerContent = ({onNavigate, ...props }) => {
    const {account} = props.accountDetail
    const {images} = props.systemImageList
    const {orders: collaboratorOrders} = props.collaboratorRewardOrderCount
    const {orders: teamOrders} = props.teamRewardOrderCount
    const isAdministrator = account.role.code === 'A'
    const orders = isAdministrator && collaboratorOrders != null && teamOrders != null ? collaboratorOrders + teamOrders : 0
    const [logo, setLogo] = useState('/assets/img/system/logo.png')
    const { isLatestVersion, emptyCacheStorage } = useClearCache()
    // var logo = images ? images.find(x => x.code === 'LOGO').src : null
    //
    // if (!logo) {
    //     logo = '/assets/img/system/logo.png'
    // }

    useEffect(() => {
        props.collaboratorRewardOrdersActions.countWaitingCollaboratorRewardOrders()
        props.teamRewardOrderCountActions.countWaitingTeamRewardOrders()
        props.systemImageListActions.getSystemImageList()
    }, [])

    useEffect(() => {
        if(images && images.length > 0) {
          setLogo(images.find(x => x.code === 'LOGO').src)
        }
    }, [images])
    const menuEntries = [
      {
        component: <DrawerButton icon={faBullseye} text={<FormattedMessage id="admin.goal.title" />} src='/goals' onNavigate={onNavigate} />,
        permission: account.hasGoalAccess
      },
      {
        component: <DrawerButton icon={faRocket} text={account.challengeWording || <FormattedMessage id="challenge.title_plural" />} src='/challenges' onNavigate={onNavigate} />,
        permission: account.hasChallengeAccess
      },
      {
        component: <DrawerButton icon={faTrophy} text={account.badgeWording ||<FormattedMessage id="badge.title" />} src='/badges' onNavigate={onNavigate} />,
        permission: account.hasBadgeAccess
      },
      {
        component: <DrawerButton icon={faListUl} text={<FormattedMessage id="coaching_list.title" />} src='/coaching' onNavigate={onNavigate} />,
        permission: account.hasCoachingAccess
      },
      {
        component: <DrawerButton icon={faRandom} text={<FormattedMessage id="ranking.title" />} src='/rankings' onNavigate={onNavigate} />,
        permission: account.hasRankingAccess && (account.hasGeneralRankAccess || account.hasCategoryRankAccess || account.hasChallengeRankAccess)
      },
      {
        component: <DrawerButton icon={faUsers} text={<FormattedMessage id="team.title" />} src='/teams' onNavigate={onNavigate} />,
        permission: account.hasTeamsAccess
      },
      {
        component: <DrawerButton icon={faChartLine} text={<FormattedMessage id="statistics.title" />} src='/stats' onNavigate={onNavigate} />,
        permission: account.hasStatisticsAccess
      },

      {
        component: <DrawerButton icon={faGift} text={<FormattedMessage id="reward.title" />} src='/rewards' onNavigate={onNavigate} badgeContent={orders} />,
        permission: account.hasRewardAccess
      },
      {
        component: <DrawerButton icon={faBook} text={account.rulesWording || <FormattedMessage id="menu.rules_button" />} src='/rules' onNavigate={onNavigate} />,
        permission: account.hasRulesAccess
      },

      {
        component: <DrawerButton icon={faTools} text={<FormattedMessage id="menu.admin_button" />} src='/admin' onNavigate={onNavigate} />,
        permission: isAdministrator
      },
      {
        component: <DrawerButton icon={faQuestion} text={<FormattedMessage id="menu.help_button" />} src='/help' onNavigate={onNavigate} />,
        permission: true
      },
      {
        component: <DrawerButton icon={faSignOutAlt} text={<FormattedMessage id="menu.logout_button" />} src='/logout' onNavigate={onNavigate} />,
        permission: true
      }
    ]
    return (
        <div>
            test v4 {isLatestVersion ? 'latest version' : 'outdated version'}
            <Account onNavigate={onNavigate} />
            <List>
                {
                  menuEntries.map((entry, index) => (
                    <React.Fragment>
                      { entry.permission && entry.component }
                      { entry.permission && (menuEntries.length > index + 1) && <Divider /> }
                    </React.Fragment>
                  ))
                }
            </List>
            <List>
                <Logo image={logo} />
                <Divider />
                <Divider />
            </List>
        </div>
    )
}

const mapStateToProps = ({accountDetail, collaboratorRewardOrderCount, systemImageList, teamRewardOrderCount}) => ({
    accountDetail,
    collaboratorRewardOrderCount,
    systemImageList,
    teamRewardOrderCount
})

const mapDispatchToProps = (dispatch) => ({
    collaboratorRewardOrdersActions: bindActionCreators(collaboratorRewardOrdersActions, dispatch),
    teamRewardOrderCountActions: bindActionCreators(teamRewardOrderCountActions, dispatch),
    systemImageListActions: bindActionCreators(systemImageListActions, dispatch)

})

export default connect(mapStateToProps, mapDispatchToProps)(DrawerContent)
