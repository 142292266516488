import React, { useContext, useState, useEffect } from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import Formsy from 'formsy-react'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth'
import {Grid, MenuItem, Select} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {AccentText, Card, FileInput, InfoText, MainLayoutComponent, ProgressButton, TextField, RefreshButton, Avatar, LanguageSelect, I18nWrapper, DefaultTitle} from '../../../../components'
import * as Resources from '../../../../Resources'
import * as accountUpdateActions from '../../../../services/Account/AccountUpdate/actions'
import * as userUpdatePasswordActions from '../../../../services/Users/UserUpdatePassword/actions'
import '../../../../helpers/FormsyHelper'
import { useIntl, injectIntl } from 'react-intl'

const styles = {
    photo: {
        height: 100,
        width: 100
    },
    refreshButton: {
      textAlign: 'center',
    }
};

class AccountDetail extends MainLayoutComponent {
    constructor(props) {
        super(props);
        this.state = {
            password: ''
        }
        this.props.accountUpdateActions.clearAccountUpdate()
    }

    componentDidMount() {
        const {intl} = this.props
        this.props.handleTitle(intl.formatMessage({id: "account.title"}));
        this.props.handleMaxWidth('sm');
        this.props.activateReturn()
    }

    handleValueChange = name => value => {
        this.setState({
            ...this.state,
            [name]: value
        })
    };

    handleValidSubmit = (model) => {
        const { account: oldAccount } = this.props.accountDetail;
        const newAccountPhoto = new FormData();
        if (model.photo) {
            newAccountPhoto.append('photo', model.photo, model.photo.name)
        }
        const newAccount = {firstname: model.firstname, lastname: model.lastname, email: model.email, citation: oldAccount.role.code != 'A' ? model.citation : null, locale: model.locale}
        this.props.accountUpdateActions.updateAccount(newAccount, newAccountPhoto);
        if (model.password && model.password != '') {
            this.props.userUpdatePasswordActions.updateUserPassword(this.props.accountDetail.account.id, model.password)
        }
    }

    render() {
        const {intl} = this.props
        const { classes } = this.props;
        const { account } = this.props.accountDetail;
        const { loading, success } = this.props.accountUpdate;
        const photo = account.photo ? account.photo : '/assets/img/user/avatar.svg';

        const mobileScreen = isWidthDown('xs', this.props.width)
        const {detect} = require('detect-browser')
        const browser = detect()
        const isIos = browser.name === 'ios-webview'

        const LanguageField = ({initial}) => {
          const context = useContext(I18nWrapper.Context);
          return (
            <LanguageSelect name="locale" label="Langue" initial={initial || context.locale} noCard />
          )
        }

        return (
            <div>
                <Formsy onValidSubmit={this.handleValidSubmit}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item>
                              <DefaultTitle>
                                {intl.formatMessage({id: "admin.user.information_title"})}
                              </DefaultTitle>
                            </Grid>
                            <Grid item xs={12}>
                              <Card>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} container justify='center'>
                                    <Grid item>
                                      <Avatar src={photo} className={classes.photo} entityId={ account.id } fallbackName={ account.fullname } fontSize={ 48 } />
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} container justify='center'>
                                    <Grid item>
                                      <InfoText>{account.role.name}</InfoText>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} container justify='center'>
                                    <Grid item>
                                      <FileInput name='photo' accept='image/*' />
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={12} container justify='flex-start'>
                                    <Grid item>
                                      <LanguageField initial={account.locale} />
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={6}>
                                    <TextField name='firstname' label={intl.formatMessage({id: "account.first_name_label"})} initial={account.firstname} fullWidth required
                                      validationErrors={{isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"})}}
                                      lowercase
                                      />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField name='lastname' label={intl.formatMessage({id: "account.last_name_label"})} initial={account.lastname} fullWidth required
                                      validationErrors={{isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"})}}
                                      lowercase
                                      />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField lowercase name='email' label={intl.formatMessage({id: "account.email_label"})} validations="isEmail" initial={account.email} fullWidth required
                                      validationErrors={{
                                        isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"}),
                                        isEmail: intl.formatMessage({id: "common.form.email_error"})
                                      }}
                                      />
                                  </Grid>
                                  { account.role.code != 'A' && <Grid item xs={12}>
                                  <TextField name='citation' label={intl.formatMessage({id: "account.citation_label"})} initial={account.citation} fullWidth multiline />
                                </Grid> }
                              </Grid>
                            </Card>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item>
                              <DefaultTitle>
                                {intl.formatMessage({id: "admin.user.update.password_title"})}
                              </DefaultTitle>
                            </Grid>
                            <Grid item xs={12}>
                              <Card>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <InfoText lowercase>
                                      {intl.formatMessage({id: "admin.user.password_info"})}
                                    </InfoText>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField lowercase type='password' name='password' label={intl.formatMessage({id: "account.password_label"})} fullWidth
                                      validations={{
                                        hasUppercaseCharacter: true,
                                        hasLowercaseCharacter: true,
                                        hasSpecialCharacter: true,
                                        hasMoreCharactersThan: 8,
                                      }}
                                      validationErrors={{
                                        hasUppercaseCharacter: intl.formatMessage({id: 'common.form.has_uppercase_character'}),
                                        hasLowercaseCharacter: intl.formatMessage({id: 'common.form.has_lowercase_character'}),
                                        hasSpecialCharacter: intl.formatMessage({id: 'common.form.has_special_character'}),
                                        hasMoreCharactersThan: intl.formatMessage({id: 'common.form.has_more_characters_than'}).format(8),
                                      }}
                                      />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField lowercase type='password' name='paswwordConfirm' label={intl.formatMessage({id: "account.confirm_password_label"})} fullWidth
                                      validations='equalsField:password'
                                      validationErrors={{
                                        isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"}),
                                        equalsField: intl.formatMessage({id: "common.form.password_not_match_error"})
                                      }}
                                      />
                                  </Grid>
                                </Grid>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container spacing={2} justify="center">
                            <Grid item style={{order: mobileScreen ? 2 : 1}}>
                              <RefreshButton color="secondary" className={classes.refreshButton} />
                            </Grid>
                            <Grid item style={{order: mobileScreen ? 1 : 2}}>
                              <ProgressButton type='submit' text={intl.formatMessage({id: "common.submit"})} centered loading={loading} />
                            </Grid>
                          </Grid>
                        </Grid>
                        {success && <Grid item xs={12}>
                            <AccentText align='center'>{Resources.ACCOUNT_SUCCESS_MESSAGE}</AccentText>
                        </Grid>}
                    </Grid>
                </Formsy>
            </div>
        )
    }
}

const mapStateToProps = ({ accountDetail, accountUpdate }) => ({
    accountDetail,
    accountUpdate
});

const mapDispatchToProps = (dispatch) => ({
    accountUpdateActions: bindActionCreators(accountUpdateActions, dispatch),
    userUpdatePasswordActions: bindActionCreators(userUpdatePasswordActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(injectIntl(AccountDetail))))
