import * as types from './actionTypes'

export const updateReward = (id, reward) => ({
    type: types.UPDATE_REWARD,
    id,
    reward
})

export const updateRewardActivation = (id, isActive) => ({
    type: types.UPDATE_REWARD_ACTIVATION,
    id,
    isActive
})

export const updateRewardSuccess = () => ({
    type: types.UPDATE_REWARD_SUCCESS
})

export const updateRewardError = () => ({
    type: types.UPDATE_REWARD_ERROR
})

export const clearRewardUpdate = () => ({
    type: types.CLEAR_REWARD_UPDATE
})
