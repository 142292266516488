import React, {useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import Formsy from 'formsy-react'
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@material-ui/core'
import {Button, DefaultText, ProgressButton, Select} from '../../../../../../components'
import * as Resources from '../../../../../../Resources'
import {useIntl} from 'react-intl'
import * as collaboratorGoalListDuplicationActions from '../../../../../../services/CollaboratorGoals/CollaboratorGoalListDuplication/actions'
import '../../../../../../helpers/StringHelper'

const GoalDuplicationDialog = ({allCollaborators, teams, user, ...props}) => {
    const intl = useIntl()
    const {success, loading} = props.collaboratorGoalListDuplication
    const [collaborator, setCollaborator] = React.useState(null)
    const [duplicationOpen, setDuplicationOpen] = React.useState(false)
    const [duplicationConfirmationOpen, setDuplicationConfirmationOpen] = React.useState(false)
    const [team, setTeam] = React.useState(null)
    const selectedTeam = team ? teams.filter(x => x.id === team)[0] : null
    const collaborators = selectedTeam ? selectedTeam.collaborators.filter(x => x.id !== user.id) : allCollaborators.filter(x => x.id !== user.id)
    const selectedCollaborator = collaborator ? collaborators.filter(x => x.id === collaborator)[0] : null
    const selectedCollaboratorFullname = selectedCollaborator ? selectedCollaborator.fullname : ""

    useEffect(() => {
        props.collaboratorGoalListDuplicationActions.clearCollaboratorGoalListDuplication()
    }, [])

    if (success) {
        props.collaboratorGoalListDuplicationActions.clearCollaboratorGoalListDuplication()
        props.history.goBack()
    }

    function handleCollaboratorChange(newCollaborator) {
        newCollaborator ? setCollaborator(Number(newCollaborator)) : setCollaborator(null)
    }

    function handleConfirmClick() {
        props.collaboratorGoalListDuplicationActions.duplicateCollaboratorGoalList(collaborator, user.id)
    }

    function handleTeamChange(newTeam) {
        newTeam ? setTeam(Number(newTeam)) : setTeam(null)
    }

    function handleValidSubmit(model) {
        setDuplicationConfirmationOpen(true)
    }

    return (
        <div>
            {user.role.code === 'C' && <Button type='button' color='secondary' onClick={() => setDuplicationOpen(true)}>{intl.formatMessage({id: "admin.goal.duplication.dialog_title"})}</Button>}
            <Dialog open={duplicationOpen || loading} onClose={() => setDuplicationOpen(false)}>
                <Formsy onValidSubmit={handleValidSubmit}>
                    <DialogTitle>{intl.formatMessage({id: "admin.goal.duplication.dialog_title"})}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DefaultText>{intl.formatMessage({id: "admin.goal.duplication.dialog_message"}).format(user.fullname)}</DefaultText>
                            </Grid>
                            <Grid item xs={12}>
                                <Select
                                    emptyText={intl.formatMessage({id: "filter.all_team_label"})}
                                    fullWidth
                                    label={intl.formatMessage({id: "filter.team_label"})}
                                    name='team'
                                    optionValueName='id'
                                    options={teams}
                                    optionTextName='name'
                                    onChange={handleTeamChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Select
                                    emptyText={intl.formatMessage({id: "filter.collaborator_all_option"})}
                                    fullWidth
                                    label={intl.formatMessage({id: "filter.collaborator_label"})}
                                    name='collaborator'
                                    optionValueName='id'
                                    options={collaborators}
                                    optionTextName='fullname'
                                    required
                                    validationErrors={{isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"})}}
                                    onChange={handleCollaboratorChange}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color='secondary' onClick={() => setDuplicationOpen(false)}>{intl.formatMessage({id: "common.cancel"})}</Button>
                        <Button type='submit'>{intl.formatMessage({id: "common.submit"})}</Button>
                    </DialogActions>
                </Formsy>
            </Dialog>
            <Dialog open={duplicationConfirmationOpen || loading} onClose={() => setDuplicationConfirmationOpen(false)}>
                <Formsy>
                    <DialogContent>
                        <DefaultText>{intl.formatMessage({id: "admin.goal.duplication.dialog_confirmation_message"}).format(user.fullname, selectedCollaboratorFullname)}</DefaultText>
                    </DialogContent>
                    <DialogActions>
                        <Button color='secondary' onClick={() => setDuplicationConfirmationOpen(false)}>{intl.formatMessage({id: "common.no"})}</Button>
                        <ProgressButton type='submit' text={intl.formatMessage({id: "common.yes"})} loading={loading} onClick={handleConfirmClick} />
                    </DialogActions>
                </Formsy>
            </Dialog>
        </div>
    )
}

const mapStateToProps = ({collaboratorGoalListDuplication}) => ({
    collaboratorGoalListDuplication
})

const mapDispatchToProps = (dispatch) => ({
    collaboratorGoalListDuplicationActions: bindActionCreators(collaboratorGoalListDuplicationActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GoalDuplicationDialog))
