import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { AdministratorCollaboratorSelector, MainLayoutComponent, ManagerCollaboratorSelector } from '../../../../components'
import * as Resources from '../../../../Resources'
import {injectIntl} from 'react-intl'

class CoachingHome extends MainLayoutComponent {
    componentDidMount() {
        const { intl } = this.props
        this.props.handleTitle(intl.formatMessage({id: "coaching_list.title"}))
    }

    handleClick(id) {
        this.props.history.push(`/coaching/${id}`)
    }

    render() {
        const { account } = this.props.accountDetail;

        if (!account.hasCoachingAccess) {
            return <Redirect to={'/'} />
        }

        if (account.role.code == 'C') {
            return <Redirect to={`/coaching/${account.id}`} />
        }

        return (
            <div>
                { account.role.code == 'M' && <ManagerCollaboratorSelector onClick={this.handleClick.bind(this)} /> }
                { (account.role.code == 'A' || account.role.code == 'S') && <AdministratorCollaboratorSelector contextUrl="coaching/team/" onClick={this.handleClick.bind(this)} /> }
            </div>
        )
    }
}

const mapStateToProps = ({ accountDetail }) => ({
    accountDetail
});

export default connect(mapStateToProps)(injectIntl(CoachingHome))
