import React from 'react'
import {Link} from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash';
import { SubHeader } from './components'
import {Grid, Tooltip} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFireAlt, faEdit, faFlagCheckered, faSlidersH} from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { Badge, CollaboratorFilter, LevelIcon } from './components'
import { AccentText, Card, DefaultText, DefaultTitle, EmptyState, GridLink, IconButton, InfoText, MainLayoutComponent, ProgressBar, AnimatedCounter} from '../../../../components'
import * as Resources from '../../../../Resources'
import {injectIntl} from 'react-intl'
import '../../../../helpers/StringHelper'
import * as configListActions from '../../../../services/Configs/ConfigList/actions'
import * as currentCollaboratorBadgeSummaryListActions from '../../../../services/CollaboratorBadges/CurrentCollaboratorBadgeSummaryList/actions'
import * as collaboratorDetailActions from '../../../../services/Collaborators/CollaboratorDetail/actions'

const styles = {
    iconMargin: {
        marginRight: 16
    },
    levelTitle: {
        fontSize: 20,
        marginTop: 8,
        fontWeight: 'bold',
        lineHeight: 1
    },
    levelNumber: {
        fontSize: 17,
        fontWeight: 'bold',
        marginTop: 8,
        lineHeight: 1
    },
    levelPoints: {
        fontSize: 15,
        // fontWeight: 'bold',
        marginTop: 8,
        lineHeight: 1
    },
    progressInfo: {
        fontSize: 15
    },

};

class CollaboratorDetail extends MainLayoutComponent {
    constructor(props) {
        super(props);
        this.id = null;
        this.year = null;
        this.buttonInitialized = false;
        this.state = {
            filterOpen: false
        }
    }

    refresh(id, year) {
        const teamId = this.props.match.params.teamId;
        var url = teamId ? `/teams/${teamId}/collaborators/${id}/detail` : `/collaborators/${id}/detail`;
        if (year) url += `?year=${year}`;
        this.props.history.replace(url)
    }

    loadData(props) {
        const id = props.match.params.id;
        const params = new URLSearchParams(window.location.search);
        const year = params.get('year');
        if (id != this.id || year != this.year) {
            this.id = id;
            this.year = year;
            this.props.collaboratorDetailActions.getCollaboratorDetail(id, year);
            this.props.currentCollaboratorBadgeSummaryListActions.getCurrentCollaboratorBadgeSummaryList(id, year)
        }
    }

    handleButtons() {
        const {intl} = this.props
        const { collaborator } = this.props.collaboratorDetail;
        if (!this.buttonInitialized && collaborator) {
            const { account } = this.props.accountDetail;
            this.buttonInitialized = true;
            const teamIds = _.get(account, 'team.id') ? [_.get(account, 'team.id')] : _.get(account, 'team_group.allTeamIds')
            if (account.canUpdateCollaboratorPassword && (account.role.code == 'A' || (account.role.code == 'M' || account.role.code == 'S') && teamIds.indexOf(collaborator.team.id) >= 0)) {
                const { classes } = this.props;
                this.props.handleButtons(<div>
                    <Tooltip title={intl.formatMessage({id: "collaborator.detail.password_button"})}>
                        <IconButton size='small' onClick={this.handleEditCollaborator.bind(this)} classes={{root: classes.iconMargin}}><FontAwesomeIcon icon={faEdit} /></IconButton>
                    </Tooltip>
                    <Tooltip title={intl.formatMessage({id: "common.filter"})}>
                        <IconButton size='small' onClick={this.handleFilterOpen.bind(this)}><FontAwesomeIcon icon={faSlidersH} /></IconButton>
                    </Tooltip>
                </div>)
            } else {
                this.props.handleButtons(<IconButton size='small' onClick={this.handleFilterOpen.bind(this)}><FontAwesomeIcon icon={faSlidersH} /></IconButton>)
            }
        } else {
            this.props.handleButtons(<IconButton size='small' onClick={this.handleFilterOpen.bind(this)}><FontAwesomeIcon icon={faSlidersH} /></IconButton>)
        }
    }

    handleFilterOpen() {
        this.setState({
            ...this.state,
            filterOpen: true
        })
    }

    handleFilterClose() {
        this.setState({
            ...this.state,
            filterOpen: false
        })
    }

    handleFilterChange(collaborator, year) {
        const collaboratorId = this.props.accountDetail.account.role.code == 'C' ? this.id : collaborator;
        this.refresh(collaboratorId, year)
    }

    handleEditCollaborator = () => {
        this.props.history.push(`/collaborators/${this.id}/password`)
    };

    componentDidMount() {
        const { intl } = this.props
        this.props.handleTitle(intl.formatMessage({id: 'collaborator.title'}));
        this.props.handleSubHeader(<SubHeader />);
        this.props.handleMaxWidth('md');
        if(_.get(this.props, 'match.params.teamId')) {
          this.props.activateReturn();
        }
        this.handleButtons();
        this.loadData(this.props);
        this.props.configListActions.getPermanentConfigList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.loadData(this.props)
    }

    renderEmptyState() {
        const {intl} = this.props
        return <EmptyState title={intl.formatMessage({id: "collaborator.detail.empty_state_title"})} />
    }

    renderData() {
        const {intl} = this.props
        const { account } = this.props.accountDetail;
        const { configs } = this.props.configList;
        const { badges } = this.props.currentCollaboratorBadgeSummaryList;
        const { collaborator } = this.props.collaboratorDetail;
        const levelPoints = collaborator.generalRank.points - collaborator.level.points;

        const levelProgression = collaborator.nextLevel ? Math.round((levelPoints / (collaborator.nextLevel.points - collaborator.level.points)) * 100) : 100;
        const nextLevelInfo = collaborator.nextLevel ? intl.formatMessage({id: "collaborator.detail.info_next_level"}).format(collaborator.nextLevel.number, collaborator.nextLevel.points) : intl.formatMessage({id: "collaborator.detail.info_max_level"});
        const { classes } = this.props

        // Badge activated for collaborators or not

        const CBAR = configs.filter(c => c.code === 'CBAR')[0];

        return (
            <div>
                <Grid container spacing={2} style={{marginTop: 8}}>
                    { collaborator.citation && <Grid item xs={12}>
                        <InfoText align='center'>
                            « {collaborator.citation} »
                        </InfoText>
                    </Grid> }
                    <Grid item container spacing={1} xs={12}>
                          <Grid item align='center' xs={12}>
                              {
                                _.get(collaborator, 'level.icon.path') && (
                                  <LevelIcon image={collaborator.level.icon.path} collaborator={ collaborator }/>
                                )
                              }

                              {
                                _.get(collaborator, 'level.title') && (
                                  <Grid item>
                                      <InfoText className={classes.levelTitle}>
                                        {collaborator.level.title}
                                      </InfoText>
                                  </Grid>
                                )
                              }
                              {
                                _.get(collaborator, 'level.citation') && (
                                  <Grid item>
                                      <InfoText align='center'>
                                          « { collaborator.level.citation } »
                                      </InfoText>
                                  </Grid>
                                )
                              }
                              {
                                _.get(collaborator, 'generalRank.level') && (
                                  <Grid item>
                                    <AccentText className={classes.levelNumber}>
                                      {intl.formatMessage({id: "menu.level_label"}).format(collaborator.generalRank.level)}
                                    </AccentText>
                                  </Grid>
                                )
                              }
                              <Grid item>
                                <DefaultText className={classes.levelPoints}>
                                  <FontAwesomeIcon icon={faFireAlt} /> {intl.formatMessage({id: "collaborator.detail.info_total_points"}).format(collaborator.generalRank.points)}
                                </DefaultText>
                              </Grid>
                          </Grid>

                        <Grid item xs={12}>
                            <Card>
                                <Grid container spacing={1}>
                                    <Grid container item spacing={1} xs={12}>
                                        <Grid item container xs={12}>
                                            <Grid item xs>
                                                <DefaultText className={classes.progressInfo}>
                                                  { collaborator.nextLevel && (
                                                    <React.Fragment>
                                                      <AnimatedCounter counter={ levelPoints } timer={ 750 } resource={ intl.formatMessage({id: "collaborator.detail.info_current_level"}) }/>
                                                      <InfoText className={classes.progressInfo} component='span'>
                                                        {intl.formatMessage({id: "collaborator.detail.info_current_level_max"}).format(
                                                          collaborator.nextLevel.points - collaborator.level.points
                                                        )}
                                                      </InfoText>
                                                    </React.Fragment>
                                                  )}
                                                </DefaultText>
                                            </Grid>
                                            <Grid item>
                                                <AccentText className={classes.progressInfo}>{nextLevelInfo}</AccentText>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ProgressBar value={levelProgression} animate />
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={1} xs={12} className={classes.progressWrapper}>
                                        { account.hasGeneralRankAccess && collaborator.generalRank.rank && <Grid item>
                                            <DefaultText>
                                                <FontAwesomeIcon icon={faFlagCheckered} /> {collaborator.generalRank.rank == 1 ? intl.formatMessage({id: "collaborator.detail.info_first_rank_text"}).format(collaborator.generalRank.rank) : intl.formatMessage({id: "collaborator.detail.info_other_rank_text"}).format(collaborator.generalRank.rank)} <InfoText component='span'>/ {collaborator.collaborators}</InfoText>
                                            </DefaultText>
                                        </Grid> }
                                        <Grid item>
                                            <DefaultText>
                                                <FontAwesomeIcon icon={faStar} /> {intl.formatMessage({id: "collaborator.detail.info_victories"}).format(collaborator.generalRank.victories)}
                                            </DefaultText>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                    { _.get(CBAR, 'value', "true") === "true" &&
                      <Grid item container spacing={1} xs={12}>
                          <Grid spacing={1} item xs={12}>
                              <DefaultTitle>{account.badgeWording || intl.formatMessage({id: "collaborator.detail.badge_area"})}</DefaultTitle>
                          </Grid>
                          <Grid spacing={1} item xs={12}>
                              <Card>
                                  { badges.length > 0 && <Grid container spacing={2}>
                                      { badges.map(badge => {
                                          return (
                                              <GridLink key={badge.id} item xs={6} sm={4} md={3} component={Link} to={`/badges/detail/${badge.levelId}`}>
                                                  <Badge badge={badge} />
                                              </GridLink>
                                          )
                                      }) }
                                  </Grid> }
                                  { badges.length == 0 && <DefaultText>{intl.formatMessage({id: 'collaborator.badge_empty_title'})}</DefaultText> }
                              </Card>
                          </Grid>
                      </Grid>
                    }
                </Grid>
            </div>
        )
    }

    render() {
        const { badges, loading: currentCollaboratorBadgeSummaryListLoading } = this.props.currentCollaboratorBadgeSummaryList;
        const { collaborator, loading: collaboratorDetailLoading } = this.props.collaboratorDetail;
        const { configs, loading: configLoading, hasError } = this.props.configList;

        const loading = currentCollaboratorBadgeSummaryListLoading || collaboratorDetailLoading || configLoading || hasError;
        const teamId = collaborator && collaborator.team ? collaborator.team.id : null;
        const collaboratorId = collaborator ? collaborator.id : null;

        return (
            <div>
                { !loading && badges && collaborator && collaborator.generalRank && this.renderData() }
                { !loading && badges && collaborator && !collaborator.generalRank && this.renderEmptyState() }
                <CollaboratorFilter
                    open={this.state.filterOpen}
                    onClose={this.handleFilterClose.bind(this)}
                    onChange={this.handleFilterChange.bind(this)}
                    team={teamId}
                    collaborator={collaboratorId}
                    year={this.year}
                />
            </div>
        )
    }
}

const mapStateToProps = ({ accountDetail, configList, currentCollaboratorBadgeSummaryList, collaboratorDetail }) => ({
    accountDetail,
    configList,
    currentCollaboratorBadgeSummaryList,
    collaboratorDetail
});

const mapDispatchToProps = (dispatch) => ({
    configListActions: bindActionCreators(configListActions, dispatch),
    currentCollaboratorBadgeSummaryListActions: bindActionCreators(currentCollaboratorBadgeSummaryListActions, dispatch),
    collaboratorDetailActions: bindActionCreators(collaboratorDetailActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(CollaboratorDetail)))
