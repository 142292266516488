import {call, put, takeLatest} from 'redux-saga/effects'
import {updateCollaboratorRewardOrderSuccess, updateCollaboratorRewardOrderError} from './actions'
import {countCollaboratorRewardOrderSuccess} from '../CollaboratorRewardOrderCount/actions'
import {clearCollaboratorRewardOrderDetail} from '../CollaboratorRewardOrderDetail/actions'
import * as types from './actionTypes'
import api from '../../../data/api/api'

function* updateCollaboratorRewardOrderValidation(action) {
    try {
        yield call(api.collaboratorRewardOrders.update, action.id, action.oldPointBalance, action.isValid)
        const {data: orders} = yield call(api.collaboratorRewardOrders.waitingCount)
        yield put(updateCollaboratorRewardOrderSuccess())
        yield put(countCollaboratorRewardOrderSuccess(orders))
        yield put(clearCollaboratorRewardOrderDetail())
    } catch(e) {
        yield put(updateCollaboratorRewardOrderError())
    }
}

function* watchCollaboratorRewardOrderValidationUpdate() {
    yield takeLatest(types.UPDATE_COLLABORATOR_REWARD_ORDER, updateCollaboratorRewardOrderValidation)
}

export default watchCollaboratorRewardOrderValidationUpdate
