import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersH } from '@fortawesome/free-solid-svg-icons'
import { Redirect } from 'react-router-dom'
import {Challenge, ChallengeCard, ChallengeCollaboratorFilter, ChallengeFilter, TimeFilter} from '../../components'
import { EmptyState, GridLink, IconButton, Loader, MainLayoutComponent } from '../../../../components'
import {useIntl, injectIntl} from 'react-intl'
import * as configListActions from '../../../../services/Configs/ConfigList/actions'
import * as collaboratorChallengeListActions from '../../../../services/CollaboratorChallenges/CollaboratorChallengeList/actions'
import * as collaboratorDetailActions from '../../../../services/Collaborators/CollaboratorDetail/actions'
import * as teamChallengeListActions from '../../../../services/TeamChallenges/TeamChallengeList/actions'

class CollaboratorChallengeList extends MainLayoutComponent {
    constructor(props) {
        super(props);
        this.id = null;
        this.page = 0;
        this.year = null;
        this.start = null;
        this.end = null;
        this.type = null;
        this.state = {
            filterOpen: false,
            collaboratorFilterLoaded: false
        }
    }

    refresh(id, page, year, start, end, type) {
        var url = `/challenges/collaborator/${id}?page=${page}`;
        if (year) url += `&year=${year}`;
        if (start) url += `&start=${start.getTime()}`;
        if (end) url += `&end=${end.getTime()}`;
        if (type) url += `&type=${type}`;
        this.props.history.replace(url)
    }

    handleFilterOpen() {
        this.setState({
            ...this.state,
            filterOpen: true
        })
    }

    handleFilterClose() {
        this.setState({
            ...this.state,
            filterOpen: false
        })
    }

    handleTimeChange(page) {
        this.refresh(this.id, page, this.year, this.start, this.end, this.type)
    }

    loadData(props) {
        const id = props.match.params.id;
        const params = new URLSearchParams(window.location.search);
        const page = Number(params.get('page'));
        const year = params.get('year');
        const startParam = params.get('start');
        const start = startParam ? new Date(Number(startParam)) : null;
        const endParam = params.get('end');
        const end = endParam ? new Date(Number(endParam)) : null;
        const currentStart = this.start ? this.start.getTime().toString() : null;
        const currentEnd = this.end ? this.end.getTime().toString() : null;
        const type = params.get('type');

        if (id != this.id || page != this.page || type != this.type || year != this.year || startParam != currentStart || endParam != currentEnd) {
            this.id = id;
            this.page = page;
            this.year = year;
            this.start = start;
            this.end = end;
            this.type = type;
            const time = page == 1 ? -1 : page == 2 ? 1 : 0;
            this.props.collaboratorChallengeListActions.getCollaboratorChallengeList(id, time, year, start, end, type);
            this.props.collaboratorDetailActions.getCollaboratorDetail(id);
            this.props.teamChallengeListActions.getTeamChallengeListByCollaborator(id, time, year, start, end, type)
        }
    }

    componentDidMount() {
        const {intl} = this.props
        const params = new URLSearchParams(window.location.search);
        const page = Number(params.get('page'));
        const { account } = this.props.accountDetail;

        this.props.handleTitle(account.challengeWording || intl.formatMessage({id: "challenge.title"}));
        this.props.handleSubHeader(<TimeFilter initial={page} handleTimeChange={this.handleTimeChange.bind(this)} />);
        this.props.handleButtons(<IconButton size='small' onClick={this.handleFilterOpen.bind(this)}><FontAwesomeIcon icon={faSlidersH} /></IconButton>);
        this.props.configListActions.getPermanentConfigList()
        this.loadData(this.props)
    }

    componentWillReceiveProps(props) {
        this.loadData(props)
    }

    handleFilterChange(team, collaborator, year, start, end, type) {
        const collaboratorId = this.props.accountDetail.account.role.code == 'C' ? this.id : collaborator;
        if (collaboratorId) {
            this.refresh(collaboratorId, this.page, year, start, end, type)
        } else {
            const teamId = this.props.accountDetail.account.role.code == 'M' ? this.props.collaboratorDetail.collaborator.team.id : team;
            var url = `/challenges/team/${teamId || this.props.match.params.id}?page=${this.page}`;
            if (year) url += `&year=${year}`;
            if (start) url += `&start=${start.getTime()}`;
            if (end) url += `&end=${end.getTime()}`;
            if (type) url += `&type=${type}`;
            this.props.history.push(url)
        }
    }

    onCollaboratorFilterLoaded() {
      if(!this.state.collaboratorFilterLoaded) {
        this.setState({
          ...this.state,
          collaboratorFilterLoaded: true
        })
      }
    }

    mergeChallenges(collaboratorGoals, teamGoals) {
        return collaboratorGoals.concat(teamGoals).sort((a, b) => {
            const comparison = a.end - b.end;
            return this.page ? comparison * -1 : comparison
            // return this.page == 0 || this.page == 2 ? comparison : comparison * -1
        })
    }

    renderLoader() {
        return <Loader centered />
    }

    renderEmptyState() {
        const {intl} = this.props
        return <EmptyState title={intl.formatMessage({id: "challenge.list.empty_state_title"})} message={intl.formatMessage({id: "challenge.list.empty_state_message"})} />
    }

    renderData() {
        const { challenges: teamChallenges } = this.props.teamChallengeList;
        const { challenges: collaboratorChallenges } = this.props.collaboratorChallengeList;
        const { configs } = this.props.configList;
        const challenges = this.mergeChallenges(collaboratorChallenges, teamChallenges);
        const { collaborator } = this.props.collaboratorDetail;
        const teamId = collaborator && collaborator.team ? collaborator.team.id : null;
        const collaboratorId = collaborator ? collaborator.id : null;

        return (
            <Grid container spacing={2}>
              { challenges.map(challenge=> {
                const detailurl = challenge.typeCode != 'CT' ? `/challenges/detail/collaborator/${challenge.id}` : `/challenges/detail/team/${challenge.id}`;

                return (
                  <GridLink key={challenge.id} item xs={12} sm={6} md={4} component={Link} to={detailurl}>
                    <ChallengeCard>
                      <Challenge challenge={challenge} configs={configs} />
                    </ChallengeCard>
                  </GridLink>
                )
              }) }
            </Grid>
        )
    }

    render() {
        const { challenges: collaboratorChallenges, loading: collaboratorChallengeListLoading } = this.props.collaboratorChallengeList;
        const { collaborator, loading: collaboratorDetailLoading } = this.props.collaboratorDetail;
        const { challenges: teamChallenges, loading: teamChallengeListLoading } = this.props.teamChallengeList;
        const { configs, loading: configLoading } = this.props.configList;
        const loading = collaboratorChallengeListLoading || collaboratorDetailLoading || teamChallengeListLoading || configLoading  || !this.state.collaboratorFilterLoaded;
        const teamId = collaborator && collaborator.team ? collaborator.team.id : null;
        const collaboratorId = collaborator ? collaborator.id : null;
        const { account } = this.props.accountDetail;

        if(!account.hasChallengeAccess) {
          return <Redirect to={'/'} />
        }

        return (
            <div>
                <ChallengeCollaboratorFilter
                  open={this.state.filterOpen}
                  onClose={this.handleFilterClose.bind(this)}
                  onChange={this.handleFilterChange.bind(this)}
                  team={teamId}
                  collaborator={collaboratorId}
                  year={this.year}
                  start={this.start}
                  end={this.end}
                  type={this.type}
                  onLoaded={this.onCollaboratorFilterLoaded.bind(this)}
                />
                { loading && this.renderLoader() }
                { !loading && collaboratorChallenges && teamChallenges && configs && this.renderData() }
                { !loading && collaboratorChallenges && teamChallenges && configs && collaboratorChallenges.length == 0 && teamChallenges.length == 0 && this.renderEmptyState() }
                {
                  this.state.filterOpen &&
                  <ChallengeFilter
                    open={this.state.filterOpen}
                    onClose={this.handleFilterClose.bind(this)}
                    onChange={this.handleFilterChange.bind(this)}
                    team={this.props.match.params.id}
                    collaborator={collaboratorId}
                    year={this.year}
                    start={this.start}
                    end={this.end}
                    type={this.type}
                  />
                }

            </div>
        )
    }
}

const mapStateToProps = ({ accountDetail, configList, collaboratorChallengeList, collaboratorDetail, teamChallengeList }) => ({
    accountDetail,
    configList,
    collaboratorChallengeList,
    collaboratorDetail,
    teamChallengeList
});

const mapDispatchToProps = (dispatch) => ({
    configListActions: bindActionCreators(configListActions, dispatch),
    collaboratorChallengeListActions: bindActionCreators(collaboratorChallengeListActions, dispatch),
    collaboratorDetailActions: bindActionCreators(collaboratorDetailActions, dispatch),
    teamChallengeListActions: bindActionCreators(teamChallengeListActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(CollaboratorChallengeList)))
