import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'
import { TeamGoalList, SubHeader } from './components'
import { GoalIndication, TeamGoalRankList } from '../../components'
import { MainLayoutComponent } from '../../../../components'
import * as Resources from '../../../../Resources'
import {injectIntl} from 'react-intl'
import * as teamGoalDetailActions from '../../../../services/TeamGoals/TeamGoalDetail/actions'
import * as teamGoalRankListActions from '../../../../services/TeamGoalRanks/TeamGoalRankList/actions'

class TeamGoalDetail extends MainLayoutComponent {
    constructor(props) {
        super(props);
        const { account } = this.props.accountDetail;
        this.state = {
            page: account.hasGoalRankAccess && account.hasTeamRankAccess ? 0 : 1
        }
    }

    handlePageChange = (page) => {
        this.setState({
            ...this.state,
            page: page
        })
    };

    componentDidMount() {
        const {intl} = this.props
        const id = this.props.match.params.id;
        const { account } = this.props.accountDetail;
        this.props.handleTitle(intl.formatMessage({id: "admin.goal.title"}));
        this.props.handleMaxWidth('md');
        this.props.activateReturn();
        this.props.teamGoalDetailActions.getTeamGoalDetail(id);
        this.props.teamGoalRankListActions.getTeamGoalRankList(id)
        this.props.handleSubHeader(<SubHeader onChange={this.handlePageChange.bind(this)} activateRank={account.hasGoalRankAccess && account.hasTeamRankAccess} />);

    }

    componentDidUpdate() {
      const { account } = this.props.accountDetail;
      const { goal } = this.props.teamGoalDetail;

      if(!this.state.initialized && goal && account) {
        this.setState({
          ...this.state,
          initialized: true,
          page: this.state.page === 0 && !goal.allow_ranking ? 1 : this.state.page
        }, () => {
          this.props.handleSubHeader(<SubHeader onChange={this.handlePageChange.bind(this)} activateRank={account.hasGoalRankAccess && account.hasTeamRankAccess && goal.allow_ranking} />);
        })
      }
    }

    render() {
        const { account } = this.props.accountDetail;
        const { goal } = this.props.teamGoalDetail;
        const { ranks } = this.props.teamGoalRankList;

        if(!account.hasGoalAccess) {
          return <Redirect to={'/challenges'} />
        }

        return (
            <div>
                { account.hasGoalRankAccess && account.hasTeamRankAccess && this.state.page == 0 && goal && ranks && goal.allow_ranking && <TeamGoalRankList goal={goal} ranks={ranks} teamId={goal.teamId} account={account}/> }
                { this.state.page == 1 && goal && <GoalIndication goal={goal} type='T' /> }
                { this.state.page == 2 && goal && <TeamGoalList /> }
            </div>
        )
    }
}

const mapStateToProps = ({ accountDetail, teamGoalDetail, teamGoalRankList }) => ({
    accountDetail,
    teamGoalDetail,
    teamGoalRankList
});

const mapDispatchToProps = (dispatch) => ({
    teamGoalDetailActions: bindActionCreators(teamGoalDetailActions, dispatch),
    teamGoalRankListActions: bindActionCreators(teamGoalRankListActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TeamGoalDetail))
