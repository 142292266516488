import React from 'react'
import { Timer } from '..'
import { AccentTag, DarkTag, LightTag, UltraLightTag } from '../../..'
import * as Resources from '../../../../../../Resources'
import { useIntl } from 'react-intl'
import {getDifferenceWithToday} from '../../../../../../helpers/DateHelper'
import '../../../../../../helpers/NumberHelper'

const TimerTag = ({ date }) => {
    const intl = useIntl()
    const difference = getDifferenceWithToday(date);
    const text = intl.formatMessage({id: "admin.goal.thumbnail.timer_tag"}).format(difference)

    return (
        <div>
            { difference === 0 && <AccentTag><Timer date={date.toDate2()} /></AccentTag> }
            { difference < 0 && difference >= -5 && <DarkTag>{text}</DarkTag> }
            { difference < -5 && difference >= -30 && <LightTag>{text}</LightTag> }
            { difference < -30  && <UltraLightTag>{text}</UltraLightTag> }
        </div>
    )
};

export default TimerTag
