import React, { useState } from 'react';
import {IntlProvider} from 'react-intl';
import flatten from 'flat'
import locales from '../../../../locales'


const Context = React.createContext();

const I18nWrapper = (props) => {
  const local = localStorage.getItem('locale') || 'fr'
  const lang = locales[local]
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);
  const selectLanguage = (value) => {
     const newLocale = value;
     setLocale(value)
     setMessages(locales[newLocale]);
     // if (newLocale === 'en') {
     // }
     // if (newLocale === 'fr'){
     //     setMessages(fr);
     // }
   }
   return (
       <Context.Provider value={{locale, selectLanguage}}>
         <IntlProvider locale={locale} messages={flatten(messages)}>
           { props.children }
         </IntlProvider>
       </Context.Provider>

   );
}

export default {I18nWrapper, Context};
