import React, {useContext} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Formsy from 'formsy-react'
import { Grid } from '@material-ui/core'
import { AppBarSubTitle, Card, Loader, MainLayoutComponent, ProgressButton, Select, TextField, LanguageSelect, I18nWrapper, DefaultTitle, InfoText } from '../../../../components'
import * as roleListActions from '../../../../services/Roles/RoleList/actions'
import * as teamListActions from '../../../../services/Teams/TeamList/actions'
import * as userCreationActions from '../../../../services/Users/UserCreation/actions'
import '../../../../helpers/FormsyHelper'
import * as Resources from "../../../../Resources";
import {injectIntl} from 'react-intl'

class AdminUserCreation extends MainLayoutComponent {
    constructor(props) {
        super(props);
        this.state = {
            display: true,
            role: null
        };
        this.props.userCreationActions.clearUserCreation()
    }

    componentDidMount() {
        const { intl } = this.props
        this.props.handleTitle(intl.formatMessage({id: 'menu.admin_button'}));
        this.props.handleSubHeader(<AppBarSubTitle title={intl.formatMessage({id: 'admin.user.creation.title'})} />);
        this.props.handleMaxWidth('sm');
        this.props.activateReturn();
        this.props.roleListActions.getRoleList();
        this.props.teamListActions.getTeamList()
    }

    handleValueChange = name => value => {
        this.setState({
            ...this.state,
            [name]: value,
            display: false
        }, () => {
            this.setState({
                ...this.state,
                display: true
            })
        })
    };

    handleSubmit(model) {
        this.props.userCreationActions.createUser(model)
    }

    renderLoader() {
        return <Loader centered />
    }

    renderData() {
        const {intl} = this.props
        const { loading } = this.props.userCreation;
        const { roles } = this.props.roleList;
        const { teams } = this.props.teamList;
        const collaboratorRole = roles.find(r => r.code == 'C');
        const managerRole = roles.find(r => r.code == 'M');
        const selectableTeams = this.state.role == collaboratorRole.id ? teams : this.state.role == managerRole.id ? teams.filter(t => !t.manager) : null;

        const LanguageField = () => {
          const context = useContext(I18nWrapper.Context);
          return (
            <LanguageSelect name="locale" label={intl.formatMessage({id: 'admin.user.locale'})} initial={context.locale} noCard />
          )
        }

        return (
            <div>
            <Formsy onValidSubmit={this.handleSubmit.bind(this)}>
                <Grid container spacing={4}>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <DefaultTitle>
                          {intl.formatMessage({id: "admin.user.information_title"})}
                        </DefaultTitle>
                      </Grid>
                      <Grid item xs={12}>
                        <Card>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField name='firstname' label={intl.formatMessage({id: 'admin.user.firstname'})} fullWidth required lowercase
                                validationErrors={{isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"})}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField name='lastname' label={intl.formatMessage({id: 'admin.user.lastname'})} fullWidth required lowercase
                                validationErrors={{isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"})}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField name='email' label={intl.formatMessage({id: 'admin.user.email'})} validations="isEmail" fullWidth required lowercase
                                validationErrors={{
                                  isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"}),
                                  isEmail: intl.formatMessage({id: 'common.form.email_error'})
                                }}
                                />
                            </Grid>
                            <Grid item xs>
                              <LanguageField />
                            </Grid>

                            <Grid item xs={6}></Grid>
                            <Grid item xs={6}>
                              <Select name='role' label={intl.formatMessage({id: 'admin.user.role'})} options={roles} optionValueName='id' optionTextName='name' fullWidth required
                                onChange={this.handleValueChange('role').bind(this)}
                                validationErrors={{
                                  isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"})
                                }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                              { this.state.display && <Select name='team' label={intl.formatMessage({id: 'admin.user.team'})} options={selectableTeams} optionValueName='id' optionTextName='name' fullWidth /> }
                            </Grid>

                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <DefaultTitle>
                          {intl.formatMessage({id: "admin.user.creation.password_title"})}
                        </DefaultTitle>
                      </Grid>
                      <Grid item xs={12}>
                        <Card>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <InfoText lowercase>
                                {intl.formatMessage({id: "admin.user.password_info"})}
                              </InfoText>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField type='password' name='password' label={intl.formatMessage({id: 'admin.user.password_field'})} fullWidth required lowercase
                                validations={{
                                  hasUppercaseCharacter: true,
                                  hasLowercaseCharacter: true,
                                  hasSpecialCharacter: true,
                                  hasMoreCharactersThan: 8,
                                }}
                                validationErrors={{
                                  isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"}),
                                  hasUppercaseCharacter: intl.formatMessage({id: 'common.form.has_uppercase_character'}),
                                  hasLowercaseCharacter: intl.formatMessage({id: 'common.form.has_lowercase_character'}),
                                  hasSpecialCharacter: intl.formatMessage({id: 'common.form.has_special_character'}),
                                  hasMoreCharactersThan: intl.formatMessage({id: 'common.form.has_more_characters_than'}).format(8),
                                }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField type='password' name='paswwordConfirm' label={intl.formatMessage({id: 'admin.user.password_confirm'})} fullWidth lowercase
                                validations='equalsField:password'
                                validationErrors={{
                                  isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"}),
                                  equalsField: intl.formatMessage({id: "common.form.password_not_match_error"})
                                }}
                                />
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                      <ProgressButton type='submit' text={intl.formatMessage({id: 'common.submit'})} centered loading={loading} />
                  </Grid>
                </Grid>
            </Formsy>
            </div>
        )
    }

    render() {
        const { roles, loading: roleListLoading } = this.props.roleList;
        const { teams, loading: teamListLoading } = this.props.teamList;
        const { success } = this.props.userCreation;
        const loading = roleListLoading || teamListLoading;

        if (success) {
            this.props.userCreationActions.clearUserCreation();
            this.props.history.goBack()
        }

        return (
            <div>
                { loading && this.renderLoader() }
                { !loading && roles && teams && this.renderData() }
            </div>
        )
    }
}

const mapStateToProps = ({ roleList, teamList, userCreation }) => ({
    roleList,
    teamList,
    userCreation
});

const mapDispatchToProps = (dispatch) => ({
    roleListActions: bindActionCreators(roleListActions, dispatch),
    teamListActions: bindActionCreators(teamListActions, dispatch),
    userCreationActions: bindActionCreators(userCreationActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AdminUserCreation))
