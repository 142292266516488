import React, { useState } from 'react'
import {Grid, CardMedia} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleRight, faBalanceScale, faCalendarAlt, faEquals, faInfoCircle, faUser, faUsers} from '@fortawesome/free-solid-svg-icons'
import {faStar} from '@fortawesome/free-regular-svg-icons'
import {AccentTag, AccentText, AnimationController, BlueTag, BlueText, Card, DefaultText, DefaultTitle, InfoText, Table, TableBody, TableCell, TableChip, TableRow, Tooltip, RichText, Linkify, Dialog, BigText, ProgressBar} from '../../../../components'
import * as Resources from '../../../../Resources'
import {ChallengeReward, ChallengeRewardDetail, ChallengeRewardCard} from '../'
import {useIntl} from 'react-intl'
import '../../../../helpers/StringHelper'
import _ from 'lodash'

const styles = {
  rewardDialog: {
    width: 900,
    maxWidth: 900
  },
  icon: {
    height: 100,
    width: 100
  }
}

const ChallengeCondition = ({ challenge, goals, ...props }) => {
    const intl = useIntl()

    const [rewardDetail, setRewardDetail] = useState()
    const start = challenge.start.toDate2().toLocaleDateString()
    const end = challenge.end.toDate2().toLocaleDateString()
    const typeIcon = challenge.typeCode === 'CT' ? faUsers : faUser

    const coinImage = require(`../../../../assets/img/system/challenge/icons/coin.png`)
    const giftImage = require(`../../../../assets/img/system/challenge/icons/gift.png`)

    const modeIcons = {
      'R': require(`../../../../assets/img/system/challenge/icons/Ribbons.png`),
      'M': require(`../../../../assets/img/system/challenge/icons/Rocket.png`),
      'P': require(`../../../../assets/img/system/challenge/icons/Levels.png`),
      'C': require(`../../../../assets/img/system/challenge/icons/race.png`)
    }

    const rewardTypeIcon = challenge.rewardTypeCode === 'G' ? giftImage : coinImage
    const pointRewardImage = require(`../../../../assets/img/system/challenge/icons/points.png`)

    const renderMaximumAward = () => {
        const award = challenge.awards[0]

        return (
            <Grid container spacing={1}>
              <Grid key={award.key} item xs={12} sm={6} md={4} style={{borderRadius: 15}}>
                <Grid container spacing={1} direction="column">
                  <Grid item xs={12}>
                    <ChallengeRewardCard>
                      <Grid container spacing={1} alignItems='flex-end'>
                        <Grid item xs={12} >
                          <Grid container direction='column' spacing={2}>
                            <Grid item>
                              <Grid container justify='space-between'>
                                <Grid item>

                                  <DefaultTitle>{challenge.typeCode === 'CT' ? intl.formatMessage({id: "challenge.condition.team_max_points_label"}) : intl.formatMessage({id: "challenge.condition.collaborator_max_points_label"})}</DefaultTitle>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <CardMedia image={pointRewardImage} style={{height: 100, width: 100, margin: 'auto'}}/>
                            </Grid>
                            <Grid item>
                              <Grid container>
                                <Grid item>
                                  <CardMedia image={coinImage} style={{height: 20, width: 20, marginRight: 5, marginTop: -2}} />
                                </Grid>
                                <Grid item>
                                  <DefaultText>
                                    {intl.formatMessage({id: "challenge.condition.award_points"}).format(award.points)}

                                  </DefaultText>
                                </Grid>
                              </Grid>
                            </Grid>



                          </Grid>
                        </Grid>

                      </Grid>
                    </ChallengeRewardCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        )
    }


    const renderRankingAwards = () => {
        return (
              <Table backgroundDisabled>
                <TableBody>
                  <Grid container spacing={1}>
                  {challenge.awards.map((award, awardIndex) => {
                    const highlightAward =
                      // filtered on participant
                      (challenge.collaboratorId || challenge.teamId) &&
                      // step is reached
                      challenge.goalPoints >= award.target &&
                      // next step is not reached
                      (awardIndex >= challenge.awards.length - 1 || challenge.goalPoints < challenge.awards[awardIndex + 1].target)
                    return (

                        <React.Fragment>
                          {challenge.rewardTypeCode === 'P' && (
                            <Grid key={award.key} item xs={12} sm={6} md={4} style={{background: highlightAward ? '#00E58D' : '', borderRadius: 15}}>
                              <Grid container spacing={1} direction="column">
                                <Grid item xs={12}>
                                  <ChallengeRewardCard>
                                    <Grid container spacing={1} alignItems='flex-end'>
                                      <Grid item xs={12} >
                                        <Grid container direction='column' spacing={2}>
                                          <Grid item>
                                            <Grid container justify='space-between'>
                                              <Grid item>

                                                <DefaultTitle>{challenge.typeCode === 'CT' ? intl.formatMessage({id: "challenge.condition.team_rank"}).format(award.rank) : intl.formatMessage({id: "challenge.condition.collaborator_rank"}).format(award.rank)}</DefaultTitle>
                                              </Grid>
                                            </Grid>
                                          </Grid>

                                          <Grid item xs={12}>
                                            <CardMedia image={pointRewardImage} style={{height: 100, width: 100, margin: 'auto'}}/>
                                          </Grid>
                                          <Grid item>
                                            <Grid container>
                                              <Grid item>
                                                <CardMedia image={coinImage} style={{height: 20, width: 20, marginRight: 5, marginTop: -2}} />
                                              </Grid>
                                              <Grid item>
                                                <DefaultText>
                                                  {intl.formatMessage({id: "challenge.condition.award_points"}).format(award.points)}

                                                </DefaultText>
                                              </Grid>
                                            </Grid>
                                          </Grid>



                                        </Grid>
                                      </Grid>

                                    </Grid>
                                  </ChallengeRewardCard>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          {challenge.rewardTypeCode === 'G' && (
                            <Grid item xs={12} style={{ cursor: 'pointer' }} onClick={() => award.reward && setRewardDetail(Object.assign({}, award.reward))}>
                              <div>
                                <TableRow>
                                  <TableCell style={{width: 270}}>
                                    <Grid container direction="column" spacing={1}>
                                      <Grid item>
                                        <Grid container spacing={1}>
                                          <Grid item>
                                            <TableChip label={'>'} />
                                          </Grid>
                                          <Grid item>
                                            <DefaultText>{challenge.typeCode === 'CT' ? intl.formatMessage({id: "challenge.condition.team_rank"}).format(award.rank) : intl.formatMessage({id: "challenge.condition.collaborator_rank"}).format(award.rank)}</DefaultText>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item style={{maxWidth: 250}}>
                                        <ChallengeReward reward={award.reward} />
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              </div>
                            </Grid>
                          )}
                        </React.Fragment>
                    )
                })}
                </Grid>
              </TableBody>
            </Table>
        )
    }


    const renderStepAwards = () => {
        const awards = _.sortBy(challenge.awards, ['target'])
        return (
            <Grid container spacing={1}>
              {awards.map((award, awardIndex) => {
                const highlightAward =
                  // filtered on participant
                  (challenge.collaboratorId || challenge.teamId) &&
                  // step is reached
                  challenge.goalPoints >= award.target &&
                  // next step is not reached
                  (awardIndex >= awards.length - 1 || challenge.goalPoints < awards[awardIndex + 1].target)
                return (
                    <React.Fragment>
                      {challenge.rewardTypeCode === 'P' && (
                        <Grid key={award.key} item xs={12} sm={6} md={4} style={{background: highlightAward ? '#00E58D' : '', borderRadius: 15}}>
                          <Grid container spacing={1} direction="column">
                            <Grid item xs={12}>
                              <ChallengeRewardCard>
                                <Grid container spacing={1} alignItems='flex-end'>
                                  <Grid item xs={12} >
                                    <Grid container direction='column' spacing={2}>
                                      <Grid item>
                                        <Grid container justify='space-between'>
                                          <Grid item>

                                            <DefaultTitle>{intl.formatMessage({id: "challenge.award_list.step_point_label"}).format(award.rank)}</DefaultTitle>
                                          </Grid>
                                        </Grid>
                                      </Grid>


                                      <Grid item>

                                        <DefaultText>
                                          { intl.formatMessage({id: "challenge.award_list.target_label"}) } : {award.target}
                                        </DefaultText>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <CardMedia image={pointRewardImage} style={{height: 100, width: 100, margin: 'auto'}}/>
                                      </Grid>
                                      <Grid item>
                                        <Grid container>
                                          <Grid item>
                                            <CardMedia image={coinImage} style={{height: 20, width: 20, marginRight: 5, marginTop: -2}} />
                                          </Grid>
                                          <Grid item>
                                            <DefaultText>
                                              {intl.formatMessage({id: "challenge.condition.award_points"}).format(award.points)}

                                            </DefaultText>
                                          </Grid>
                                        </Grid>
                                      </Grid>



                                    </Grid>
                                  </Grid>

                                </Grid>
                              </ChallengeRewardCard>
                            </Grid>
                          </Grid>
                        </Grid>

                      )}
                      {challenge.rewardTypeCode === 'G' && (
                        <Grid
                          item
                          xs={12}
                          style={{ cursor: 'pointer', background: highlightAward ? '#00E58D' : '', borderRadius: 15 }}
                          onClick={() => award.reward && setRewardDetail(Object.assign({}, award.reward))}
                        >
                          <div>
                            <TableRow>
                              <TableCell style={{width: 270}}>
                                <Grid container direction="column" spacing={1}>
                                  <Grid item>
                                    <Grid container spacing={1}>
                                      <Grid item>
                                        <TableChip label={'>'} />
                                      </Grid>
                                      <Grid item>
                                        <DefaultText>{intl.formatMessage({id: "challenge.award_list.step_point_label"}).format(award.rank)}</DefaultText>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <DefaultText>
                                      { intl.formatMessage({id: "challenge.award_list.target_label"}) } : {award.target}
                                    </DefaultText>
                                  </Grid>
                                  <Grid item style={{maxWidth: 250}}>
                                    <ChallengeReward reward={award.reward} />
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          </div>
                        </Grid>
                      )}
                    </React.Fragment>
                )
            })}
          </Grid>

        )
    }


    const renderAwards = () => {
      return (
        <Grid container spacing={2}>
          {challenge.awards.map(award => {
            return(
              <Grid key={award.key} item xs={12} sm={6} md={4} style={{ cursor: 'pointer' }} onClick={() => award.reward && setRewardDetail(Object.assign({}, award.reward))}>
              <Grid container spacing={1} direction="column">
                <Grid item xs={12}>
                  <ChallengeRewardCard>
                    <Grid container spacing={1} alignItems='flex-end'>
                      {challenge.rewardTypeCode === 'G' && (
                        <Grid item xs={12} >
                          <Grid container direction='column' spacing={2}>
                            <Grid item>
                              <Grid container justify='space-between'>
                                <Grid item>
                                  {challenge.awardCode === 'P' ? (
                                    <DefaultText>{intl.formatMessage({id: "challenge.award_list.step_point_label"}).format(award.rank)}</DefaultText>
                                  ) : (
                                    <DefaultTitle>
                                      {challenge.typeCode === 'CT' ? intl.formatMessage({id: "challenge.condition.team_rank"}).format(award.rank) : intl.formatMessage({id: "challenge.condition.collaborator_rank"}).format(award.rank)}
                                    </DefaultTitle>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>

                            {challenge.awardCode === 'P' && (
                              <Grid item>
                                <DefaultText>
                                  { intl.formatMessage({id: "challenge.award_list.target_label"}) } : {award.target}
                                </DefaultText>
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              {award.reward && (
                                <ChallengeReward reward={award.reward} />
                              )}
                            </Grid>

                          </Grid>
                        </Grid>
                      )}
                      {challenge.rewardTypeCode === 'P' && (
                        <React.Fragment>
                          <Grid item xs>
                            <DefaultText>
                              {challenge.typeCode === 'CT' ? intl.formatMessage({id: "challenge.condition.team_rank"}).format(award.rank) : intl.formatMessage({id: "challenge.condition.collaborator_rank"}).format(award.rank)}
                            </DefaultText>
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                  </ChallengeRewardCard>
                </Grid>
              </Grid>
            </Grid>
            )
          })}
        </Grid>

      )
    }


    const goalTooltip = challenge.awardCode === 'C' ? intl.formatMessage({id: "challenge.condition.race_condition_goal_info"}) : intl.formatMessage({id: "challenge.condition.goal_info"})
    // const AwardWrapperComponent = challenge.awardCode === 'P' || challenge.awardCode === 'R' ? React.Fragment : Card
    const AwardWrapperComponent = React.Fragment
    return (
        <div>
            <Grid container spacing={4}>
                <Grid item xs={12} container spacing={1}>
                    <Grid item xs={12}>
                        <DefaultTitle>{intl.formatMessage({id: "challenge.condition.condition_area"})}</DefaultTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <Card marginDisabled>
                            <Grid container spacing={2} alignItems={'flex-end'}>
                                <Grid item xs={12} sm>
                                    <Table backgroundDisabled>
                                        <TableBody>
                                            { goals.map(goal => {
                                                const progression = Math.round((goal.counter / (goal.targetByTeam || goal.target)) * 100)
                                                return (
                                                    <TableRow>
                                                        <TableCell>
                                                            <Grid container spacing={2}>
                                                                <Grid item>
                                                                    <TableChip label={goal.number} />
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <div>

                                                                        <Grid container spacing={1}>
                                                                            <Grid item xs={12}>
                                                                                <div>
                                                                                    <Grid container spacing={1}>
                                                                                        <Grid item xs zeroMinWidth>
                                                                                            <DefaultText>
                                                                                                {goal.name}&nbsp;
                                                                                                <Tooltip title={goalTooltip} placement={'right'}>
                                                                                                    <BlueText style={{ width: 'fit-content' }} component={'span'}>
                                                                                                        <FontAwesomeIcon icon={faInfoCircle} />
                                                                                                    </BlueText>
                                                                                                </Tooltip>
                                                                                            </DefaultText>
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <DefaultText>
                                                                                                <FontAwesomeIcon icon={faBalanceScale} /> ({goal.unit})
                                                                                            </DefaultText>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </div>
                                                                            </Grid>
                                                                            {challenge.awardCode === 'C' && (
                                                                              <Grid item xs={12} style={{marginTop: 10, marginBottom: 15, maxWidth: 400}}>
                                                                                <Grid container>
                                                                                  <Grid item>
                                                                                    <DefaultText lowercase style={{fontSize: 13}}>
                                                                                      {intl.formatMessage({id: "admin.goal.thumbnail.counter_text"}).format(goal.counter)} <InfoText lowercase  style={{fontSize: 13}} component='span'>{intl.formatMessage({id: "admin.goal.thumbnail.target_text"}).format(goal.targetByTeam || goal.target)}</InfoText>
                                                                                    </DefaultText>
                                                                                  </Grid>
                                                                                  <Grid item xs>
                                                                                    <AccentText align='right'>{"{0}%".format(progression)}</AccentText>
                                                                                  </Grid>
                                                                                </Grid>
                                                                                <Grid container>
                                                                                  <Grid item xs>
                                                                                    <ProgressBar value={progression} />
                                                                                  </Grid>
                                                                                </Grid>
                                                                              </Grid>
                                                                            )}
                                                                            {challenge.awardCode !== 'C' && (
                                                                              <Grid item xs={12}>
                                                                                <div>
                                                                                  <table>
                                                                                    <tbody>
                                                                                      <tr>
                                                                                        <td style={{padding: 0, width: '30%'}}>
                                                                                          <DefaultText lowercase style={{textAlign: 'right', paddingRight: 5, fontSize: 13}}>
                                                                                            Condition :
                                                                                          </DefaultText>
                                                                                        </td>
                                                                                        <td>
                                                                                          <Grid container spacing={1} style={{marginTop: 0, width: '70%'}}>
                                                                                              <Grid item>
                                                                                                  <DefaultText>{goal.target}</DefaultText>
                                                                                              </Grid>

                                                                                                <React.Fragment>
                                                                                                  <Grid item>
                                                                                                    <DefaultText>
                                                                                                      <FontAwesomeIcon icon={faEquals} />
                                                                                                    </DefaultText>
                                                                                                  </Grid>
                                                                                                  <Grid item>
                                                                                                    <BlueTag>{intl.formatMessage({id: "challenge.condition.point_target"}).format(goal.targetPoints)}</BlueTag>
                                                                                                  </Grid>
                                                                                                </React.Fragment>

                                                                                          </Grid>
                                                                                        </td>
                                                                                      </tr>
                                                                                      <tr>
                                                                                        <td style={{padding: 0, width: '30%'}}>
                                                                                          <DefaultText lowercase style={{textAlign: 'right', paddingRight: 5, fontSize: 13}}>
                                                                                            👉 Réalisé :
                                                                                          </DefaultText>
                                                                                        </td>
                                                                                        <td style={{width: '70%'}}>
                                                                                          <Grid container spacing={1} style={{marginTop: '-1px'}}>
                                                                                              <Grid item>
                                                                                                  <AccentText>{goal.counter}</AccentText>
                                                                                              </Grid>

                                                                                                <React.Fragment>
                                                                                                  <Grid item>
                                                                                                    <AccentText>
                                                                                                      <FontAwesomeIcon icon={faAngleRight} />
                                                                                                    </AccentText>
                                                                                                  </Grid>
                                                                                                  <Grid item>
                                                                                                    <AccentTag>{intl.formatMessage({id: "challenge.condition.point_counter"}).format(goal.points)}</AccentTag>
                                                                                                  </Grid>
                                                                                                </React.Fragment>

                                                                                          </Grid>
                                                                                        </td>
                                                                                      </tr>
                                                                                    </tbody>
                                                                                  </table>

                                                                                </div>
                                                                            </Grid>
                                                                            )}

                                                                        </Grid>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }) }
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item xs={12} sm={'auto'}>
                                    <div>
                                        <Grid container justify={'center'}>
                                            <Grid item>
                                                <AnimationController />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1}>
                    <Grid item xs={12}>
                        <DefaultTitle>{intl.formatMessage({id: "challenge.condition.description_area"})}</DefaultTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={8}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                      <DefaultTitle>{challenge.name}</DefaultTitle>
                                  </Grid>
                                  <Grid item xs={12}>
                                      <DefaultText>
                                          <FontAwesomeIcon icon={faCalendarAlt} /> {intl.formatMessage({id: "challenge.condition.period"}).format(start, end)}
                                      </DefaultText>
                                  </Grid>
                                  <Grid item xs={12}>
                                      <DefaultText>
                                          <FontAwesomeIcon icon={typeIcon} /> {challenge.typeName}
                                      </DefaultText>
                                  </Grid>
                                  <Grid item xs={12}>
                                      <DefaultText>
                                        {intl.formatMessage({id: "challenge.condition.description_label"})}
                                      </DefaultText>
                                      <RichText
                                        initial={JSON.parse(challenge.description)}
                                        readOnly={ true }
                                        onChange={() => {}}
                                        />
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12} sm={4}>
                                <Grid container spacing={1} direction='column' alignItems='center'>
                                  <Grid item>
                                    <CardMedia image={modeIcons[challenge.awardCode]} className={props.classes.icon} />
                                  </Grid>
                                  <Grid item>
                                    <BigText>
                                      Mode {challenge.awardName}
                                      {challenge.awardCode === 'P' && (

                                        <span style={{marginLeft: 5, lineHeight: 1, verticalAlign: 'middle'}}>
                                          <Tooltip title={intl.formatMessage({id: "challenge.form.step_mode_information"})} placement={'right'}>
                                            <BlueText style={{ width: 'fit-content' }} component={'span'}>
                                              <FontAwesomeIcon icon={faInfoCircle} />
                                            </BlueText>
                                          </Tooltip>
                                        </span>
                                      )}
                                    </BigText>
                                  </Grid>
                                  <Grid item style={{textAlign: 'center'}}>
                                    <DefaultText lowercase style={{fontSize: 14}}>
                                      { _.get(challenge, 'awards.length', 0) > 1 ?
                                        intl.formatMessage({id: `challenge.form.award_type_description_${_.lowerCase(challenge.awardCode)}`}).format(
                                          challenge.typeCode === 'CC' ? 'premiers' : 'premières équipes',
                                          challenge.typeCode === 'CC' ? 'participant' : 'équipe',
                                          _.get(challenge, 'awards.length')
                                        ) :
                                        intl.formatMessage({id: `challenge.form.award_type_description_single_${_.lowerCase(challenge.awardCode)}`}).format(
                                          challenge.typeCode === 'CC' ? 'le premier' : 'la première équipe',
                                          challenge.typeCode === 'CC' ? 'participant' : 'équipe'
                                        )
                                      }
                                    </DefaultText>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>


                        </Card>
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1}>
                    <Grid item xs={12}>
                        <DefaultTitle>{intl.formatMessage({id: "challenge.condition.award_area"})}</DefaultTitle>
                    </Grid>
                    <Grid item xs={12}>

                        <Grid container spacing={1} style={{marginBottom: 5}}>
                          <Grid item>
                            <CardMedia image={rewardTypeIcon} style={{height: 25, width: 25}} />
                          </Grid>
                          <Grid item>
                            <DefaultTitle style={{marginTop: 3}}>
                              {challenge.rewardTypeName}
                            </DefaultTitle>
                          </Grid>
                        </Grid>
                        { challenge.rewardTypeCode === 'G' ? renderAwards() : (

                          <AwardWrapperComponent>
                            { challenge.awardCode == 'M' && renderMaximumAward() }
                            { challenge.awardCode == 'R' && renderRankingAwards() }
                            { challenge.awardCode == 'C' && renderRankingAwards() }
                            { challenge.awardCode == 'P' && renderStepAwards() }
                          </AwardWrapperComponent>
                        ) }
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                open={rewardDetail}
                classes={{ paper: props.classes.rewardDialog }}
                onClose={() => setRewardDetail(null)}
            >
                <Grid container spacing={1} direction="column">
                  <Grid item>
                      <ChallengeRewardDetail reward={rewardDetail}/>
                  </Grid>
                </Grid>
            </Dialog>
        </div>
    )
}

export default withStyles(styles)(ChallengeCondition)
