import React from 'react'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { CardMedia } from '@material-ui/core'
import {faFileUpload, faFileDownload, faPlus} from '@fortawesome/free-solid-svg-icons'
import { injectIntl } from 'react-intl'
import {SubHeader, UserListImport} from './components'
import {DataTable, IconButton, Loader, MainLayoutComponent} from '../../../../components'
import * as userListActions from '../../../../services/Users/UserList/actions'
import * as userListExportActions from '../../../../services/Users/UserListExport/actions'
import {bindActionCreators} from 'redux'
import '../../../../helpers/NumberHelper'
import {Tooltip} from "@material-ui/core";
import api from '../../../../data/api/api';

class AdminUserList extends MainLayoutComponent {
    state = {
      importOpen: false,
      isActive: true
    };

    loadUserList(isActive) {
        this.props.userListActions.getUserList(isActive)
    }

    handleCreateClick() {
        this.props.history.push('/admin/users/creation')
    }

    handlePageChange = (page) => {
        const isActive = page == 0
        this.loadUserList(isActive)
        this.setState({
          ...this.state,
          isActive: isActive
        })
    };

    componentDidMount() {
        const { intl } = this.props
        this.props.handleTitle(intl.formatMessage({id: 'menu.admin_button'}));
        this.props.handleSubHeader(<SubHeader onChange={this.handlePageChange.bind(this)} />);
        this.props.handleButtons(<div>
            <Tooltip title={intl.formatMessage({id: 'common.export'})}>
                <IconButton size='small' onClick={this.export.bind(this)} style={{marginRight: 8}}><FontAwesomeIcon icon={faFileDownload} /></IconButton>
            </Tooltip>
            <Tooltip title={intl.formatMessage({id: 'common.import'})}>
                <IconButton size='small' onClick={this.onOpen.bind(this)} style={{marginRight: 8}}><FontAwesomeIcon icon={faFileUpload} /></IconButton>
            </Tooltip>
            <Tooltip title={intl.formatMessage({id: 'common.create'})}>
                <IconButton size='small' onClick={this.handleCreateClick.bind(this)}><FontAwesomeIcon icon={faPlus} /></IconButton>
            </Tooltip>
        </div>);
        this.props.activateReturn();
        this.loadUserList(true)
    }

    async export() {
      const { users } = this.props.userList;
      const request = new FormData();
      request.append('users', users);

      const response = await api.users.export(request, this.state.isActive)

      const blob = new Blob([response.data], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.setAttribute('hidden', '')
      a.setAttribute('href', url)
      a.setAttribute('download', `firetiger_users_${(new Date).toLocaleDateString()}.csv`)
      document.body.appendChild(a)

      a.click()
    }

    renderLoader() {
        return <Loader centered />
    }

    onOpen() {
        this.setState({
            ...this.state,
            importOpen: true
        })
    }

    onClose() {
        this.setState({
            ...this.state,
            importOpen: false
        })
    }

    renderData() {
        const { intl } = this.props
        const { history } = this.props;
        const { users } = this.props.userList;
        var columns = [
            { name: 'id', label: intl.formatMessage({id: 'admin.user.id'}) },
            { name: 'fullname', label: intl.formatMessage({id: 'admin.user.fullname'}) },
            { name: 'email', label: intl.formatMessage({id: 'admin.user.email'}) },
            { name: 'role.name', label: intl.formatMessage({id: 'admin.user.role'}) },
            { name: 'team.name', label: intl.formatMessage({id: 'admin.user.team'}) },
            { name: 'locale', label: intl.formatMessage({id: 'admin.user.locale'}), options: {
                customBodyRender: value => {
                    const icons = {
                      fr: require(`../../../../assets/img/system/flags/fr.svg`),
                      en: require(`../../../../assets/img/system/flags/gb.svg`)
                    }
                    return <CardMedia image={icons[value]} style={{height: 15, width: 20}}/>
                },
                filter: false
            } },
            { name: 'lastLogin', label: intl.formatMessage({id: 'admin.user.last_login'}), options: {
                customBodyRender: value => {
                    return <span>{ value ? value.toDate().toLocaleString() : intl.formatMessage({id: 'admin.user.last_login_empty'}) }</span>
                },
                filter: false
            } }
        ];
        const options = {
            selectableRows: 'none',
            onRowClick: (colData, cellMeta) => { history.push(`/admin/users/modification/${colData[0]}`) }
        };
        return (
            <div>
                <DataTable data={users} columns={columns} options={options} />
                <UserListImport open={this.state.importOpen} onClose={this.onClose.bind(this)} />
            </div>
        )
    }

    render() {
        const { users, loading } = this.props.userList;

        return (
            <div>
                { loading && this.renderLoader() }
                { !loading && users && this.renderData() }
            </div>
        )
    }
}

const mapStateToProps = ({ userList, userListExport }) => ({
    userList,
    userListExport
});

const mapDispatchToProps = (dispatch) => ({
    userListActions: bindActionCreators(userListActions, dispatch),
    userListExportActions: bindActionCreators(userListExportActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AdminUserList))
