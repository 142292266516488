import React, {useContext} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import Formsy from 'formsy-react'
import {Grid} from '@material-ui/core'
import {GoalDuplicationDialog} from './components'
import {AppBarSubTitle, Card, Loader, MainLayoutComponent, ProgressButton, Select, TextField, I18nWrapper, LanguageSelect, DefaultTitle, InfoText} from '../../../../components'
import {useIntl, injectIntl} from 'react-intl'
import * as collaboratorListActions from '../../../../services/Collaborators/CollaboratorList/actions'
import * as roleListActions from '../../../../services/Roles/RoleList/actions'
import * as teamListActions from '../../../../services/Teams/TeamList/actions'
import * as userDetailActions from '../../../../services/Users/UserDetail/actions'
import * as userUpdateActions from '../../../../services/Users/UserUpdate/actions'
import * as userUpdateActivationActions from '../../../../services/Users/UserUpdateActivation/actions'
import * as userUpdatePasswordActions from '../../../../services/Users/UserUpdatePassword/actions'
import * as Resources from '../../../../Resources'
import '../../../../helpers/FormsyHelper'

class AdminUserUpdate extends MainLayoutComponent {
    state = {role: null}

    constructor(props) {
        super(props)
        this.id = null
        this.initialized = false
        this.props.userUpdateActions.clearUserUpdate()
        this.props.userUpdateActivationActions.clearUserActivationUpdate()
    }

    componentDidMount() {
        const {intl} = this.props
        this.id = this.props.match.params.id
        this.props.handleTitle(intl.formatMessage({id: "admin.title"}))
        this.props.handleSubHeader(<AppBarSubTitle title={intl.formatMessage({id: "admin.user.update.title"})} />)
        this.props.handleMaxWidth('sm')
        this.props.activateReturn()
        this.props.collaboratorListActions.getCollaboratorList()
        this.props.roleListActions.getRoleList()
        this.props.teamListActions.getTeamList()
        this.props.userDetailActions.getUserDetail(this.id)
    }

    componentWillReceiveProps(props) {
        const {user} = props.userDetail
        if (!this.initialized && user) {
            this.state.role = user.role.id
        }
    }

    handleValueChange = name => value => {
        this.setState({
            ...this.state,
            [name]: value
        })
    }

    handleChangeActivationClick = () => {
        const {user} = this.props.userDetail
        this.props.userUpdateActivationActions.updateUserActivation(user)
    }

    handleValidSubmit(model) {
        model.id = this.id

        this.props.userUpdateActions.updateUser(model)
        if (model.password && model.password != '') {
            this.props.userUpdatePasswordActions.updateUserPassword(this.id, model.password)
        }
    }

    renderLoader() {
        return <Loader centered />
    }

    renderData() {
        const {intl} = this.props
        const {loading: userUpdateLoading} = this.props.userUpdate
        const {collaborators} = this.props.collaboratorList
        const {roles} = this.props.roleList
        const {teams} = this.props.teamList
        const {user} = this.props.userDetail
        const collaboratorRole = roles.find(r => r.code == 'C')
        const managerRole = roles.find(r => r.code == 'M')
        const selectableTeams = this.state.role == collaboratorRole.id ? teams : this.state.role == managerRole.id ? teams.filter(t => t.manager && t.manager.id == user.id || !t.manager) : null
        const initialTeam = selectableTeams && user.team && selectableTeams.find(x => x.id == user.team.id) != null ? user.team.id : null
        const {loading: userUpdateActivationLoading} = this.props.userUpdateActivation
        const activationButtonText = user.isActive ? intl.formatMessage({id: "admin.user.update.disable_button"}) : intl.formatMessage({id: "admin.user.update.enable_button"})


        const LanguageField = ({initial}) => {
          const context = useContext(I18nWrapper.Context);
          return (
            <LanguageSelect name="locale" label="Langue" initial={initial || context.locale} noCard />
          )
        }

        return (
            <div>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Formsy onValidSubmit={this.handleValidSubmit.bind(this)}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                  <Grid container spacing={1}>
                                    <Grid item>
                                      <DefaultTitle>
                                        {intl.formatMessage({id: "admin.user.information_title"})}
                                      </DefaultTitle>
                                    </Grid>
                                    <Grid item>
                                      <Card>
                                        <Grid container spacing={2}>
                                          <Grid item xs={12}>
                                            <Grid container justify="flex-start">
                                              <Grid item>
                                                <LanguageField initial={user.locale}/>
                                              </Grid>
                                            </Grid>
                                          </Grid>

                                          <Grid item xs={6}>
                                            <TextField name='firstname' label={intl.formatMessage({id: "admin.user.firstname"})} initial={user.firstname} fullWidth required lowercase
                                              validationErrors={{isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"})}}
                                              />
                                          </Grid>
                                          <Grid item xs={6}>
                                            <TextField name='lastname' label={intl.formatMessage({id: "admin.user.lastname"})} initial={user.lastname} fullWidth required lowercase
                                              validationErrors={{isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"})}}
                                              />
                                          </Grid>
                                          <Grid item xs={12}>
                                            <TextField name='email' label={intl.formatMessage({id: "admin.user.email"})} validations="isEmail" initial={user.email} fullWidth required lowercase
                                              validationErrors={{
                                                isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"}),
                                                isEmail: intl.formatMessage({id: "common.form.email_error"})
                                              }}
                                              />
                                          </Grid>
                                          <Grid item xs={6}>
                                            <Select name='role' label={intl.formatMessage({id: "admin.user.type"})} options={roles} optionValueName='id' optionTextName='name' initial={user.role ? user.role.id : null} fullWidth required
                                              onChange={this.handleValueChange('role').bind(this)}
                                              validationErrors={{
                                                isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"})
                                              }}
                                              />
                                          </Grid>
                                          <Grid item xs={6}>
                                            <Select name='team' label={intl.formatMessage({id: "admin.user.team"})} options={selectableTeams} optionValueName='id' optionTextName='name' initial={initialTeam} fullWidth />
                                          </Grid>

                                        </Grid>
                                      </Card>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container spacing={1}>
                                    <Grid item>
                                      <DefaultTitle>
                                        {intl.formatMessage({id: "admin.user.update.password_title"})}
                                      </DefaultTitle>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Card>
                                        <Grid container spacing={2}>
                                          <Grid item xs={12}>
                                            <InfoText lowercase>
                                              {intl.formatMessage({id: "admin.user.password_info"})}
                                            </InfoText>
                                          </Grid>
                                          <Grid item xs={6}>
                                            <TextField type='password' name='password' label={intl.formatMessage({id: "admin.user.password"})} fullWidth lowercase
                                              validations={{
                                                hasUppercaseCharacter: true,
                                                hasLowercaseCharacter: true,
                                                hasSpecialCharacter: true,
                                                hasMoreCharactersThan: 8,
                                              }}
                                              validationErrors={{
                                                isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"}),
                                                hasUppercaseCharacter: intl.formatMessage({id: 'common.form.has_uppercase_character'}),
                                                hasLowercaseCharacter: intl.formatMessage({id: 'common.form.has_lowercase_character'}),
                                                hasSpecialCharacter: intl.formatMessage({id: 'common.form.has_special_character'}),
                                                hasMoreCharactersThan: intl.formatMessage({id: 'common.form.has_more_characters_than'}).format(8),
                                              }}
                                              />
                                          </Grid>
                                          <Grid item xs={6}>
                                            <TextField type='password' name='paswwordConfirm' label={intl.formatMessage({id: "admin.user.password_confirm"})} fullWidth lowercase
                                              validations='equalsField:password'
                                              validationErrors={{
                                                isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"}),
                                                equalsField: intl.formatMessage({id: "common.form.password_not_match_error"})
                                              }}
                                              />
                                          </Grid>
                                        </Grid>
                                      </Card>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        <Grid container justify='space-between'>
                                            <Grid item>
                                                <ProgressButton type='button' text={activationButtonText} color='secondary' centered loading={userUpdateActivationLoading} onClick={this.handleChangeActivationClick.bind(this)} />
                                            </Grid>
                                            <Grid item>
                                                <ProgressButton type='submit' text={intl.formatMessage({id: "common.submit"})} centered loading={userUpdateLoading} />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Formsy>
                    </Grid>
                    <Grid item xs={12}>
                        <GoalDuplicationDialog allCollaborators={collaborators} teams={teams} user={user} />
                    </Grid>
                </Grid>
            </div>
        )
    }

    render() {
        const {collaborators, loading: collaboratorListLoading} = this.props.collaboratorList
        const {roles, loading: roleListLoading} = this.props.roleList
        const {teams, loading: teamListLoading} = this.props.teamList
        const {user, loading: userDetail} = this.props.userDetail
        const {success: userUpdateSuccess} = this.props.userUpdate
        const {success: userUpdateActivationSuccess} = this.props.userUpdateActivation
        const loading = collaboratorListLoading || roleListLoading || teamListLoading || userDetail

        if (userUpdateSuccess || userUpdateActivationSuccess) {
            this.props.userUpdateActions.clearUserUpdate()
            this.props.userUpdateActivationActions.clearUserActivationUpdate()
            this.props.history.goBack()
        }

        return (
            <div>
                {loading && this.renderLoader()}
                {!loading && collaborators && roles && teams && user && this.renderData()}
            </div>
        )
    }
}

const mapStateToProps = ({collaboratorList, roleList, teamList, userDetail, userUpdate, userUpdateActivation}) => ({
    collaboratorList,
    roleList,
    teamList,
    userDetail,
    userUpdate,
    userUpdateActivation
})

const mapDispatchToProps = (dispatch) => ({
    collaboratorListActions: bindActionCreators(collaboratorListActions, dispatch),
    roleListActions: bindActionCreators(roleListActions, dispatch),
    teamListActions: bindActionCreators(teamListActions, dispatch),
    userDetailActions: bindActionCreators(userDetailActions, dispatch),
    userUpdateActions: bindActionCreators(userUpdateActions, dispatch),
    userUpdateActivationActions: bindActionCreators(userUpdateActivationActions, dispatch),
    userUpdatePasswordActions: bindActionCreators(userUpdatePasswordActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AdminUserUpdate))
