import * as types from './actionTypes'

export const getTeamGroupList = (baseTeamGroup) => {
    return {
        type: types.GET_TEAM_GROUP_LIST,
        baseTeamGroup
    }
}

export const getTeamGroupListSuccess = (teamGroups) => {
    return {
        type: types.GET_TEAM_GROUP_LIST_SUCCESS,
        teamGroups
    }
}

export const getTeamGroupListError = () => {
    return {
        type: types.GET_TEAM_GROUP_LIST_ERROR
    }
}
