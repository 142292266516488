import React from 'react'
import {connect} from 'react-redux'
import {CardMedia, Grid} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {ImageInput} from './components'
import {Card, DefaultTitle, InfoText, Select, TextField, RichTextField} from '../../../../../../components'
import * as Resources from '../../../../../../Resources'
import {useIntl} from 'react-intl'

const useStyles = makeStyles({
    image: {
        height: '100%',
        width: '100%'
    }
})

const Infos = ({description, end, customImage, image, images, isUpdate, name, period, onTypeChange, start, type, types, setCustomImage, ...props}) => {
    const intl = useIntl()
    const classes = useStyles()
    const {account} = props.accountDetail
    const hasManager = account.role.code === 'M'
    const [selectedImageId, setSelectedImageId] = React.useState(image ? image : null)
    const selectedImage = customImage ? {path: customImage} : images.find(x => x.id === image)
    // console.log(selectedImage);

    const [selectedImagePath, setSelectedImagePath] = React.useState(selectedImage ? selectedImage.path : null)

    function handleImageChange(id) {
      if (id instanceof Blob) {
          var reader = new FileReader()
          reader.onloadend = function (e) {
              setSelectedImagePath(reader.result)
              setCustomImage(reader.result)
          }.bind(this)
          reader.readAsDataURL(id)
      } else {
        setSelectedImageId(Number(id))
        setSelectedImagePath(images.find(x => x.id === Number(id)).path)
      }
    }


    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <DefaultTitle>{intl.formatMessage({id: "challenge.form.info_area"})}</DefaultTitle>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <div>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                initial={name}
                                                label={intl.formatMessage({id: "challenge.form.info_name_label"})}
                                                name='name'
                                                required
                                                validationErrors={{isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"})}}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RichTextField
                                              fullWidth
                                              initial={description}
                                              label={intl.formatMessage({id: "challenge.form.info_description_label"})}
                                              name='description'
                                              required
                                              validationErrors={{isDefaultRequiredValue: intl.formatMessage({id: "common.form.required_error"})}}
                                            />

                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                {!selectedImagePath && <Grid alignItems={'center'} container justify={'center'} style={{height: '100%'}}>
                                    <Grid item>
                                        <InfoText align={'center'}>{intl.formatMessage({id: "challenge.form.info_no_image_text"})}</InfoText>
                                    </Grid>
                                </Grid>}
                                {selectedImagePath && <CardMedia className={classes.image} image={selectedImagePath} />}
                            </Grid>


                            <Grid item xs={12}>
                                <ImageInput
                                    images={images}
                                    initial={selectedImageId || customImage}
                                    label={intl.formatMessage({id: "challenge.form.info_image_label"})}
                                    name={'image'}
                                    required
                                    onChange={handleImageChange}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = ({accountDetail}) => ({
    accountDetail
})

export default connect(mapStateToProps)(Infos)
