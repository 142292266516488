import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseye, faSortAmountDown, faRandom, faChartLine } from '@fortawesome/free-solid-svg-icons'
import { FixedTableCell, FlexibleTableCell, RankEvolution, Table, TableBody, TableCell, TableChip, TableHead, TableHeadCell, TableRow, Avatar, TableRowHighlight } from '../../../../components'
import * as Resources from '../../../../Resources'
import {useIntl} from 'react-intl'
import '../../../../helpers/NumberHelper'
import _ from 'lodash'

const styles = {
    photo: {
        height: 34,
        width: 34
    }
}

const CollaboratorGoalRankList = ({ranks, collaboratorId, account, goal, ...props}) => {
    const intl = useIntl()
    const { classes } = props
    const mobileScreen = isWidthDown('xs', props.width)

    const hasPoints = _.get(goal, 'levels.length', 0) > 0
    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeadCell>
                            <FontAwesomeIcon icon={faSortAmountDown} />
                        </TableHeadCell>
                        <TableHeadCell colSpan={2}>{intl.formatMessage({id: "admin.goal.rank_list.player_column"})}</TableHeadCell>
                        <TableHeadCell style={{textAlign: 'center'}}>
                            <FontAwesomeIcon icon={faBullseye} />
                        </TableHeadCell>
                        { hasPoints && goal.allow_ranking_points && (

                          <TableHeadCell style={{textAlign: 'center'}}>{Resources.COLLABORATOR_GOAL_RANK_LIST_POINTS_COLUMN}</TableHeadCell>
                        ) }
                        <TableHeadCell style={{textAlign: 'center'}}>
                            <FontAwesomeIcon icon={faRandom} />
                        </TableHeadCell>
                        { goal.allow_ranking_latest_value && (

                          <TableHeadCell style={{textAlign: 'center'}}>
                            <FontAwesomeIcon icon={faChartLine} />
                          </TableHeadCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    { ranks.map(rank => {
                        const photo = rank.collaborator.photo ? rank.collaborator.photo : '/assets/img/user/avatar.svg'
                        const selected = rank.collaborator ? rank.collaborator.id == collaboratorId : false
                        const color = !selected ? 'default' : 'primary'
                        const TableRowComponent = selected ? TableRowHighlight : TableRow
                        return (
                            <TableRowComponent key={rank.id}>
                                <TableCell>
                                    <TableChip label={rank.rank ? rank.rank : '-'} />
                                </TableCell>
                                <FixedTableCell>
                                    <Avatar src={photo} className={classes.photo} entityId={ _.get(rank, 'collaborator.id') } fallbackName={ _.get(rank, 'collaborator.fullname') } />
                                </FixedTableCell>
                                <FlexibleTableCell color={color}>
                                  {mobileScreen ? (
                                    <React.Fragment>
                                      <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',}}>
                                        {rank.collaborator.firstname}
                                      </div>
                                      <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',}}>
                                        {rank.collaborator.lastname}
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',}}>
                                        {rank.collaborator.firstname} {rank.collaborator.lastname}
                                      </div>
                                    </React.Fragment>
                                  )}
                                </FlexibleTableCell>
                                <TableCell color={color} style={{textAlign: 'center'}}>
                                  <div>
                                    {rank.progression.toPercentage()}
                                  </div>
                                  {(_.get(account, 'role.code') === 'A' || _.get(account, 'role.code') === 'M') && (
                                    <div style={{fontSize: 10, opacity: 0.6}}>
                                      {rank.counter}&nbsp;/&nbsp;{rank.target}
                                    </div>
                                  ) }
                                </TableCell>
                                { hasPoints && goal.allow_ranking_points && (
                                  <TableCell color={color} style={{textAlign: 'center'}}>{rank.points}</TableCell>
                                )}
                                <TableCell style={{textAlign: 'center'}}>
                                    <RankEvolution evolution={rank.evolution} />
                                </TableCell>
                                { goal.allow_ranking_latest_value && (
                                  <TableCell color={color} style={{textAlign: 'center', color: rank.latest_result ? '#00E58D' : 'auto'}}>
                                    {rank.latest_result ? `+${rank.latest_result}` : 0}
                                  </TableCell>
                                ) }
                            </TableRowComponent>
                        )
                    }) }
                </TableBody>
            </Table>
        </div>
    )
}

export default withStyles(styles)(withWidth()(CollaboratorGoalRankList))
