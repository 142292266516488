import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { Redirect } from 'react-router-dom'
import {ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {Collaborator, DefaultTitle, EmptyState, Loader, TeamSelector} from '../../../../components'
import {useIntl} from 'react-intl'
import * as teamListActions from '../../../../services/Teams/TeamList/actions'

const styles = {
    panel: {
        backgroundColor: 'initial',
        borderRadius: 'initial',
        boxShadow: 'none'
    },
    panelSummary: {
        padding: 'initial'
    },
    panelDetails: {
        padding: 'initial',
        paddingBottom: 24
    }
}

const AdministratorCollaboratorSelector = ({onClick, contextUrl, contextUrlOptions, ...props}) => {
    const intl = useIntl()
    const {classes} = props
    const {teams, loading} = props.teamList
    const [team, setTeam] = useState()

    useEffect(() => {
        props.teamListActions.getTeamList()
    }, [])

    function renderLoader() {
        return <Loader centered />
    }

    function renderEmptyState() {
        return <EmptyState title={intl.formatMessage({id: "admin.collaborator_selector.empty_state_title"})} message={intl.formatMessage({id: "admin.collaborator_selector.empty_state_message"})} />
    }


    const urlParams = contextUrlOptions ? Object.keys(contextUrlOptions).reduce((acc, key) => {
      if(!acc) {
        acc = `${acc}?${key}=${contextUrlOptions[key]}`
      } else {
        acc = `${acc}&${key}=${contextUrlOptions[key]}`
      }
      return acc
    }, '') : ''
    function renderData() {
        return (
            <div>
                { team ? (
                  <Redirect to={`${contextUrl}${team.id}${urlParams}`} />
                ) : (
                  <TeamSelector
                    onClick={(teamId) => {
                      setTeam(teams.find(t => t.id === teamId))
                    }}
                  />
                )}
            </div>
        )
    }

    return (
        <div>
            {loading && renderLoader()}
            {!loading && teams && teams.length > 0 && renderData()}
            {!loading && teams && teams.length === 0 && renderEmptyState()}
        </div>
    )
}

const mapStateToProps = ({teamList}) => ({
    teamList
})

const mapDispatchToProps = (dispatch) => ({
    teamListActions: bindActionCreators(teamListActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdministratorCollaboratorSelector))
