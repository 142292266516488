import {DialogTitle} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'

const styles = {
    root: {
        paddingLeft: 0,
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        padding: 0,
        textAlign: 'justify',
        fontSize: '1.35rem'
    }
};

export default withStyles(styles)(DialogTitle)
