// Account
export const ACCOUNT_TITLE = "Mon profil"
export const ACCOUNT_FIRST_NAME_LABEL = "Prénom"
export const ACCOUNT_LAST_NAME_LABEL = "Nom"
export const ACCOUNT_EMAIL_LABEL = "Email"
export const ACCOUNT_CITATION_LABEL = "Citation"
export const ACCOUNT_PASSWORD_LABEL = "Nouveau mot de passe"
export const ACCOUNT_CONFIRM_PASSWORD_LABEL = "Confirmation du mot de passe"
export const ACCOUNT_SUBMIT_BUTTON = "Valider"
export const ACCOUNT_SUCCESS_MESSAGE = "Les modifications ont bien été effectuées"

// Admin
export const ADMIN_TITLE = "Administration"

// Admin - Aircall form
export const ADMIN_AIRCALL_FORM_AC_GET_CALLS_ACTIVATION = "Activer le KPI de récupération des appels aboutis"
export const ADMIN_AIRCALL_FORM_AC_GET_CALLS_DURATION = "Durée minimale d’un appel abouti (secondes)"
export const ADMIN_AIRCALL_FORM_SUBMIT_BUTTON = "Valider"

// Admin - Goal creation
export const ADMIN_GOAL_CREATION_TITLE = "Création d'un objectif"
export const ADMIN_GOAL_CREATION_KPI_LABEL = "KPI"
export const ADMIN_GOAL_CREATION_UNIT_LABEL = "Unité"
export const ADMIN_GOAL_CREATION_NAME_LABEL = "Intitulé"
export const ADMIN_GOAL_CREATION_KPI_NAME_LABEL = "Intitulé du KPI"
export const ADMIN_GOAL_CREATION_DESCRIPTION_LABEL = "Message"
export const ADMIN_GOAL_CREATION_TYPE_LABEL = "Type"
export const ADMIN_GOAL_CREATION_CATEGORY_LABEL = "Catégorie"
export const ADMIN_GOAL_CREATION_CRITICITY_LABEL = "Urgence"
export const ADMIN_GOAL_CREATION_PERIODICITY_LABEL = "Périodicité"
export const ADMIN_GOAL_CREATION_KPI_FORMAT_LABEL = "Format"
export const ADMIN_GOAL_CREATION_REPARTITION_LABEL = "Mode de répartition"
export const ADMIN_GOAL_CREATION_TARGET_LABEL = "Obj. global annuel"
export const ADMIN_GOAL_INDIVIDUAL_CREATION_TARGET_LABEL = "Obj. {1} par {0}"
export const ADMIN_GOAL_CREATION_REPARTITION_GLOBAL = "\
  💡En utilisant ce mode de répartition, l’objectif global annuel sera automatiquement réparti sur le nombre de {0} restant(es) sur l’année en cours et divisé par le nombre participants sélectionnés.​\n \
  Une fois validé vous aurez la possibilité de personnaliser les objectifs individuels attribués à l’ensemble de vos équipes.​"

export const ADMIN_GOAL_CREATION_REPARTITION_GLOBAL_TEAM = "\
  💡En utilisant ce mode de répartition, l’objectif global annuel sera automatiquement divisé par le nombre de {0} restant(es) sur l’année en cours et réparti proportionnellement selon le nombre de participants présents dans les équipes sélectionnées.​\n \
  Une fois validé vous aurez la possibilité de personnaliser les objectifs attribués à l’ensemble des équipes.​"

export const ADMIN_GOAL_CREATION_REPARTITION_INDIVIDUAL = "\
  💡En utilisant ce mode de répartition, l’objectif individuel sera automatiquement attribué à l’ensemble des participants sélectionnés sur les {0} restant(es) de l’année en cours.​\n \
  Une fois validé vous aurez la possibilité de personnaliser les objectifs individuels attribués à l’ensemble de vos équipes.​"

  export const ADMIN_GOAL_CREATION_REPARTITION_INDIVIDUAL_TEAM = "\
  💡En utilisant ce mode de répartition, l’objectif d’équipe sera automatiquement attribué à l’ensemble des équipes sélectionnées sur les {0} restant(es) de l’année en cours.​\n \
  Une fois validé vous aurez la possibilité de personnaliser les objectifs attribués à l’ensemble des équipes.​"

export const ADMIN_GOAL_CREATION_DEFAULT_LABEL = "Réalisé par défaut"
export const ADMIN_GOAL_CREATION_INDICATION_LABEL = "Indications"
export const ADMIN_GOAL_CREATION_LIVE_LABEL = "Points gagnés en live"
export const ADMIN_GOAL_CREATION_LIVE_INFOS = "Si activé, les points seront automatiquement gagnés par les participants avant la date de fin de l’objectif."
export const ADMIN_GOAL_CREATION_EDITABLE_LABEL = "Objectif modifiable par les managers"
export const ADMIN_GOAL_CREATION_PAST_EDITABLE_LABEL = "Objectif passé modifiable par les administrateurs"
export const ADMIN_GOAL_CREATION_ALLOW_OVER_TARGET_LABEL = "Dépassement de l'objectif alloué"
export const ADMIN_GOAL_CREATION_ALLOW_RANKING_LABEL = "Affichage du classement"
export const ADMIN_GOAL_CREATION_ADMIN_EDITABLE_LABEL = "Objectif modifiable par les administrateurs"
export const ADMIN_GOAL_CREATION_SUBMIT_BUTTON = "Valider"

export const ADMIN_GOAL_POINT_CONFIG_TITLE_D = 'Nombre de jours restants'
export const ADMIN_GOAL_POINT_CONFIG_TITLE_W = 'Nombre de semaines restantes'
export const ADMIN_GOAL_POINT_CONFIG_TITLE_M = 'Nombre de mois restants'
export const ADMIN_GOAL_POINT_CONFIG_TITLE_Q = 'Nombre de trimestres restants'
export const ADMIN_GOAL_POINT_CONFIG_TITLE_S = 'Nombre de semestres restants'
export const ADMIN_GOAL_POINT_CONFIG_TITLE_Y = 'Année en cours'

export const ADMIN_GOAL_POINT_CONFIG_TOOLTIP_D = 'les jours restants'
export const ADMIN_GOAL_POINT_CONFIG_TOOLTIP_W = 'les semaines restantes'
export const ADMIN_GOAL_POINT_CONFIG_TOOLTIP_M = 'les mois restants'
export const ADMIN_GOAL_POINT_CONFIG_TOOLTIP_Q = 'les trimestres restants'
export const ADMIN_GOAL_POINT_CONFIG_TOOLTIP_S = 'les semestres restants'
export const ADMIN_GOAL_POINT_CONFIG_TOOLTIP_Y = 'l\'année en cours'

// Admin - Goal update
export const ADMIN_GOAL_UPDATE_BASE_TITLE = "Modification de l'objectif « {0} »"
export const ADMIN_GOAL_UPDATE_READONLY_TITLE = "Visualisation de l'objectif « {0} »"
export const ADMIN_GOAL_UPDATE_BASE_TAB = "Base"
export const ADMIN_GOAL_UPDATE_CUSTOM_TAB = "Personnalisation"
export const ADMIN_GOAL_UPDATE_KPI_LABEL = "KPI"
export const ADMIN_GOAL_UPDATE_UNIT_LABEL = "Unité"
export const ADMIN_GOAL_UPDATE_NAME_LABEL = "Intitulé"
export const ADMIN_GOAL_UPDATE_TYPE_LABEL = "Type"
export const ADMIN_GOAL_UPDATE_CATEGORY_LABEL = "Catégorie"
export const ADMIN_GOAL_UPDATE_PERIODICITY_LABEL = "Périodicité"
export const ADMIN_GOAL_UPDATE_TARGET_LABEL = "Obj. global annuel"
export const ADMIN_GOAL_UPDATE_DEFAULT_LABEL = "Réalisé par défaut"
export const ADMIN_GOAL_UPDATE_INDICATION_LABEL = "Indications"
export const ADMIN_GOAL_UPDATE_LIVE_LABEL = "Points gagnés en live"
export const ADMIN_GOAL_UPDATE_LIVE_INFOS = "Si activé, les points seront automatiquement gagnés par les participants avant la date de fin de l’objectif."
export const ADMIN_GOAL_UPDATE_EDITABLE_LABEL = "Objectif modifiable par les managers"
export const ADMIN_GOAL_UPDATE_ADMIN_EDITABLE_LABEL = "Objectif modifiable par les administrateurs"
export const ADMIN_GOAL_UPDATE_SUBMIT_BUTTON = "Valider"
export const ADMIN_GOAL_UPDATE_DISABLE_BUTTON = "Archiver"
export const ADMIN_GOAL_UPDATE_DISABLE_MESSAGE = "Êtes-vous sûr de vouloir archiver l'objectif « {0} » ?"
export const ADMIN_GOAL_UPDATE_DISABLE_INFO = "Après l’archivage de cet objectif, il ne sera plus possible de le réactiver. Tous les points attribués sur les objectifs en cours et ultérieurs seront remis à disposition."
export const ADMIN_GOAL_UPDATE_YES_BUTTON = "Oui"
export const ADMIN_GOAL_UPDATE_NO_BUTTON = "Non"
export const ADMIN_GOAL_UPDATE_CONFIRMATION_MESSAGE = "Êtes-vous sûr de vouloir Modifier le KPI de cet objectif ? Cette modification peut avoir des conséquences sur l'historique des résultats. En cas de doute, n'hésitez pas à contacter nos services."


// Admin - Home
export const ADMIN_HOME_USER_AREA = "Utilisateurs et droits"
export const ADMIN_HOME_USER_LINK = "Abonnements utilisateurs"
export const ADMIN_HOME_TEAM_LINK = "Équipes"
export const ADMIN_HOME_ORGANIZATION_LINK = "Équipes / Organisation"
export const ADMIN_HOME_PERMISSION_LINK = "Gestion droits d'accès"
export const ADMIN_HOME_DATA_AREA = "Intégration et gestion de données"
export const ADMIN_HOME_PARTNER_LINK = "Intégrations partenaires"
export const ADMIN_HOME_REPORT_LINK = "Rapports"
export const ADMIN_HOME_IMPORT_LINK = "Journal d'imports"
export const ADMIN_HOME_LOGO_LINK = "Changement du logo"
export const ADMIN_HOME_INDICATOR_AREA = "Configuration des indicateurs"
export const ADMIN_HOME_YEAR_LABEL = "Année à configurer"
export const ADMIN_HOME_POINT_LINK = "Répartition générale des points"
export const ADMIN_HOME_CATEGORY_LINK = "Catégories"
export const ADMIN_HOME_GOAL_LINK = "Objectifs"
export const ADMIN_HOME_GOAL_LEVEL_LINK = "Points"
export const ADMIN_HOME_CHALLENGE_LINK = "Challenges"
export const ADMIN_HOME_BADGE_LINK = "Défis"
export const ADMIN_HOME_LEVEL_LINK = "Level"
export const ADMIN_HOME_REWARD_LINK = "Récompenses"

// Admin - Partner list
export const ADMIN_PARTNER_LIST_SUBTITLE = "Intégrations partenaires"

// Admin - Partner detail
export const ADMIN_PARTNER_DETAIL_SUBTITLE = "Intégrations partenaires"
export const ADMIN_PARTNER_DETAIL_EMPTY_CONNECTION = "Pour utiliser ces fonctionnalités, veuillez installer Fire Tiger sur votre espace client Aircall."

// Admin - Report detail
export const ADMIN_REPORT_DETAIL_SEMESTER_PERIOD = "Semestre {0}"
export const ADMIN_REPORT_DETAIL_QUARTER_PERIOD = "Trimestre {0}"
export const ADMIN_REPORT_DETAIL_WEEK_PERIOD = "Semaine {0}"

// Admin - Reward categories
export const ADMIN_REWARD_CATEGORY_LIST_SUBTITLE = "Configuration des catégories de récompenses"
export const ADMIN_REWARD_CATEGORY_LIST_ACTIVE_TAB = "Actives"
export const ADMIN_REWARD_CATEGORY_LIST_INACTIVE_TAB = "Archivées"
export const ADMIN_REWARD_CATEGORY_LIST_NAME_COLUMN = "Nom"
export const ADMIN_REWARD_CATEGORY_LIST_ICON_COLUMN = "Icône"

// Admin - Reward category creation
export const ADMIN_REWARD_CATEGORY_CREATION_SUBTITLE = "Création d'une catégorie de récompenses"
export const ADMIN_REWARD_CATEGORY_CREATION_NAME_LABEL = "Nom"
export const ADMIN_REWARD_CATEGORY_CREATION_ICON_LABEL = "Icône"
export const ADMIN_REWARD_CATEGORY_CREATION_SUBMIT_BUTTON = "Valider"

// Admin - Reward category update
export const ADMIN_REWARD_CATEGORY_UPDATE_SUBTITLE = "Modification d'une catégorie de récompenses"
export const ADMIN_REWARD_CATEGORY_UPDATE_NAME_LABEL = "Nom"
export const ADMIN_REWARD_CATEGORY_UPDATE_ICON_LABEL = "Icône"
export const ADMIN_REWARD_CATEGORY_UPDATE_SUBMIT_BUTTON = "Valider"

// Admin - User update
export const ADMIN_USER_UPDATE_TITLE = "Modification d'un utilisateur"
export const ADMIN_USER_UPDATE_FIRSTNAME = "Prénom"
export const ADMIN_USER_UPDATE_LASTNAME = "Nom"
export const ADMIN_USER_UPDATE_EMAIL = "Email"
export const ADMIN_USER_UPDATE_TYPE = "Type"
export const ADMIN_USER_UPDATE_TEAM = "Équipe"
export const ADMIN_USER_UPDATE_PASSWORD = "Nouveau mot de passe"
export const ADMIN_USER_UPDATE_PASSWORD_CONFIRM = "Confirmation du mot de passe"
export const ADMIN_USER_UPDATE_SUBMIT_BUTTON = "Valider"
export const ADMIN_USER_UPDATE_ENABLE_BUTTON = "Réactiver le compte"
export const ADMIN_USER_UPDATE_DISABLE_BUTTON = "Résilier le compte"

// Administrator collaborator selector
export const ADMINISTRATOR_COLLABORATOR_SELECTOR_EMPTY_STATE_TITLE = "Aucune équipe trouvée"
export const ADMINISTRATOR_COLLABORATOR_SELECTOR_EMPTY_STATE_MESSAGE = "Les équipes n'ont pas encore été créées"

// Aircall install
export const AIRCALL_INSTALL_TITLE = "Connexion avec Aircall"
export const AIRCALL_INSTALL_CODE_LABEL = "Code entreprise"
export const AIRCALL_INSTALL_EMAIL_LABEL = "Email"
export const AIRCALL_INSTALL_PASSWORD_LABEL = "Mot de passe"
export const AIRCALL_INSTALL_AUTHORIZATION_ERROR = "Vous n’avez pas les autorisations"
export const AIRCALL_INSTALL_LOGIN_ERROR = "Les identifiants sont incorrects"
export const AIRCALL_INSTALL_UNKNOWN_ERROR = "Une erreur inconnue est survenue"
export const AIRCALL_INSTALL_SUBMIT_BUTTON = "Connecter Aircall"
export const AIRCALL_INSTALL_NO_CUSTOMER_BUTTON_1 = "Pas encore"
export const AIRCALL_INSTALL_NO_CUSTOMER_BUTTON_2 = "client Fire Tiger ?"
export const AIRCALL_INSTALL_WEBSITE_BUTTON = "Voir site web"
export const AIRCALL_INSTALL_DEMO_BUTTON = "Démonstration"

// Badges
export const BADGE_LONG_TITLE = "Les défis"
export const BADGE_SHORT_TITLE = "Défis"

// Badges - level
export const BADGE_LEVEL_COUNTER_TEXT = "Réalisé : {0}"
export const BADGE_LEVEL_TARGET_TEXT = "Objectif : {0}"
export const BADGE_LEVEL_RANK_TEXT = "Rang {0}"
export const BADGE_LEVEL_CONDITION_TEXT = "Condition : lvl {0}"
export const BADGE_LEVEL_POINT_TEXT = "{0} PTS"

// Badges - list
export const BADGE_LIST_EMPTY_STATE_TITLE = "Aucun défi trouvé"
export const BADGE_LIST_CURRENT_TAB = "En cours"
export const BADGE_LIST_COMPLETED_TAB = "Réussis"

// Challenge
export const CHALLENGE_LONG_TITLE = "Challenges"
export const CHALLENGE_SHORT_TITLE = "Challenges"
export const CHALLENGE_FIRST_RANK = "{0}er"
export const CHALLENGE_OTHER_RANK = "{0}ème"
export const CHALLENGE_COLLABORATORS = "{0} joueurs"
export const CHALLENGE_TEAMS = "{0} équipes"
export const CHALLENGE_POINTS = "Score : {0}"
export const CHALLENGE_MAX_POINTS = "{0} MAX"


// Challenge - Award list
export const CHALLENGE_AWARD_LIST_TITLE = "Récompenses"
export const CHALLENGE_AWARD_TARGET_LABEL = "Score à atteindre"
export const CHALLENGE_AWARD_LIST_POINTS_CALCULATION_MESSAGE = "Calcul des pts utilisables..."
export const CHALLENGE_AWARD_LIST_USABLE_POINTS = "{0} pts utilisables"
export const CHALLENGE_AWARD_LIST_TYPE_LABEL = "Mode"
export const CHALLENGE_AWARD_LIST_COLLABORATOR_MAX_POINT_LABEL = "Maximum / joueur"
export const CHALLENGE_AWARD_LIST_TEAM_MAX_POINT_LABEL = "Maximum / équipe"
export const CHALLENGE_AWARD_LIST_COLLABORATOR_POINT_LABEL = "Gain joueur #{0}"
export const CHALLENGE_AWARD_LIST_TEAM_POINT_LABEL = "Gain équipe #{0}"
export const CHALLENGE_AWARD_STEP_POINT_LABEL = "Palier #{0}"
export const CHALLENGE_AWARD_LIST_LIVE_LABEL = "Points gagnés en live"
export const CHALLENGE_AWARD_LIST_LIVE_INFOS = "Si activé, les points seront automatiquement gagnés par les participants avant la date de fin du challenge."

// Challenge - Category list
export const CHALLENGE_CATEGORY_LIST_TITLE = "Sélection de la catégorie"

// Challenge - Collaborator challenge detail
export const COLLABORATOR_CHALLENGE_DETAIL_RANK_TAB = "Classements"
export const COLLABORATOR_CHALLENGE_DETAIL_CONDITION_TAB = "Conditions"

// Challenge - Collaborator challenge list
export const COLLABORATOR_CHALLENGE_LIST_EMPTY_STATE_TITLE = "Aucun challenge trouvé"
export const COLLABORATOR_CHALLENGE_LIST_EMPTY_STATE_MESSAGE = "Si vous avez appliqué des filtres, changez-les pour afficher d'autres challenges"

// Challenge - Condition
export const CHALLENGE_CONDITION_CONDITION_AREA = "Indicateurs"
export const CHALLENGE_CONDITION_GOAL_INFO = "La condition fixée ici peut être atteinte plusieurs fois. Chaque fois que celle-ci est atteinte elle rapporte le nombre de points associés"
export const CHALLENGE_RACE_CONDITION_GOAL_INFO = "Si l'objectif est atteint, il rapporte 1 point de score"
export const CHALLENGE_CONDITION_POINT_TARGET = "{0} PTS"
export const CHALLENGE_CONDITION_POINT_COUNTER = "{0} PTS"
export const CHALLENGE_CONDITION_DESCRIPTION_AREA = "Description"
export const CHALLENGE_CONDITION_PERIOD = "Du {0} au {1}"
export const CHALLENGE_CONDITION_TYPE = "Mode : {0}"
export const CHALLENGE_CONDITION_DESCRIPTION_LABEL = "Description"
export const CHALLENGE_CONDITION_AWARD_AREA = "Récompenses du challenge"
export const CHALLENGE_CONDITION_COLLABORATOR_MAX_POINTS_LABEL = "Maximum / joueur"
export const CHALLENGE_CONDITION_TEAM_MAX_POINTS_LABEL = "Maximum / équipe"
export const CHALLENGE_CONDITION_COLLABORATOR_RANK = "Joueur #{0}"
export const CHALLENGE_CONDITION_TEAM_RANK = "Équipe #{0}"
export const CHALLENGE_CONDITION_AWARD_POINTS = "{0} PTS"

// Challenge - Filter
export const CHALLENGE_FILTER_TITLE = "Filtres"
export const CHALLENGE_FILTER_TEAM_GROUP_LABEL = "Département"
export const CHALLENGE_FILTER_TEAM_LABEL = "Équipe"
export const CHALLENGE_FILTER_MY_TEAM_LABEL = "Mon équipe"
export const CHALLENGE_FILTER_COLLABORATOR_LABEL = "Collaborateur"
export const CHALLENGE_FILTER_COLLABORATOR_ALL_OPTION = "Tous"
export const CHALLENGE_FILTER_PERIOD_LABEL = "Année"
export const CHALLENGE_FILTER_TYPE_LABEL = "Mode"
export const CHALLENGE_FILTER_START_LABEL = "Date de début"
export const CHALLENGE_FILTER_END_LABEL = "Date de fin"
export const CHALLENGE_FILTER_CANCEL_BUTTON = "Annuler"
export const CHALLENGE_FILTER_SUBMIT_BUTTON = "Filtrer"

// Challenge - Team challenge ranking
export const COLLABORATOR_CHALLENGE_RANKING_COLLABORATOR_COLUMN = "Joueurs"
export const COLLABORATOR_CHALLENGE_RANKING_POINTS_COLUMN = "Score"

// Challenge - Creation
export const CHALLENGE_CREATION_TITLE = "Création d'un challenge"
export const CHALLENGE_CREATION_INFO_AREA = "Informations"
export const CHALLENGE_CREATION_INFO_NAME_LABEL = "Nom"
export const CHALLENGE_CREATION_INFO_DESCRIPTION_LABEL = "Description"
export const CHALLENGE_CREATION_INFO_NO_IMAGE_TEXT = "Aucune image sélectionée"
export const CHALLENGE_CREATION_INFO_START_LABEL = "Début"
export const CHALLENGE_CREATION_INFO_END_LABEL = "Fin"
export const CHALLENGE_CREATION_INFO_TYPE_LABEL = "Type"
export const CHALLENGE_CREATION_INFO_AWARD_TYPE_LABEL = "Mode"
export const CHALLENGE_CREATION_INFO_IMAGE_LABEL = "Sélectionner une image..."
export const CHALLENGE_CREATION_GOAL_AREA = "Indicateurs"
export const CHALLENGE_CREATION_GOAL_TITLE = "Indicateur {0}"
export const CHALLENGE_CREATION_GOAL_CATEGORY_LABEL = "Catégorie"
export const CHALLENGE_CREATION_GOAL_KPI_LABEL = "Kpi"
export const CHALLENGE_CREATION_GOAL_NAME_LABEL = "Intitulé"
export const CHALLENGE_CREATION_GOAL_UNIT_LABEL = "Unité"
export const CHALLENGE_CREATION_GOAL_TARGET_LABEL = "Objectif"
export const CHALLENGE_CREATION_GOAL_TARGET_INFO_TEXT = "La condition fixée ici peut être atteinte plusieurs fois. Chaque fois que celle-ci est atteinte elle rapporte le nombre de points associés."
export const CHALLENGE_CREATION_GOAL_POINTS_LABEL = "Pts"
export const CHALLENGE_CREATION_SUBMIT_BUTTON = "Valider"
export const CHALLENGE_CREATION_AWARD_TYPE_DESCRIPTION_M = "Chaque {1} gagne le nombre de points correspondants à son score, dans la limite du maximum défini"
export const CHALLENGE_CREATION_AWARD_TYPE_DESCRIPTION_R = "Récompense pour les X {0} à la fin du challenge"
export const CHALLENGE_CREATION_AWARD_TYPE_DESCRIPTION_P = "La récompense obtenue par les participants dépend du niveau de performance atteint"
export const CHALLENGE_CREATION_AWARD_TYPE_DESCRIPTION_C = "Récompense pour les X {0} à atteindre les objectifs du challenge"
export const CHALLENGE_CREATION_AWARD_TYPE_MINIMUM_PARTICIPANTS = "Ce mode nécessite au moins {0} {1}"
export const CHALLENGE_CREATION_GOAL_TARGET_LABEL2 = "Condition"
export const CHALLENGE_CREATION_GOAL_TARGET_LABEL2_RACE = "Objectif"
export const CHALLENGE_CREATION_GOAL_POINTS_LABEL2 = "Pts"
export const CHALLENGE_STEP_MODE_INFORMATION = "Maximum 1 récompense par participant (la récompense gagnée est celle du plus haut palier)"

// Challenge - Duplication
export const CHALLENGE_DUPLICATION_TITLE = "Duplication d'un challenge"
export const CHALLENGE_DUPLICATION_INFO_AREA = "Informations"
export const CHALLENGE_DUPLICATION_INFO_NAME_LABEL = "Nom"
export const CHALLENGE_DUPLICATION_INFO_DESCRIPTION_LABEL = "Description"
export const CHALLENGE_DUPLICATION_INFO_NO_IMAGE_TEXT = "Aucune image sélectionée"
export const CHALLENGE_DUPLICATION_INFO_START_LABEL = "Début"
export const CHALLENGE_DUPLICATION_INFO_END_LABEL = "Fin"
export const CHALLENGE_DUPLICATION_INFO_TYPE_LABEL = "Mode"
export const CHALLENGE_DUPLICATION_INFO_IMAGE_LABEL = "Sélectionner une image..."
export const CHALLENGE_DUPLICATION_GOAL_AREA = "Indicateurs"
export const CHALLENGE_DUPLICATION_GOAL_TITLE = "Indicateur {0}"
export const CHALLENGE_DUPLICATION_GOAL_CATEGORY_LABEL = "Catégorie"
export const CHALLENGE_DUPLICATION_GOAL_KPI_LABEL = "Kpi"
export const CHALLENGE_DUPLICATION_GOAL_NAME_LABEL = "Intitulé"
export const CHALLENGE_DUPLICATION_GOAL_UNIT_LABEL = "Unité"
export const CHALLENGE_DUPLICATION_GOAL_TARGET_LABEL = "Objectif"
export const CHALLENGE_DUPLICATION_GOAL_TARGET_INFO_TEXT = "La condition fixée ici peut être atteinte plusieurs fois. Chaque fois que celle-ci est atteinte elle rapporte le nombre de points associés."
export const CHALLENGE_DUPLICATION_GOAL_POINTS_LABEL = "Pts"
export const CHALLENGE_DUPLICATION_SUBMIT_BUTTON = "Valider"

// Challenge - Team challenge detail
export const TEAM_CHALLENGE_DETAIL_RANK_TAB = "Classements"
export const TEAM_CHALLENGE_DETAIL_CONDITION_TAB = "Conditions"
export const TEAM_CHALLENGE_DETAIL_DUPLICATE_BUTTON = "Dupliquer"
export const TEAM_CHALLENGE_DETAIL_UPDATE_BUTTON = "Éditer"

// Challenge - Team challenge list
export const TEAM_CHALLENGE_LIST_CREATE_BUTTON = "Créer un challenge"
export const TEAM_CHALLENGE_LIST_FILTER_BUTTON = "Filtrer"
export const TEAM_CHALLENGE_LIST_EMPTY_STATE_TITLE = "Aucun challenge trouvé"
export const TEAM_CHALLENGE_LIST_EMPTY_STATE_MESSAGE = "Si vous avez appliqué des filtres, changez-les pour afficher d'autres challenges"

// Challenge - Team challenge ranking
export const TEAM_CHALLENGE_RANKING_TEAM_COLUMN = "Équipes"
export const TEAM_CHALLENGE_RANKING_POINTS_COLUMN = "Score"

// Challenge - Team collaborator challenge detail
export const TEAM_COLLABORATOR_CHALLENGE_DETAIL_RANK_TAB = "Classements"
export const TEAM_COLLABORATOR_CHALLENGE_DETAIL_CONDITION_TAB = "Conditions"
export const TEAM_COLLABORATOR_CHALLENGE_DETAIL_DUPLICATE_BUTTON = "Dupliquer"
export const TEAM_COLLABORATOR_CHALLENGE_DETAIL_DELETE_BUTTON = "Supprimer"
export const TEAM_COLLABORATOR_CHALLENGE_DETAIL_UPDATE_BUTTON = "Éditer"

// Challenge - Time filter
export const CHALLENGE_TIME_FILTER_CURRENT_TAB = "En cours"
export const CHALLENGE_TIME_FILTER_PREVIOUS_TAB = "Passés"
export const CHALLENGE_TIME_FILTER_NEXT_TAB = "Futurs"

// Challenge - Update
export const CHALLENGE_UPDATE_TITLE = "Modification d'un challenge"
export const CHALLENGE_UPDATE_INFO_AREA = "Informations"
export const CHALLENGE_UPDATE_INFO_NAME_LABEL = "Nom"
export const CHALLENGE_UPDATE_INFO_DESCRIPTION_LABEL = "Description"
export const CHALLENGE_UPDATE_INFO_NO_IMAGE_TEXT = "Aucune image sélectionée"
export const CHALLENGE_UPDATE_INFO_START_LABEL = "Début"
export const CHALLENGE_UPDATE_INFO_END_LABEL = "Fin"
export const CHALLENGE_UPDATE_INFO_TYPE_LABEL = "Mode"
export const CHALLENGE_UPDATE_INFO_IMAGE_LABEL = "Sélectionner une image..."
export const CHALLENGE_UPDATE_GOAL_AREA = "Indicateurs"
export const CHALLENGE_UPDATE_GOAL_TITLE = "Indicateur {0}"
export const CHALLENGE_UPDATE_GOAL_CATEGORY_LABEL = "Catégorie"
export const CHALLENGE_UPDATE_GOAL_KPI_LABEL = "Kpi"
export const CHALLENGE_UPDATE_GOAL_NAME_LABEL = "Intitulé"
export const CHALLENGE_UPDATE_GOAL_UNIT_LABEL = "Unité"
export const CHALLENGE_UPDATE_GOAL_PERIODICITY_LABEL = "Periodicité"
export const CHALLENGE_UPDATE_GOAL_FORMAT_LABEL = "Format"
export const CHALLENGE_UPDATE_GOAL_TARGET_LABEL = "Condition"
export const CHALLENGE_UPDATE_GOAL_TARGET_LABEL_RACE = "Objectif"
export const CHALLENGE_UPDATE_GOAL_TARGET_INFO_TEXT = "La condition fixée ici peut être atteinte plusieurs fois. Chaque fois que celle-ci est atteinte elle rapporte le nombre de points associés."
export const CHALLENGE_UPDATE_GOAL_POINTS_LABEL = "Pts"
export const CHALLENGE_UPDATE_SUBMIT_BUTTON = "Valider"
export const CHALLENGE_UPDATE_AWARD_ERROR = "Ajoutez au moins une récompense"

// Chart
export const CHART_X_LEGEND = "Période"
export const CHART_Y_LEGEND = "%"
export const CHART_TARGET_TEXT = "Objectif : {0}"
export const CHART_COUNTER_TEXT = "Réalisé : {0}"
export const CHART_POINTS_TEXT = "{0} pts"
export const CHART_MAX_POINTS_TEXT = "/ {0} max"
export const CHART_COLLABORATOR_TYPE_TEXT = "Solo"
export const CHART_TEAM_TYPE_TEXT = "Équipe"

// Coaching list
export const COACHING_LIST_LONG_TITLE = "Les coaching lists"
export const COACHING_LIST_SHORT_TITLE = "Coaching list"
export const COACHING_LIST_EMPTY_STATE_TITLE = "Aucune instruction trouvée"
export const COACHING_LIST_EMPTY_STATE_COLLABORATOR_MESSAGE = "Revenez plus tard lorsque votre manager vous coachera"
export const COACHING_LIST_EMPTY_STATE_MANAGER_MESSAGE = "Créer une première instruction pour votre collaborateur"
export const COACHING_LIST_SUBMIT_BUTTON = "Valider"

// Collaborator
export const COLLABORATOR_TITLE = "Collaborateurs"

// Collaborator - Detail
export const COLLABORATOR_DETAIL_PASSWORD_BUTTON = "Modifier le mot de passe"
export const COLLABORATOR_DETAIL_FILTER_BUTTON = "Filtrer"
export const COLLABORATOR_DETAIL_EMPTY_STATE_TITLE = "Aucune donnée disponible"
export const COLLABORATOR_DETAIL_INFO_AREA = "Informations générales"
export const COLLABORATOR_DETAIL_INFO_TOTAL_POINTS = "{0} PTS"
export const COLLABORATOR_DETAIL_INFO_CURRENT_LEVEL = "{0} "
export const COLLABORATOR_DETAIL_INFO_CURRENT_LEVEL_MAX = "/ {0} PTS"
export const COLLABORATOR_DETAIL_INFO_NEXT_LEVEL = "Level {0}"
export const COLLABORATOR_DETAIL_INFO_MAX_LEVEL = "Level max atteint"
export const COLLABORATOR_DETAIL_INFO_FIRST_RANK_TEXT = "{0}er"
export const COLLABORATOR_DETAIL_INFO_OTHER_RANK_TEXT = "{0}ème"
export const COLLABORATOR_DETAIL_INFO_VICTORIES = "{0} victoire(s)"
export const COLLABORATOR_DETAIL_BADGE_AREA = "Défis"
export const COLLABORATOR_DETAIL_BADGE_RANK = "Rang {0}"

// Collaborator - Filter
export const COLLABORATOR_FILTER_TITLE = "Filtres"
export const COLLABORATOR_FILTER_TEAM_LABEL = "Équipe"
export const COLLABORATOR_FILTER_COLLABORATOR_LABEL = "Collaborateur"
export const COLLABORATOR_FILTER_PERIOD_LABEL = "Année"
export const COLLABORATOR_FILTER_CANCEL_BUTTON = "Annuler"
export const COLLABORATOR_FILTER_SUBMIT_BUTTON = "Filtrer"

// Collaborator reward list
export const COLLABORATOR_REWARD_LIST_COLLABORATOR_TAB = "Joueur"
export const COLLABORATOR_REWARD_LIST_TEAM_TAB = "Équipe"

// Collaborator reward order summary
export const COLLABORATOR_REWARD_ORDER_SUMMARY_TITLE = "Récapitulatif d'une commande"
export const COLLABORATOR_REWARD_ORDER_SUMMARY_REWARDS_AREA = "Commande n°{0} de {1}"
export const COLLABORATOR_REWARD_ORDER_SUMMARY_POINTS_AREA = "Total commande"
export const COLLABORATOR_REWARD_ORDER_SUMMARY_POINTS_AREA_YEAR = "Année {0}"

// Collaborator reward order tracking
export const COLLABORATOR_REWARD_ORDER_TRACKING_ID_COLUMN = "Ref"
export const COLLABORATOR_REWARD_ORDER_TRACKING_NAME_COLUMN = "Nom"
export const COLLABORATOR_REWARD_ORDER_TRACKING_EMAIL_COLUMN = "Email"
export const COLLABORATOR_REWARD_ORDER_TRACKING_TEAM_COLUMN = "Équipe"
export const COLLABORATOR_REWARD_ORDER_TRACKING_WAITING_POINTS_COLUMN = "Pts à valider"
export const COLLABORATOR_REWARD_ORDER_TRACKING_VALIDATED_POINTS_COLUMN = "Pts validés"
export const COLLABORATOR_REWARD_ORDER_TRACKING_VALUE_COLUMN = "Montant total"
export const COLLABORATOR_REWARD_ORDER_TRACKING_ORDER_DATE_COLUMN = "Date de commande"
export const COLLABORATOR_REWARD_ORDER_TRACKING_VALIDATION_DATE_COLUMN = "Date de validation"

// Collaborator reward order validation
export const COLLABORATOR_REWARD_ORDER_VALIDATION_TITLE = "Validation d'une commande"
export const COLLABORATOR_REWARD_ORDER_VALIDATION_REWARDS_AREA = "Commande n°{0} de {1}"
export const COLLABORATOR_REWARD_ORDER_VALIDATION_POINTS_AREA = "Total commande"
export const COLLABORATOR_REWARD_ORDER_VALIDATION_POINTS_AREA_YEAR = "Année {0}"

// Common
export const COMMON_EMAIL_ERROR = "L'email n'est pas valide."
export const COMMON_IS_INT_ERROR = "La valeur doit être un nombre entier."
export const COMMON_IS_MORE_THAN_OR_EQUALS_0_ERROR = "La valeur doit être supérieure ou égal à 0."
export const COMMON_MAX_LENGTH_128_ERROR = "La valeur doit faire moins de 128 caractères."
export const COMMON_PASSWORD_NOT_MATCH_ERROR = "Les mots de passe ne correspondent pas."
export const COMMON_REQUIRED_ERROR = "Ce champ est requis."

// Contact
export const CONTACT_TITLE = "Aide"
export const CONTACT_QUESTION = "Comment pouvons-nous vous aider ?"
export const CONTACT_FORM_TYPE_LABEL = "Motif"
export const CONTACT_FORM_TYPE_EVOLUTION_OPTION = "Demander une évolution"
export const CONTACT_FORM_TYPE_INCIDENT_OPTION = "Déclarer un incident"
export const CONTACT_EVOLUTION_FORM_TITLE = "Remplissez ce formulaire pour votre demande d'évolution"
export const CONTACT_EVOLUTION_FORM_MESSAGE_LABEL = "Message"
export const CONTACT_EVOLUTION_FORM_SUBMIT_BUTTON = "Envoyer"
export const CONTACT_INCIDENT_FORM_TITLE = "Remplissez ce formulaire pour votre déclaration d'indicent"

// Drawer
export const DRAWER_LEVEL_LABEL = "Level {0}"
export const DRAWER_POINTS_LABEL = "{0} PTS"
export const DRAWER_MANAGER_LABEL = "Manager"
export const DRAWER_ADMINISTRATOR_LABEL = "Administrateur"
export const DRAWER_INFOS_BUTTON = "Mes infos"
export const DRAWER_NOTIFICATIONS_BUTTON = "Notifications"
export const DRAWER_GOALS_BUTTON = "Objectifs"
export const DRAWER_CHALLENGES_BUTTON = "Challenges"
export const DRAWER_BADGES_BUTTON = "Défis"
export const DRAWER_COACHING_LIST_BUTTON = "Coaching list"
export const DRAWER_RANKINGS_BUTTON = "Classements"
export const DRAWER_TEAMS_BUTTON = "Équipes"
export const DRAWER_STATS_BUTTON = "Statistiques"
export const DRAWER_REWARDS_BUTTON = "Récompenses"
export const DRAWER_RULES_BUTTON = "Règles du jeu"
export const DRAWER_ADMIN_BUTTON = "Administration"
export const DRAWER_HELP_BUTTON = "Aide"
export const DRAWER_LOGOUT_BUTTON = "Déconnexion"

// Game rules
export const GAME_RULES_TITLE = "Règles du jeu"
export const GAME_RULES_SUBMIT_BUTTON = "Valider"
export const GAME_RULES_CANCEL_BUTTON = "Annuler"

// Goal
export const GOAL_LONG_TITLE = "Objectifs"
export const GOAL_SHORT_TITLE = "Objectifs"
export const GOAL_YEAR_PERIOD = "Année {0}"
export const GOAL_SEMESTER_PERIOD = "Semestre {0}"
export const GOAL_QUARTER_PERIOD = "Trimestre {0} ({1})"
export const GOAL_WEEK_PERIOD = "Semaine {0} ({1})"
export const GOAL_OTHER_PERIOD = "Du {0} au {1}"
export const GOAL_COUNTER_TEXT = "Réalisé : {0}"
export const GOAL_TARGET_TEXT = "/ Objectif : {0}"
export const GOAL_TIMER_TAG = "J{0}"
export const GOAL_PROGRESSION_TEXT = "{0}%"
export const GOAL_FIRST_RANK_TEXT = "{0} er"
export const GOAL_OTHER_RANK_TEXT = "{0} ème"
export const GOAL_MAX_RANK_TEXT = "/ {0}"
export const GOAL_PLAYER_TEXT = "{0} joueurs"
export const GOAL_TEAM_TEXT = "{0} équipes"
export const GOAL_POINTS_TEXT = "{0} PTS"
export const GOAL_MAX_POINTS_TEXT = "/ {0} MAX"
export const GOAL_COLLABORATOR_TAG = "Solo"
export const GOAL_TEAM_TAG = "Équipe"

// Goal - Category filter
export const GOAL_CATEGORY_FILTER_TITLE = "Filtres"
export const GOAL_CATEGORY_FILTER_TEAM_LABEL = "Équipe"
export const GOAL_CATEGORY_FILTER_COLLABORATOR_LABEL = "Collaborateur"
export const GOAL_CATEGORY_FILTER_COLLABORATOR_ALL_OPTION = "Tous"
export const GOAL_CATEGORY_FILTER_PERIOD_LABEL = "Année"
export const GOAL_CATEGORY_FILTER_CANCEL_BUTTON = "Annuler"
export const GOAL_CATEGORY_FILTER_SUBMIT_BUTTON = "Filtrer"

// Goal - Collaborator category list
export const COLLABORATOR_GOAL_CATEGORY_LIST_TITLE = "Sélection de la catégorie"
export const COLLABORATOR_GOAL_CATEGORY_LIST_ALL_LABEL = "Toutes"

// Goal - Collaborator goal detail
export const COLLABORATOR_GOAL_DETAIL_RANK_TAB = "Classement"
export const COLLABORATOR_GOAL_DETAIL_INDICATION_TAB = "Indications"

// Goal - Collaborator goal list
export const COLLABORATOR_GOAL_LIST_EMPTY_STATE_TITLE = "Aucun objectif trouvé"
export const COLLABORATOR_GOAL_LIST_EMPTY_STATE_MESSAGE = "Si vous avez appliqué des filtres, changez-les pour afficher d'autres objectifs"

// Goal - Collaborator rank list
export const COLLABORATOR_GOAL_RANK_LIST_PLAYER_COLUMN = "Joueurs"
export const COLLABORATOR_GOAL_RANK_LIST_POINTS_COLUMN = "PTS"

// Goal - Edition
export const COLLABORATOR_GOAL_LIST_EDITION_TITLE = "Indicateurs"
export const COLLABORATOR_GOAL_LIST_EDITION_MAX_TARGET_LABEL = "Objectif alloué pour la période sélectionnée"
export const COLLABORATOR_GOAL_LIST_EDITION_ALL_TARGET_LABEL = "Objectif utilisé"
export const COLLABORATOR_GOAL_LIST_EDITION_REMAINING_TARGET_LABEL = "Objectif restant"
export const COLLABORATOR_GOAL_LIST_EDITION_EMPTY_STATE_TITLE = "Aucun objectif trouvé"
export const COLLABORATOR_GOAL_LIST_EDITION_EMPTY_STATE_MESSAGE = "Si vous avez appliqué des filtres, changez-les pour afficher d'autres objectifs"
export const COLLABORATOR_GOAL_LIST_EDITION_ERROR_TEXT = "Veuillez respecter l'objectif total alloué pour la période sélectionnée"
export const COLLABORATOR_GOAL_LIST_EDITION_SUBMIT_BUTTON = "Valider"

// Goal - Indication
export const GOAL_INDICATION_LEVEL_AREA = "Paliers"
export const GOAL_INDICATION_LEVEL_PROGRESSION_TEXT = "{0} %"
export const GOAL_INDICATION_LEVEL_POINTS_TEXT = "{0} PTS"
export const GOAL_INDICATION_DESCRIPTION_AREA = "Description"
export const GOAL_INDICATION_UNIT_WITH_SYMBOL_TEXT = "Unité : {0} ({1})"
export const GOAL_INDICATION_UNIT_WITHOUT_SYMBOL_TEXT = "Unité : {0}"
export const GOAL_INDICATION_PERIOD_TEXT = "Du {0} au {1}"
export const GOAL_INDICATION_LIVE_LABEL = "Points gagnés en live"
export const GOAL_INDICATION_LIVE_INFO = "Si activé, les points seront automatiquement gagnés par les participants avant la date de fin de l’objectif."
export const GOAL_INDICATION_COACHING_AREA = "Les conseils du coach"
export const GOAL_INDICATION_COACHING_EMPTY_STATE = "Aucun conseil trouvé"
export const GOAL_INDICATION_COACHING_SUBMIT_BUTTON = "Valider"

// Goal - Filter
export const GOAL_FILTER_TITLE = "Filtres"
export const GOAL_FILTER_CATEGORY_LABEL = "Catégorie"
export const GOAL_FILTER_GOAL_LABEL = "Objectif"
export const GOAL_FILTER_ALL_CATEGORY_LABEL = "Toutes les catégories"
export const GOAL_FILTER_CATEGORY_ALL_OPTION = "Toutes"
export const GOAL_FILTER_TEAM_LABEL = "Équipe"
export const GOAL_FILTER_ALL_TEAM_LABEL = "Toutes les équipes"
export const GOAL_FILTER_GLOBAL_CHIP = "Global"
export const GOAL_FILTER_COLLABORATOR_LABEL = "Collaborateur"
export const GOAL_FILTER_COLLABORATOR_ALL_OPTION = "Tous"
export const GOAL_FILTER_PERIOD_LABEL = "Année"
export const GOAL_FILTER_START_LABEL = "Date de début"
export const GOAL_FILTER_END_LABEL = "Date de fin"
export const GOAL_FILTER_CANCEL_BUTTON = "Annuler"
export const GOAL_FILTER_SUBMIT_BUTTON = "Filtrer"

// Goal - Team category list
export const TEAM_GOAL_CATEGORY_LIST_TITLE = "Sélection de la catégorie"
export const TEAM_GOAL_CATEGORY_LIST_ALL_LABEL = "Toutes"

// Goal - Team collaborator goal detail
export const TEAM_COLLABORATOR_GOAL_DETAIL_RANK_TAB = "Classement"
export const TEAM_COLLABORATOR_GOAL_DETAIL_INDICATION_TAB = "Indications"
export const TEAM_COLLABORATOR_GOAL_DETAIL_EDIT_TAB = "Édition"

// Goal - Team goal detail
export const TEAM_GOAL_DETAIL_RANK_TAB = "Classement"
export const TEAM_GOAL_DETAIL_INDICATION_TAB = "Indications"

// Goal - Team goal list
export const TEAM_GOAL_LIST_EMPTY_STATE_TITLE = "Aucun objectif trouvé"
export const TEAM_GOAL_LIST_EMPTY_STATE_MESSAGE = "Si vous avez appliqué des filtres, changez-les pour afficher d'autres objectifs"

// Goal - Team rank list
export const TEAM_GOAL_RANK_LIST_TEAM_COLUMN = "Équipes"
export const TEAM_GOAL_RANK_LIST_POINTS_COLUMN = "PTS"

// Goal duplication dialog
export const GOAL_DUPLICATION_DIALOG_BUTTON = "Configuration des objectifs"
export const GOAL_DUPLICATION_DIALOG_TITLE = "Configuration des objectifs"
export const GOAL_DUPLICATION_DIALOG_MESSAGE = "Appliquer automatiquement à {0} les objectifs du collaborateur suivant :"
export const GOAL_DUPLICATION_DIALOG_TEAM_LABEL = "Équipe"
export const GOAL_DUPLICATION_DIALOG_TEAM_EMPTY_TEXT = "Toutes"
export const GOAL_DUPLICATION_DIALOG_COLLABORATOR_LABEL = "Joueur"
export const GOAL_DUPLICATION_DIALOG_COLLABORATOR_EMPTY_TEXT = "Tous"
export const GOAL_DUPLICATION_DIALOG_SUBMIT_BUTTON = "Valider"
export const GOAL_DUPLICATION_DIALOG_CANCEL_BUTTON = "Annuler"
export const GOAL_DUPLICATION_DIALOG_CONFIRMATION_MESSAGE = "Êtes-vous sûr de vouloir appliquer automatiquement à {0} les objectifs de {1} ?"
export const GOAL_DUPLICATION_DIALOG_CONFIRMATION_YES_BUTTON = "Oui"
export const GOAL_DUPLICATION_DIALOG_CONFIRMATION_NO_BUTTON = "Non"

// Login
export const LOGIN_CODE_LABEL = "Code entreprise"
export const LOGIN_EMAIL_LABEL = "Email"
export const LOGIN_PASSWORD_LABEL = "Mot de passe"
export const LOGIN_ERROR = "Les identifiants sont incorrects"
export const LOGIN_SUBMIT_BUTTON = "Se connecter"
export const LOGIN_SSO_SUBMIT_BUTTON = "Se connecter en SSO"
export const LOGIN_SSO_ERROR_MESSAGE = "Le SSO n'est pas configuré. Veuillez contacter un administrateur de Firetiger"
export const LOGIN_STORE_MESSAGE_1 = "Votre application Fire Tiger est disponible pour Iphone et Android."
export const LOGIN_STORE_MESSAGE_2 = "Téléchargez-la dès maintenant :"

// Main layout
export const MAIN_LAYOUT_SEARCH_PLACEHOLDER = "Rechercher..."

// Notification
export const NOTIFICATION_EMPTY_MESSAGE = "Aucune notification reçue"

// Notification date
export const NOTIFICATION_DATE_DAYS = "{0} j"
export const NOTIFICATION_DATE_HOURS = "{0} h"
export const NOTIFICATION_DATE_MINUTES = "{0} min"

// Point summary
export const POINT_SUMMARY_TITLE = "Informations générales"
export const POINT_SUMMARY_POINTS_LABEL = "Total points gagnés"
export const POINT_SUMMARY_POINTS_VALUE = "{0} PTS"
export const POINT_SUMMARY_USED_POINTS_LABEL = "Total points consommés"
export const POINT_SUMMARY_USED_POINTS_VALUE = "{0} PTS"
export const POINT_SUMMARY_VALIDATED_VALUES_LABEL = "Total valeur dépensée"
export const POINT_SUMMARY_VALIDATED_VALUES_VALUE = "{0} €"
export const POINT_SUMMARY_WAITING_POINTS_LABEL = "Points en attente de validation"
export const POINT_SUMMARY_WAITING_POINTS_VALUE = "{0} PTS"
export const POINT_SUMMARY_USABLE_POINTS_LABEL = "Solde de points"
export const POINT_SUMMARY_USABLE_POINTS_VALUE = "{0} PTS"
export const POINT_SUMMARY_ORDERS_BUTTON = "Commandes à valider"

// Ranking
export const RANKING_LONG_TITLE = "Les classements"
export const RANKING_OWNER_TITLE = "Mes classements"
export const RANKING_SHORT_TITLE = "Classements"

// Ranking - Collaborator category ranking
export const COLLABORATOR_CATEGORY_RANKING_TITLE = "Classement individuel"
export const COLLABORATOR_CATEGORY_RANKING_EMPTY_STATE_TITLE = "Aucun classement disponible"

// Ranking - Collaborator challenge ranking
export const COLLABORATOR_CHALLENGE_RANKING_TITLE = "Classement des challenges individuels"
export const COLLABORATOR_CHALLENGE_RANKING_EMPTY_STATE_TITLE = "Aucun classement disponible"

// Ranking - Collaborator general ranking
export const COLLABORATOR_GENERAL_RANKING_TITLE = "Classement général individuel"
export const COLLABORATOR_GENERAL_RANKING_EMPTY_STATE_TITLE = "Aucun classement disponible"

// Ranking - collaborator ranking
export const COLLABORATOR_RANKING_COLLABORATOR_COLUMN = "Joueurs"
export const COLLABORATOR_RANKING_LEVEL_COLUMN = "LVL"
export const COLLABORATOR_RANKING_POINTS_COLUMN = "PTS"

// Ranking - collaborator ranking list
export const COLLABORATOR_RANKING_LIST_COLLABORATOR_TAB = "Individuel"
export const COLLABORATOR_RANKING_LIST_TEAM_TAB = "Équipe"

// Ranking - Collaborator rank list
export const COLLABORATOR_RANK_LIST_EMPTY_STATE_TITLE = "Aucun classement trouvé"

// Ranking - Collaborator ranking list filter
export const COLLABORATOR_RANKING_LIST_FILTER_TITLE = "Filtres"
export const COLLABORATOR_RANKING_LIST_FILTER_TEAM_LABEL = "Équipe"
export const COLLABORATOR_RANKING_LIST_FILTER_COLLABORATOR_LABEL = "Collaborateur"
export const COLLABORATOR_RANKING_LIST_FILTER_PERIOD_LABEL = "Année"
export const COLLABORATOR_RANKING_LIST_FILTER_SUBMIT_BUTTON = "Filtrer"
export const COLLABORATOR_RANKING_LIST_FILTER_CANCEL_BUTTON = "Annuler"

// Ranking - Rank list
export const RANK_LIST_NAME_COLUMN = "Nom"
export const RANK_LIST_POINTS_COLUMN = "PTS"

// Ranking - Team category ranking
export const TEAM_CATEGORY_RANKING_TITLE = "Classement d'équipe"
export const TEAM_CATEGORY_RANKING_EMPTY_STATE_TITLE = "Aucun classement disponible"

// Ranking - Team challenge ranking
export const TEAM_CHALLENGE_RANKING_TITLE = "Classement des challenges d'équipe"
export const TEAM_CHALLENGE_RANKING_EMPTY_STATE_TITLE = "Aucun classement disponible"

// Ranking - Team general ranking
export const TEAM_GENERAL_RANKING_TITLE = "Classement général d'équipe"
export const TEAM_GENERAL_RANKING_EMPTY_STATE_TITLE = "Aucun classement disponible"

// Ranking - Team ranking
export const TEAM_RANKING_TEAM_COLUMN = "Équipes"
export const TEAM_RANKING_POINTS_COLUMN = "PTS"

// Ranking - Team rank list
export const TEAM_RANK_LIST_EMPTY_STATE_TITLE = "Aucun classement trouvé"

// Reward
export const REWARD_TITLE = "Récompenses"
export const REWARD_IMAGE_INPUT_INFOS = "L’affichage de l’image s’adapte et varie en fonction de la taille de l’écran utilisé par l’utilisateur. Le format d’image recommandé pour un affichage optimal est le suivant : 1024x700."
export const REWARD_POINT_TAG = "{0} PTS"
export const REWARD_ADD_BUTTON = "Ajouter"

// Reward - Creation
export const REWARD_CREATION_SUBTITLE = "Création d'une récompense"
export const REWARD_CREATION_INFOS_AREA = "Informations"
export const REWARD_CREATION_NAME_LABEL = "Nom"
export const REWARD_CREATION_DESCRIPTION_LABEL = "Description"
export const REWARD_CREATION_EMPTY_IMAGE_TEXT = "Aucune image sélectionnée"
export const REWARD_CREATION_CATEGORY_LABEL = "Catégorie"
export const REWARD_CREATION_TYPE_LABEL = "Type"
export const REWARD_CREATION_VALUE_LABEL = "Valeur"
export const REWARD_CREATION_VALUE_SUFFIX_LABEL = "€"
export const REWARD_CREATION_POINTS_LABEL = "Nombre de points nécessaires"
export const REWARD_CREATION_IMAGE_LABEL = "Sélectionner une image..."
export const REWARD_CREATION_DELIVERY_AREA = "Livraison"
export const REWARD_CREATION_DELIVERY_PLACE_LABEL = "Lieu"
export const REWARD_CREATION_DELIVERY_MODE_LABEL = "Mode de livraison"
export const REWARD_CREATION_DELIVERY_TIME_LABEL = "Temps de livraison"
export const REWARD_CREATION_SUBMIT_BUTTON = "Valider"

// Reward - Detail
export const REWARD_DETAIL_DESCRIPTION_AREA = "Description"
export const REWARD_DETAIL_IMAGE_CLOSE_BUTTON = "Fermer"
export const REWARD_DETAIL_VALUE_TEXT = "Valeur : {0} €"
export const REWARD_DETAIL_DELIVERY_TITLE = "Livraison"
export const REWARD_DETAIL_DELIVERY_PLACE_TEXT = "Lieu : {0}"
export const REWARD_DETAIL_DELIVERY_MODE_TEXT = "Mode de livraison : {0}"
export const REWARD_DETAIL_DELIVERY_TIME_TEXT = "Temps de livraison estimé : {0}"
export const REWARD_DETAIL_OPERATION_TITLE = "Comment ça marche ?"
export const REWARD_DETAIL_OPERATION_STEP_1_NUMBER = "1"
export const REWARD_DETAIL_OPERATION_STEP_1_TITLE = "Au clic sur ajouter"
export const REWARD_DETAIL_OPERATION_STEP_1_DESCRIPTION = "Vous devez vérifier votre commande et valider votre panier. Une notification sera ensuite adressée à l’administrateur de votre société pour l’informer de votre demande."
export const REWARD_DETAIL_OPERATION_STEP_2_NUMBER = "2"
export const REWARD_DETAIL_OPERATION_STEP_2_TITLE = "Commande en attente"
export const REWARD_DETAIL_OPERATION_STEP_2_DESCRIPTION = "Votre commande est en attente de traitement/validation. Les points utilisés au moment de votre commande sont retirés de votre de solde de points."
export const REWARD_DETAIL_OPERATION_STEP_3_NUMBER = "3"
export const REWARD_DETAIL_OPERATION_STEP_3_TITLE = "Commande en cours"
export const REWARD_DETAIL_OPERATION_STEP_3_DESCRIPTION = "L’administrateur de votre société prend en charge votre commande. Une fois validée et facturée par le fournisseur, vos points sont définitivement décomptés de votre solde de points."
export const REWARD_DETAIL_OPERATION_STEP_4_NUMBER = "Réception"
export const REWARD_DETAIL_OPERATION_STEP_4_TITLE = "Commande expédiée"
export const REWARD_DETAIL_OPERATION_STEP_4_DESCRIPTION = "Votre récompense est en route ! Vous êtes informé par l’administrateur de votre société. Votre récompense sera déclarée et soumise à cotisations patronales et/ou salariales."

// Reward - Duplication
export const REWARD_DUPLICATION_SUBTITLE = "Duplication d'une récompense"
export const REWARD_DUPLICATION_INFOS_AREA = "Informations"
export const REWARD_DUPLICATION_NAME_LABEL = "Nom"
export const REWARD_DUPLICATION_DESCRIPTION_LABEL = "Description"
export const REWARD_DUPLICATION_EMPTY_IMAGE_TEXT = "Aucune image sélectionnée"
export const REWARD_DUPLICATION_CATEGORY_LABEL = "Catégorie"
export const REWARD_DUPLICATION_TYPE_LABEL = "Type"
export const REWARD_DUPLICATION_VALUE_LABEL = "Valeur"
export const REWARD_DUPLICATION_VALUE_SUFFIX_LABEL = "€"
export const REWARD_DUPLICATION_POINTS_LABEL = "Nombre de points nécessaires"
export const REWARD_DUPLICATION_IMAGE_LABEL = "Sélectionner une image..."
export const REWARD_DUPLICATION_DELIVERY_AREA = "Livraison"
export const REWARD_DUPLICATION_DELIVERY_PLACE_LABEL = "Lieu"
export const REWARD_DUPLICATION_DELIVERY_MODE_LABEL = "Mode de livraison"
export const REWARD_DUPLICATION_DELIVERY_TIME_LABEL = "Temps de livraison"
export const REWARD_DUPLICATION_SUBMIT_BUTTON = "Valider"

// Reward list
export const REWARD_LIST_TITLE = "Récompenses"

// Reward - Management
export const REWARD_MANAGEMENT_SUBTITLE = "Gestion des récompenses"
export const REWARD_MANAGEMENT_COLLABORATOR_TAB = "Joueurs"
export const REWARD_MANAGEMENT_COLLABORATOR_SELECTOR_AREA = "Sélection d'un joueur"
export const REWARD_MANAGEMENT_TEAM_TAB = "Équipes"
export const REWARD_MANAGEMENT_TEAM_SELECTOR_AREA = "Sélection d'une équipe"
export const REWARD_MANAGEMENT_FILTER_TITLE = "Filtres"
export const REWARD_MANAGEMENT_PERIOD_LABEL = "Année"
export const REWARD_MANAGEMENT_SUBMIT_BUTTON = "Filtrer"
export const REWARD_MANAGEMENT_CANCEL_BUTTON = "Annuler"

// Reward order item list
export const REWARD_ORDER_ITEM_LIST_QUANTITY_LABEL = "Quantité"
export const REWARD_ORDER_ITEM_LIST_VALUE_LABEL = "Valeur"
export const REWARD_ORDER_ITEM_LIST_VALUE_VALUE = "{0} €"
export const REWARD_ORDER_ITEM_LIST_POINTS_VALUE = "{0} PTS"
export const REWARD_ORDER_ITEM_LIST_EMPTY_TEXT = "Vide"
export const REWARD_ORDER_ITEM_LIST_CLOSE_BUTTON = "Continuer vos achats"

// Reward - Order list export
export const REWARD_ORDER_LIST_EXPORT_TITLE = "Export des commandes validées"
export const REWARD_ORDER_LIST_EXPORT_CATEGORY_LABEL = "Catégorie"
export const REWARD_ORDER_LIST_EXPORT_CATEGORY_ALL_OPTION = "Toutes"
export const REWARD_ORDER_LIST_EXPORT_TEAM_LABEL = "Équipe"
export const REWARD_ORDER_LIST_EXPORT_TEAM_ALL_OPTION = "Toutes"
export const REWARD_ORDER_LIST_EXPORT_COLLABORATOR_LABEL = "Collaborateur"
export const REWARD_ORDER_LIST_EXPORT_COLLABORATOR_ALL_OPTION = "Tous"
export const REWARD_ORDER_LIST_EXPORT_PERIOD_LABEL = "Année"
export const REWARD_ORDER_LIST_EXPORT_PERIOD_ALL_OPTION = "Toutes"
export const REWARD_ORDER_LIST_EXPORT_VALIDATION_START_LABEL = "Date de début"
export const REWARD_ORDER_LIST_EXPORT_VALIDATION_END_LABEL = "Date de fin"
export const REWARD_ORDER_LIST_EXPORT_SUBMIT_BUTTON = "Exporter"
export const REWARD_ORDER_LIST_EXPORT_CANCEL_BUTTON = "Annuler"

// Reward order summary
export const REWARD_ORDER_SUMMARY_RECIPIENT_POINTS_LABEL = "Solde de points actuel"
export const REWARD_ORDER_SUMMARY_RECIPIENT_POINTS_VALUE = "{0} Pts"
export const REWARD_ORDER_SUMMARY_ORDER_POINTS_LABEL = "Total commande"
export const REWARD_ORDER_SUMMARY_CART_POINTS_LABEL = "Total panier"
export const REWARD_ORDER_SUMMARY_ORDER_POINTS_VALUE = "{0} Pts"
export const REWARD_ORDER_SUMMARY_ORDER_VALUE_LABEL = "Total valeur"
export const REWARD_ORDER_SUMMARY_ORDER_VALUE_VALUE = "{0} €"
export const REWARD_ORDER_SUMMARY_REMAINING_POINTS_LABEL = "Prochain solde de points"
export const REWARD_ORDER_SUMMARY_REMAINING_POINTS_VALUE = "{0} Pts"
export const REWARD_ORDER_SUMMARY_ORDER_BUTTON = "Commander"
export const REWARD_ORDER_SUMMARY_VALIDATE_BUTTON = "Valider"
export const REWARD_ORDER_SUMMARY_REFUSE_BUTTON = "Refuser"

export const REWARD_ORDER_SUMMARY_CONFIRM_ORDER_TITLE = "Êtes-vous sûr de vouloir valider votre commande de {0} Pts pour une valeur de {1} € ?"
export const REWARD_ORDER_SUMMARY_CONFIRM_ORDER_MESSAGE = "Une fois validée, vous ne pourrez plus annuler votre commande, les points seront retirés de votre solde de point et la valeur de votre commande sera déclarée et soumise à cotisation patronales et/ou salariales."
export const REWARD_ORDER_SUMMARY_CONFIRM_ORDER_YES_BUTTON = "Oui"
export const REWARD_ORDER_SUMMARY_CONFIRM_ORDER_NO_BUTTON = "Non"

export const REWARD_ORDER_SUMMARY_CONFIRM_VALIDATE_TITLE = "Êtes-vous sûr de vouloir valider la commande N°{0} d'un total de {1} Pts pour une valeur de {2} € ?"
export const REWARD_ORDER_SUMMARY_CONFIRM_VALIDATE_MESSAGE = "Une fois validée, vous ne pourrez plus annuler la commande, les points seront retirés du solde de points disponible et la valeur de cette commande devra être déclarée et soumise à cotisations patronales et/ou salariales."
export const REWARD_ORDER_SUMMARY_CONFIRM_VALIDATE_YES_BUTTON = "Oui"
export const REWARD_ORDER_SUMMARY_CONFIRM_VALIDATE_NO_BUTTON = "Non"
export const REWARD_ORDER_SUMMARY_CONFIRM_REFUSE_TITLE = "Êtes-vous sûr de vouloir refuser la commande N°{0} d'un total de {1} Pts pour une valeur de {2} € ?"
export const REWARD_ORDER_SUMMARY_CONFIRM_REFUSE_MESSAGE = "Une fois refusée, il ne sera plus possible de la réactiver, les points seront réattribués au solde de point disponible. Il sera de votre responsabilité d'informer l'utilisateur de la raison du refus."
export const REWARD_ORDER_SUMMARY_CONFIRM_REFUSE_YES_BUTTON = "Oui"
export const REWARD_ORDER_SUMMARY_CONFIRM_REFUSE_NO_BUTTON = "Non"

// Reward - settings
export const REWARD_SETTINGS_COLLABORATOR_REWARD_ACTIVATION_OPTION = "Activer les récompenses « joueur »"
export const REWARD_SETTINGS_TEAM_REWARD_ACTIVATION_OPTION = "Activer les récompenses « équipe »"

// Reward - Shopping cart
export const REWARD_SHOPPING_CART_REWARDS_AREA = "Votre panier"
export const REWARD_SHOPPING_CART_POINTS_AREA = "Total panier"
export const REWARD_SHOPPING_CART_POINTS_AREA_YEAR = "Année {0}"

// Reward - Shopping cart adding confirmation
export const REWARD_SHOPPING_CART_ADDING_CONFIRMATION_MESSAGE = 'Vous venez d’ajouter ce produit à votre panier :'
export const REWARD_SHOPPING_CART_ADDING_CONFIRMATION_SHOPPING_CART_BUTTON = 'Aller au panier'
export const REWARD_SHOPPING_CART_ADDING_CONFIRMATION_CLOSE_BUTTON = 'Continuer vos achats'

// Reward - Store
export const REWARD_STORE_EMPTY_STATE_TITLE = 'Aucune récompense trouvée'
export const REWARD_STORE_EMPTY_STATE_MESSAGE = 'Si vous avez appliqué des filtres, changez-les pour afficher d’autres récompenses'

// Reward - Store filter
export const REWARD_STORE_FILTER_TITLE = "Filtres"
export const REWARD_STORE_FILTER_CATEGORY_LABEL = "Catégorie"
export const REWARD_STORE_FILTER_CATEGORY_ALL_OPTION = "Toutes"
export const REWARD_STORE_FILTER_TEAM_LABEL = "Équipe"
export const REWARD_STORE_FILTER_COLLABORATOR_LABEL = "Collaborateur"
export const REWARD_STORE_FILTER_COLLABORATOR_ALL_OPTION = "Équipe"
export const REWARD_STORE_FILTER_PERIOD_LABEL = "Année"
export const REWARD_STORE_FILTER_SUBMIT_BUTTON = "Filtrer"
export const REWARD_STORE_FILTER_CANCEL_BUTTON = "Annuler"

// Reward - Update
export const REWARD_UPDATE_SUBTITLE = "Modification d'une récompense"
export const REWARD_UPDATE_INFOS_AREA = "Informations"
export const REWARD_UPDATE_NAME_LABEL = "Nom"
export const REWARD_UPDATE_DESCRIPTION_LABEL = "Description"
export const REWARD_UPDATE_EMPTY_IMAGE_TEXT = "Aucune image sélectionnée"
export const REWARD_UPDATE_CATEGORY_LABEL = "Catégorie"
export const REWARD_UPDATE_TYPE_LABEL = "Type"
export const REWARD_UPDATE_VALUE_LABEL = "Valeur"
export const REWARD_UPDATE_VALUE_SUFFIX_LABEL = "€"
export const REWARD_UPDATE_POINTS_LABEL = "Nombre de points nécessaires"
export const REWARD_UPDATE_IMAGE_LABEL = "Sélectionner une image..."
export const REWARD_UPDATE_DELIVERY_AREA = "Livraison"
export const REWARD_UPDATE_DELIVERY_PLACE_LABEL = "Lieu"
export const REWARD_UPDATE_DELIVERY_MODE_LABEL = "Mode de livraison"
export const REWARD_UPDATE_DELIVERY_TIME_LABEL = "Temps de livraison"
export const REWARD_UPDATE_SUBMIT_BUTTON = "Valider"

// Rich text
export const RICH_TEXT_PLACEHOLDER = "Entrez votre texte..."

// Statistiques
export const STATS_TITLE = "Statistiques"

// Statistiques - Filter
export const STATS_FILTER_TITLE = "Filtres"
export const STATS_FILTER_CATEGORY_LABEL = "Catégorie"
export const STATS_FILTER_GOAL_LABEL = "Objectif"
export const STATS_FILTER_TEAM_LABEL = "Équipe"
export const STATS_FILTER_COLLABORATOR_LABEL = "Collaborateur"
export const STATS_FILTER_COLLABORATOR_EMPTY_OPTION = "Tous"
export const STATS_FILTER_PERIOD_LABEL = "Année"

// Statistiques - Category filter
export const STATS_CATEGORY_FILTER_TITLE = "Filtres"
export const STATS_CATEGORY_FILTER_TEAM_LABEL = "Équipe"
export const STATS_CATEGORY_FILTER_COLLABORATOR_LABEL = "Collaborateur"
export const STATS_CATEGORY_FILTER_COLLABORATOR_ALL_OPTION = "Tous"
export const STATS_CATEGORY_FILTER_PERIOD_LABEL = "Année"
export const STATS_CATEGORY_FILTER_CANCEL_BUTTON = "Annuler"
export const STATS_CATEGORY_FILTER_SUBMIT_BUTTON = "Filtrer"

// Statistiques - Goals
export const STATS_GOALS_EMPTY_STATE_TITLE = "Aucune statistique trouvée"
export const STATS_GOALS_EMPTY_STATE_MESSAGE = "Modifiez les filtres pour afficher d’autres statistiques"

// Statistiques - Team goal category stats
export const TEAM_GOAL_CATEGORY_STATS_TITLE = "Sélection de la catégorie"

// Team
export const TEAM_TITLE = "Équipes"
export const TEAM_TITLE_SINGULAR = "Équipe"
export const TEAM_COLLABORATORS_TEXT = "{0} Joueurs"
export const TEAM_GROUP_TEAMS_TEXT = "{0} équipes"
export const TEAM_GROUP_COLLABORATORS_TEXT = "{0} Gestionnaires"
export const TEAM_MANAGER_TEXT = "De {0} {1}"
export const TEAM_NO_MANAGER_TEXT = "Aucun manager"

// Team - Detail
export const TEAM_DETAIL_COLLABORATORS_TEXT = "{0} joueurs"
export const TEAM_DETAIL_MANAGER_TEXT = "De {0} {1}"
export const TEAM_DETAIL_NO_MANAGER_TEXT = "Aucun manager"
export const TEAM_DETAIL_POINTS_TEXT = "{0} PTS"
export const TEAM_DETAIL_VICTORIES_TEXT = "{0} victoires"
export const TEAM_DETAIL_EMPTY_STATE_TITLE = "Aucune équipe trouvée"

// Team - List
export const TEAM_LIST_EMPTY_STATE_TITLE = "Aucune équipe trouvée"
export const TEAM_LIST_EMPTY_STATE_MESSAGE = "Les équipes n'ont pas encore été créées"

// Team reward list
export const TEAM_REWARD_LIST_COLLABORATOR_TAB = "Joueurs"
export const TEAM_REWARD_LIST_TEAM_TAB = "Équipe"

// Team reward store
export const STORE_TEAM_COLLABORATOR_DEPARTMENT_COLLABORATOR_SELECTOR_AREA = "Sélection d'un joueur"

// Team reward order summary
export const TEAM_REWARD_ORDER_SUMMARY_TITLE = "Récapitulatif d'une commande"
export const TEAM_REWARD_ORDER_SUMMARY_REWARDS_AREA = "Commande n°{0} de {1}"
export const TEAM_REWARD_ORDER_SUMMARY_POINTS_AREA = "Total commande"
export const TEAM_REWARD_ORDER_SUMMARY_POINTS_AREA_YEAR = "Année {0}"

// Team reward order tracking
export const TEAM_REWARD_ORDER_TRACKING_ID_COLUMN = "Ref"
export const TEAM_REWARD_ORDER_TRACKING_TEAM_COLUMN = "Équipe"
export const TEAM_REWARD_ORDER_TRACKING_WAITING_POINTS_COLUMN = "Pts à valider"
export const TEAM_REWARD_ORDER_TRACKING_VALIDATED_POINTS_COLUMN = "Pts validés"
export const TEAM_REWARD_ORDER_TRACKING_VALUE_COLUMN = "Montant total"
export const TEAM_REWARD_ORDER_TRACKING_ORDER_DATE_COLUMN = "Date de commande"
export const TEAM_REWARD_ORDER_TRACKING_VALIDATION_DATE_COLUMN = "Date de validation"

// Team reward order validation
export const TEAM_REWARD_ORDER_VALIDATION_TITLE = "Validation d'une commande"
export const TEAM_REWARD_ORDER_VALIDATION_REWARDS_AREA = "Commande n°{0} de {1}"
export const TEAM_REWARD_ORDER_VALIDATION_POINTS_AREA = "Total commande"
export const TEAM_REWARD_ORDER_VALIDATION_POINTS_AREA_YEAR = "Année {0}"

// Team selector
export const TEAM_SELECTOR_EMPTY_STATE_TITLE = "Aucune équipe trouvée"
export const TEAM_SELECTOR_EMPTY_STATE_MESSAGE = "Les équipes n'ont pas encore été créées"

export const TIME_FILTER_CURRENT_TAB = "En cours"
export const TIME_FILTER_PAST_TAB = "Passés"

// Tracking sub header
export const TRACKING_SUB_HEADER_VALIDATED_TITLE = "Suivi des commandes"
export const TRACKING_SUB_HEADER_VALIDATED_TAB = "À traiter"
export const TRACKING_SUB_HEADER_WAITING_TAB = "Validées"

// User profile
export const USER_PROFILE_NO_TEAM = "Aucune équipe"

// Error page
export const ERROR_PAGE_TITLE = "Une erreur est survenue"
export const ERROR_PAGE_MESSAGE = "Vous pouvez mettre à jour l'application pour résoudre le problème"
