import React, { useState } from 'react'
import {connect} from 'react-redux'
import { Grid } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleRight, faBalanceScale, faCalendarAlt, faFolderOpen, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import { AdviceList, LiveStatus, ReadonlyAdviceList } from './components'
import {AnimationController, BlueText, Card, DefaultText, DefaultTitle, InfoText, Linkify, Table, TableBody, TableCell, TableChip, TableRow, Tooltip, RichText, Collaborator} from '../../../../components'
import * as Resources from '../../../../Resources'
import {useIntl} from 'react-intl'
import {getDifferenceWithToday} from '../../../../helpers/DateHelper'

const GoalIndication = ({ goal, type, customRepartitions, ...props }) => {
    const intl = useIntl()
    const {account} = props.accountDetail;
    const difference = getDifferenceWithToday(goal.end);
    const canEdit = (account.role.code == 'M' && account.team.id == goal.teamId || account.role.code == 'A') && difference <= 0;
    const hasLevels = goal.levels && goal.levels.length > 0


    const customRepartitionsTooltip = (
      <Grid container spacing= {1} direction="column">
        { customRepartitions && customRepartitions.map((repartition) => (
            <Grid item>
              <Collaborator collaborator={repartition.collaborator} />
            </Grid>
        )) }
      </Grid>
    )
    return (
        <div>
            <Grid container spacing={2}>
                { (hasLevels || (customRepartitions && customRepartitions.length > 0)) && <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <DefaultTitle>{intl.formatMessage({id: "admin.goal.indication.level_area"})}</DefaultTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <Card marginDisabled>
                                <Grid container direction='column' spacing={2}>
                                  <Grid item>
                                    <Grid container justify='space-between'>
                                    <Grid item>
                                        <Table backgroundDisabled>
                                            <TableBody>
                                                { goal.levels.map((level, index) => {
                                                    return (
                                                        <TableRow key={level.id}>
                                                            <TableCell>
                                                                <TableChip label={index+1} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <DefaultText noWrap>{"{0}%".format(Math.round(level.percentage*100))}</DefaultText>
                                                            </TableCell>
                                                            <TableCell>
                                                                <FontAwesomeIcon icon={faAngleRight} />
                                                            </TableCell>
                                                            <TableCell align='right'>
                                                                <DefaultText noWrap>{"{0} PTS".format(level.points)}</DefaultText>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }) }
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    <Grid item>
                                        <AnimationController />
                                    </Grid>
                                </Grid>
                                  </Grid>
                                  { customRepartitions && customRepartitions.length > 0 && (
                                    <Grid item style={{margin: "10px"}}>
                                      <Grid container direction='column' spacing={1}>
                                        <Grid item>
                                          <DefaultTitle>
                                            ⚠️ Paliers personnalisés&nbsp;
                                            <Tooltip title={customRepartitionsTooltip} placement={'right'}>
                                                <BlueText style={{ width: 'fit-content' }} component={'span'}>
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </BlueText>
                                            </Tooltip>
                                          </DefaultTitle>
                                        </Grid>
                                        <Grid item>
                                          <Grid container container direction='column' spacing={2}>

                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  ) }
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>}
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <DefaultTitle>{intl.formatMessage({id: "admin.goal.indication.description_area"})}</DefaultTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <Grid container spacing={2}>
                                    <Grid item zeroMinWidth>
                                        <DefaultText noWrap>
                                            <FontAwesomeIcon icon={faFolderOpen} /> {goal.definition.category.name}
                                        </DefaultText>
                                    </Grid>
                                    <Grid item xs>
                                        <DefaultText align='right'>
                                            <FontAwesomeIcon icon={faBalanceScale} /> {goal.definition.kpi.unit.symbol ? intl.formatMessage({id: "admin.goal.indication.unit_with_symbol_text"}).format(goal.definition.kpi.unit.name, goal.definition.kpi.unit.symbol) : intl.formatMessage({id: "admin.goal.indication.unit_without_symbol_text"}).format(goal.definition.kpi.unit.name)}
                                        </DefaultText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DefaultText>
                                            <FontAwesomeIcon icon={faCalendarAlt} /> {intl.formatMessage({id: "admin.goal.indication.period_text"}).format(goal.start.toDate2().toLocaleDateString(), goal.end.toDate2().toLocaleDateString())}
                                        </DefaultText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item>
                                                <LiveStatus live={goal.definition.live} />
                                            </Grid>
                                            <Grid item>
                                                <DefaultText>
                                                    {intl.formatMessage({id: "admin.goal.indication.live_label"})}&nbsp;
                                                    <Tooltip title={intl.formatMessage({id: "admin.goal.indication.live_info"})} placement={'right'}>
                                                        <BlueText style={{ width: 'fit-content' }} component={'span'}>
                                                            <FontAwesomeIcon icon={faInfoCircle} />
                                                        </BlueText>
                                                    </Tooltip>
                                                </DefaultText>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Linkify>
                                              <RichText initial={JSON.parse(goal.definition.indication)} readOnly={ true } onChange={() => {}} />
                                        </Linkify>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {!canEdit && <ReadonlyAdviceList advices={goal.advices}/>}
                    {canEdit && <AdviceList advices={goal.advices} goal={goal} type={type} />}
                </Grid>
            </Grid>
        </div>
    )
};

const mapStateToProps = ({accountDetail}) => ({
    accountDetail,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(GoalIndication)
