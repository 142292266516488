import React from 'react'
import { connect } from 'react-redux'
import { CardMedia, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { AccentText, DefaultText, DefaultTitle, ProgressBar, InfoText, TimerTag } from '../../../../components'
import * as Resources from '../../../../Resources'
import {useIntl} from 'react-intl'
import { Period } from './components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFireAlt, faFlagCheckered, faUser, faUsers } from '@fortawesome/free-solid-svg-icons'

const styles = {
    icon: {
        width: 41,
        height: 41,
        marginRight: 8
    },
    name: {
        marginRight: 16
    },
    progress: {
        marginTop: 16
    },
    progressBar: {
        marginTop: 8
    },
    infos: {
        marginTop: 16
    },
    info: {
        marginLeft: 16
    },
    subInfo: {
        marginLeft: 4
    }
}

const Goal = ({ goal, ...props }) => {
    const intl = useIntl()
    const { classes } = props
    const progression = Math.round((goal.counter / goal.target) * 100)
    const typeStyle = goal.type === 'T' ? {color: goal.color} : null
    const hasRank = goal.rank && goal.allow_ranking

    let maxPointsKey
    maxPointsKey = 'maxPoints'
    // if(goal.pointRepartitionMode === 'G') {
    // } else {
    //   if(goal.type === 'T' && goal.pointRepartitionMode === 'T') {
    //     maxPointsKey = 'maxTeamPoints'
    //   } else {
    //     // if(goal.pointRepartitionMode === 'T') {
    //     //   maxPointsKey = 'maxTeamCollaboratorPoints'
    //     // } else if(goal.pointRepartitionMode === 'I') {
    //       maxPointsKey = 'maxCollaboratorPoints'
    //     // }
    //   }
    // }
    const maxPoints = goal[maxPointsKey]
    const hasPoints = goal[maxPointsKey] > 0


    return (
        <div>
            <Grid container>
                <Grid item>
                    <CardMedia image={goal.icon} className={classes.icon} />
                </Grid>
                <Grid item xs zeroMinWidth>
                    <DefaultTitle className={classes.name} noWrap>{goal.name}</DefaultTitle>
                    <Period goal={goal} />
                </Grid>
                <Grid item>
                    <TimerTag date={goal.end} />
                </Grid>
            </Grid>
            <Grid container className={classes.progress}>
                <Grid item>
                    <DefaultText>
                        {intl.formatMessage({id: "admin.goal.thumbnail.counter_text"}).format(goal.counter)} <InfoText component='span'>{intl.formatMessage({id: "admin.goal.thumbnail.target_text"}).format(goal.target)}</InfoText>
                    </DefaultText>
                </Grid>
                <Grid item xs>
                    <AccentText align='right'>{"{0}%".format(progression)}</AccentText>
                </Grid>
            </Grid>
            <Grid container className={classes.progressBar}>
                <Grid item xs>
                    <ProgressBar value={progression} />
                </Grid>
            </Grid>
            <Grid container className={classes.infos}>
                {hasRank && <Grid item>
                    <DefaultText>
                        <FontAwesomeIcon icon={faFlagCheckered} /> {goal.rank == 1 ? intl.formatMessage({id: "admin.goal.thumbnail.first_rank_text"}).format(goal.rank) : intl.formatMessage({id: "admin.goal.thumbnail.other_rank_text"}).format(goal.rank)} <InfoText component='span'>{intl.formatMessage({id: "admin.goal.thumbnail.max_rank_text"}).format(goal.participants)}</InfoText>
                    </DefaultText>
                </Grid>}
                {!hasRank && <Grid item>
                    <DefaultText>
                        <FontAwesomeIcon icon={faFlagCheckered} /> {goal.type == 'C' ? intl.formatMessage({id: "admin.goal.thumbnail.player_text"}).format(goal.participants) : intl.formatMessage({id: "admin.goal.thumbnail.team_text"}).format(goal.participants)}
                    </DefaultText>
                </Grid>}
                { hasPoints && <Grid item className={classes.info}>
                    <DefaultText>
                        <FontAwesomeIcon icon={faFireAlt} /> {intl.formatMessage({id: "admin.goal.thumbnail.points_text"}).format(goal.points)} <InfoText component='span'>{intl.formatMessage({id: "admin.goal.thumbnail.max_points_text"}).format(maxPoints)}</InfoText>
                    </DefaultText>
                </Grid>}
                <Grid item className={classes.info} xs zeroMinWidth>
                    <DefaultText align='right' noWrap style={typeStyle}>
                        <FontAwesomeIcon icon={goal.type == 'C' ? faUser : faUsers} /> { goal.type == 'C' ? intl.formatMessage({id: "admin.goal.thumbnail.collaborator_tag"}) : intl.formatMessage({id: "admin.goal.thumbnail.team_tag"}) }
                    </DefaultText>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = ({accountDetail}) => ({
    accountDetail
})

export default connect(mapStateToProps)(withStyles(styles)(Goal))
