import React, {useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {Grid, IconButton} from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Formsy from 'formsy-react'

import { AppBarSubTitle, MainLayoutComponent, Team, Card, DefaultText, Dialog, DialogTitle, DialogActions, ProgressButton, Button, Organization, Loader } from '../../../../components'
import { TeamCreationForm, TeamUpdateForm } from './components'
import * as teamCreationActions from '../../../../services/Teams/TeamCreation/actions'
import * as teamUpdateActions from '../../../../services/Teams/TeamUpdate/actions'
import * as teamRemovingActions from '../../../../services/Teams/TeamRemoving/actions'
import * as teamGroupTreeAction from '../../../../services/TeamGroups/TeamGroupTree/actions'
import * as teamGroupCreationActions from '../../../../services/TeamGroups/TeamGroupCreation/actions'
import * as teamGroupUpdateActions from '../../../../services/TeamGroups/TeamGroupUpdate/actions'
import * as teamGroupRemovingActions from '../../../../services/TeamGroups/TeamGroupRemoving/actions'
import * as collaboratorListActions from '../../../../services/Collaborators/CollaboratorList/actions'
import * as configListActions from '../../../../services/Configs/ConfigList/actions'
import * as Resources from '../../../../Resources'
import { useIntl, injectIntl } from 'react-intl'
import _ from 'lodash'

const styles = {
  teamDialog: {
    width: '90%',
    '& .MuiFormControlLabel-root': {
      marginLeft: 0
    }
  }

}



class AdminOrganization extends MainLayoutComponent {
    constructor(props) {
      super(props);
      this.state = {
        newTeamOpen: false,
      }
      this.wrapperElement = React.createRef()
    }
    componentDidMount() {
        const {intl} = this.props
        this.props.handleTitle(intl.formatMessage({id: 'admin.title'}))
        this.props.handleSubHeader(<AppBarSubTitle title={intl.formatMessage({id: 'admin.organization.title'})} />)
        this.props.activateReturn()

        this.props.teamGroupTreeAction.getTeamGroupTree()
        this.props.collaboratorListActions.getFreeCollaboratorList();
        this.props.configListActions.getPermanentConfigList()
    }

    addTeamToChildren = (baseTeam) => {
      this.setState({
        ...this.state,
        newTeamOpen: true,
        baseTeam
      })
    }

    editTeam = (team, type) => {
      this.setState({
        ...this.state,
        editTeamOpen: true,
        team: Object.assign({}, team, {type})
      })
    }

    handleSubmitTeam = (m) => {
      const { collaborators: collaboratorList } = this.props.collaboratorList;
      const { superManagers } = this.props.superManagerList;
      const model = this.refs.form.getModel();
      const collaborators = model.type === 'team' ? collaboratorList : superManagers
      const team = { name: model.name, color: model.color, manager: model.manager, parent: _.get(this.state, 'baseTeam.id') };
      const newCollaborators = collaborators.filter(c => model.collaborators.includes(c.id));

      if(model.type === "teamGroup") {
        this.props.teamGroupCreationActions.createTeamGroup(team, newCollaborators)
      } else {
        this.props.teamCreationActions.createTeam(team, newCollaborators)

      }
    }

    handleUpdateTeam = (m) => {
      const { collaborators: collaboratorList } = this.props.collaboratorList;
      const { superManagers } = this.props.superManagerList;
      const model = this.refs.updateForm.getModel();
      const collaborators = model.type === 'team' ? collaboratorList : superManagers

      const team = { id: this.state.team.id, name: model.name, color: model.color, manager: model.manager, parent: model.parent };


      const newCollaborators = model.collaborators ? _.uniq(_.compact(
        model.collaborators.filter(currentCollab => currentCollab && this.state.team.collaborators.map(c => parseInt(c.id)).indexOf(parseInt(currentCollab)) < 0).map(c => parseInt(c))
      )) : []

      const oldCollaborators = _.compact(this.state.team.collaborators.filter(c => !model.collaborators.includes(c.id))).map(c => parseInt(c.id))

      if(model.type === "teamGroup") {
        this.props.teamGroupUpdateActions.updateTeamGroup(team, newCollaborators, oldCollaborators)
      } else {
        this.props.teamUpdateActions.updateTeam(team, newCollaborators, oldCollaborators)
      }
    }

    handleRemoveTeam = (team) => {
      if(team.type === 'team' && team.collaborators.length === 0) {
        this.props.teamRemovingActions.removeTeam(team.id)
      }
      if(team.type === 'teamGroup' && team.collaborators.length === 0 && team.teams.length === 0 && team.teamGroups.length === 0) {
        this.props.teamGroupRemovingActions.removeTeamGroup(team.id)
      }
    }

    onNewTeamClose = (callback) => {
      const defaultCallback = () => {
        if(typeof(callback) === 'function') {
          callback()
        }
      }
      this.setState({
          ...this.state,
          newTeamOpen: false,
          baseTeam: null
      }, defaultCallback)
    }

    onEditTeamClose = (callback) => {
      const defaultCallback = () => {
        if(typeof(callback) === 'function') {
          callback()
        }
      }
      this.setState({
          ...this.state,
          editTeamOpen: false,
          team: null
      }, defaultCallback)
    }

    renderLoader = () => {
        return (
            <Loader centered />
        )
    };

    renderData() {
      const {images} = this.props.systemImageList
      const {teamGroup} = this.props.teamGroupTree
      const wrapperWidth = _.get(this.wrapperElement, 'current.offsetWidth', 0)
      const { configs } = this.props.configList;
      const logo = images && _.get(images.find(x => x.code === 'LOGO'), 'src')
      return (
        <Organization
          organizationRoot={ teamGroup }
          onClick={(team, type) => this.editTeam(team, type)}
          onAddBelow={ this.addTeamToChildren }
          companyLogo={logo}
        />

      )
    }

    render() {
      const {intl} = this.props
      const {images, imagesLoading} = this.props.systemImageList
      const {teamGroup, loading: teamGroupsLoading} = this.props.teamGroupTree
      const { collaborators, loading: collaboratorListLoading } = this.props.collaboratorList;
      const { configs, loading: configLoading } = this.props.configList

      const loading = teamGroupsLoading || collaboratorListLoading || configLoading || imagesLoading
      const { loading: createTeamLoading, success: createTeamSuccess } = this.props.teamCreation;
      const { loading: updateTeamLoading, success: updateTeamSuccess } = this.props.teamUpdate;
      const { loading: removeTeamLoading, success: removeTeamSuccess } = this.props.teamRemoving;
      const { loading: createTeamGroupLoading, success: createTeamGroupSuccess } = this.props.teamGroupCreation;
      const { loading: updateTeamGroupLoading, success: updateTeamGroupSuccess } = this.props.teamGroupUpdate;
      const { loading: removeTeamGroupLoading, success: removeTeamGroupSuccess } = this.props.teamGroupRemoving;

      if((createTeamSuccess || createTeamGroupSuccess) && this.state.newTeamOpen) {
        this.props.teamGroupCreationActions.clearTeamGroupCreation()
        this.props.teamCreationActions.clearTeamCreation()
        this.onNewTeamClose(this.props.teamGroupTreeAction.getTeamGroupTree)
      }

      if((updateTeamSuccess || updateTeamGroupSuccess) && this.state.editTeamOpen) {
        this.props.teamGroupUpdateActions.clearTeamGroupUpdate()
        this.props.teamUpdateActions.clearTeamUpdate()
        this.onEditTeamClose(this.props.teamGroupTreeAction.getTeamGroupTree)
      }

      if((removeTeamSuccess || removeTeamGroupSuccess) && this.state.editTeamOpen) {
        this.props.teamGroupRemovingActions.clearTeamGroupRemoving()
        this.props.teamRemovingActions.clearTeamRemoving()
        this.onEditTeamClose(this.props.teamGroupTreeAction.getTeamGroupTree)
      }

      const currentTeam = _.get(this.state, 'team')
      const displayDeleteButton = currentTeam && (
        (currentTeam.type === 'team' && currentTeam.collaborators.length === 0) ||
        (currentTeam.type === 'teamGroup' && currentTeam.collaborators.length === 0 && currentTeam.teams.length === 0 && currentTeam.teamGroups.length === 0)
      )

      return(
        <div>
          { loading && this.renderLoader() }
          {!loading && teamGroup && configs && images && this.renderData()}
          <Dialog
              open={this.state.newTeamOpen}
              onClose={this.onNewTeamClose}
              classes={{ paper: this.props.classes.teamDialog }}
              maxWidth="md"
          >
              <DialogTitle>{intl.formatMessage({id: 'admin.organization.create_team_title'}).format(_.get(this, 'state.baseTeam.name'))}</DialogTitle>
              <Formsy ref='form' onValidSubmit={this.handleSubmitTeam.bind(this)} >
                <TeamCreationForm form={this.refs.form}/>
                <DialogActions>
                    <ProgressButton type='submit' text={intl.formatMessage({id: "common.submit"})} loading={createTeamLoading || createTeamGroupLoading} centered />
                    <Button onClick={this.onNewTeamClose} color="secondary">{intl.formatMessage({id: 'common.cancel'})}</Button>
                </DialogActions>
              </Formsy>
          </Dialog>
          <Dialog
              open={this.state.editTeamOpen}
              onClose={this.onEditTeamClose}
              classes={{ paper: this.props.classes.teamDialog }}
              maxWidth="md"
          >
              <DialogTitle>
                {_.get(this.state.team, 'parent') ? (
                  <React.Fragment>
                    {intl.formatMessage({id: _.get(this.state.team, 'type') === 'teamGroup' ? 'admin.organization.update_team_group' : 'admin.organization.update_team'})}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {intl.formatMessage({id: 'admin.organization.update_company'})}
                  </React.Fragment>
                )}
              </DialogTitle>
              <Formsy ref='updateForm' onValidSubmit={this.handleUpdateTeam.bind(this)} >
                <TeamUpdateForm team={this.state.team} form={this.refs.updateForm}/>

                  <Grid container justify="space-between" style={{width: "100%"}}>
                    <Grid item>
                      {
                        displayDeleteButton && (
                          <ProgressButton type={'button'} color='secondary' text={intl.formatMessage({id: 'common.delete'})} loading={removeTeamLoading} centered onClick={() => this.handleRemoveTeam(this.state.team)} />
                        )
                      }
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <ProgressButton type='submit' text={intl.formatMessage({id: "common.submit"})} loading={updateTeamLoading || updateTeamGroupLoading} centered />
                        </Grid>
                        <Grid item>
                          <Button onClick={this.onEditTeamClose} color="secondary">{intl.formatMessage({id: "common.cancel"})}</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

              </Formsy>
          </Dialog>
        </div>
      )
    }
}


const mapStateToProps = ({teamGroupTree, collaboratorList, teamCreation, teamUpdate, teamRemoving, teamGroupCreation, teamGroupUpdate, teamGroupRemoving, superManagerList, configList, systemImageList}) => ({
    teamGroupTree,
    collaboratorList,
    superManagerList,
    teamCreation,
    teamUpdate,
    teamRemoving,
    teamGroupCreation,
    teamGroupUpdate,
    teamGroupRemoving,
    configList,
    systemImageList
})

const mapDispatchToProps = (dispatch) => ({
    teamGroupTreeAction: bindActionCreators(teamGroupTreeAction, dispatch),
    collaboratorListActions: bindActionCreators(collaboratorListActions, dispatch),
    teamCreationActions: bindActionCreators(teamCreationActions, dispatch),
    teamUpdateActions: bindActionCreators(teamUpdateActions, dispatch),
    teamRemovingActions: bindActionCreators(teamRemovingActions, dispatch),
    teamGroupCreationActions: bindActionCreators(teamGroupCreationActions, dispatch),
    teamGroupUpdateActions: bindActionCreators(teamGroupUpdateActions, dispatch),
    teamGroupRemovingActions: bindActionCreators(teamGroupRemovingActions, dispatch),
    configListActions: bindActionCreators(configListActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(AdminOrganization)))
