import React from 'react'
import {InfoText} from '../../../../../../components'
import * as Resources from '../../../../../../Resources'
import {useIntl} from 'react-intl'
import '../../../../../../helpers/DateHelper'
import '../../../../../../helpers/StringHelper'

const Period = ({ goal }) => {
    const intl = useIntl()
    const startDate = goal.start.toDate()
    const endDate = goal.end.toDate()

    return (
        <InfoText>
            {
                goal.periodicity == 'Y' ? intl.formatMessage({id: "admin.goal.thumbnail.year_period"}).format(startDate.getFullYear())
                : goal.periodicity == 'S' ? intl.formatMessage({id: "admin.goal.thumbnail.semester_period"}).format(startDate.getSemesterNumber())
                : goal.periodicity == 'Q' ? intl.formatMessage({id: "admin.goal.thumbnail.quarter_period"}).format(startDate.getQuarterNumber(), startDate.getFullYear())
                : goal.periodicity == 'M' ? Intl.DateTimeFormat('fr-FR', { month: 'long', year: 'numeric' }).format(startDate)
                : goal.periodicity == 'W' ? intl.formatMessage({id: "admin.goal.thumbnail.week_period"}).format(startDate.getWeekNumber(), startDate.getFullYear())
                : intl.formatMessage({id: "admin.goal.thumbnail.other_period"}).format(startDate.toDateString(), endDate.toDateString())
            }
        </InfoText>
    )
}

export default Period
