import React from 'react'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFlagCheckered} from '@fortawesome/free-solid-svg-icons'
import {faFireAlt} from '@fortawesome/free-solid-svg-icons'
import {ChallengeImage, ChallengeType} from '..'
import {DefaultText, InfoText, TimerTag} from '../../../../components'
import {useIntl} from 'react-intl'

const styles = {
    imageContainer: {
        position: 'relative'
    },
    timerContainer: {
        position: 'absolute',
        right: 0,
        top: 16
    }
}

const ChallengeSimple = ({challenge, ...props}) => {
    const intl = useIntl()
    const {classes} = props

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className={classes.imageContainer}>
                        <div className={classes.timerContainer}>
                            <TimerTag date={challenge.end} />
                        </div>
                        <ChallengeImage image={challenge.custom_image || challenge.image} />
                    </div>
                </Grid>
                <Grid item>
                    <DefaultText>
                        {challenge.rank && (<div><FontAwesomeIcon icon={faFlagCheckered} /> {challenge.rank == 1 ? intl.formatMessage({id: "challenge.first_rank"}).format(challenge.rank) : intl.formatMessage({id: "challenge.other_rank"}).format(challenge.rank)} <InfoText component='span'>/ {challenge.participants}</InfoText></div>)}
                        {!challenge.rank && (<div><FontAwesomeIcon icon={faFlagCheckered} /> {challenge.typeCode !== 'CT' ? intl.formatMessage({id: "challenge.collaborators"}).format(challenge.participants) : intl.formatMessage({id: "challenge.teams"}).format(challenge.participants)}</div>)}
                    </DefaultText>
                </Grid>
                <Grid item>
                    <DefaultText>
                        <FontAwesomeIcon icon={faFireAlt} /> {intl.formatMessage({id: "challenge.points"}).format(challenge.goalPoints)} <InfoText component='span'></InfoText
                    ></DefaultText>
                </Grid>
                <Grid item xs>
                    <DefaultText align='right'>
                        <ChallengeType type={challenge.typeCode} />
                    </DefaultText>
                </Grid>
            </Grid>
        </div>
    )
}

export default withStyles(styles)(ChallengeSimple)
