import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {injectIntl} from 'react-intl'
import { AppBarSubTitle, DataTable, Loader, MainLayoutComponent } from '../../../../components'
import * as importLogListActions from '../../../../services/ImportLogs/ImportLogList/actions'
import '../../../../helpers/StringHelper'

class AdminImportLogList extends MainLayoutComponent {
    componentDidMount() {
        const {intl} = this.props
        this.props.handleTitle(intl.formatMessage({id: 'admin.title'}));
        this.props.handleSubHeader(<AppBarSubTitle title="Journal d'import" />);
        this.props.activateReturn();
        this.props.importLogListActions.getImportLogList()
    }

    renderLoader() {
        return <Loader centered />
    }

    renderData() {
        const {intl} = this.props
        var { logs } = this.props.importLogList;
        const columns = [
            { name: 'id', label: intl.formatMessage({id: 'admin.import_log.columns.id'}) },
            { name: 'file', label: intl.formatMessage({id: 'admin.import_log.columns.file'}) },
            { name: 'date', label: intl.formatMessage({id: 'admin.import_log.columns.date'}), options: {
                filter: false,
                customBodyRender: value => {
                    return value.toDate().toLocaleString()
                }
            } },
            { name: 'state.name', label: intl.formatMessage({id: 'admin.import_log.columns.state'}) }
        ];
        const options = {
            selectableRows: 'none'
        };

        return <DataTable data={logs} columns={columns} options={options} />
    }

    render() {
        const { logs, loading } = this.props.importLogList;

        return (
            <div>
                { loading && this.renderLoader() }
                { !loading && logs && this.renderData() }
            </div>
        )
    }
}

const mapStateToProps = ({ importLogList }) => ({
    importLogList
});

const mapDispatchToProps = (dispatch) => ({
    importLogListActions: bindActionCreators(importLogListActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AdminImportLogList))
