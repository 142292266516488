import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Goal } from '../../../../components'
import { Loader, RoundedTab, RoundedTabs } from '../../../../../../components'
import * as Resources from '../../../../../../Resources'
import {useIntl} from 'react-intl'

const styles = {
    root: {
        padding: 16
    }
}

const SubHeader = ({ activateRank, onChange, ...props }) => {
    const intl = useIntl()
    const { classes } = props
    const { account } = props.accountDetail;
    const { goal, loading: teamGoalDetailLoading } = props.teamGoalDetail
    const { loading: teamGoalRankListLoading } = props.teamGoalRankList
    const loading = teamGoalDetailLoading || teamGoalRankListLoading
    const [value, setValue] = React.useState(0)

    const handleChange = (e, value) => {
        setValue(value)
        if (onChange) onChange(value)
    }

    const renderLoader = () => {
        return <Loader centered />
    }

    const renderData = () => {
        return <Goal goal={goal} />
    }

    const editable = goal && (
      (
        // Admin and manager on solo goals
        goal.editable && account.role.code !== 'C'
      ) || (
        // Admin on team goals
        goal && account.role.code === 'A'
      )
    )

    return (
        <div>
            <div className={classes.root}>
                { loading && renderLoader() }
                { !loading && goal && renderData() }
            </div>
            <RoundedTabs value={!activateRank && value === 0 ? 1 : value} onChange={handleChange} variant='fullWidth'>
                { activateRank && <RoundedTab value={0} label={intl.formatMessage({id: "admin.goal.detail.rank_tab"})} /> }
                <RoundedTab value={1} label={intl.formatMessage({id: "admin.goal.detail.indication_tab"})} />
                { editable && <RoundedTab value={2} label={intl.formatMessage({id: "common.edit"})} /> }
            </RoundedTabs>
        </div>
    )
}

const mapStateToProps = ({ teamGoalDetail, teamGoalRankList, accountDetail }) => ({
    accountDetail,
    teamGoalDetail,
    teamGoalRankList
})

export default connect(mapStateToProps)(withStyles(styles)(SubHeader))
