import * as Resources from '../../../../Resources'

const homePages = (account, intl) => ([
  {
    path: 'goals',
    title: intl.formatMessage({id: "admin.goal.title"}),
    availability: ['C', 'M']
  },
  {
    path: 'challenges',
    title: account.challengeWording || intl.formatMessage({id: "challenge.title"}),
    availability: ['C', 'M']
  },
  {
    path: 'collaborators/:id/detail',
    title: intl.formatMessage({id: "account.title"}),
    availability: ['C']
  },
  {
    path: 'rankings',
    title: intl.formatMessage({id: "ranking.title"}),
    availability: ['C', 'M']
  },
])

export {
  homePages
};
