import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import { useClearCache } from 'react-clear-cache'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../../../components'
import configureStore from "../../../../store/configureStore";
import {useIntl} from 'react-intl'

const styles = {
  reloadIcon: {
    cursor: 'pointer'
  }
}

const RefreshButton = ({color, ...props}) => {
  const intl = useIntl()
  const { emptyCacheStorage } = useClearCache();
  const { classes } = props;
  const { store, persistor } = configureStore();
  return(
    <Button className={ classes.reloadIcon} color={color} onClick={ () => {
        persistor.purge().then(() => {
          localStorage.clear();
          emptyCacheStorage()
        })
      } } >
      <FontAwesomeIcon icon={ faSyncAlt } />
      &nbsp;
      {intl.formatMessage({id: 'common.refresh_button'})}
    </Button>
  )
}

export default withStyles(styles)(RefreshButton)
