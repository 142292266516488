import * as types from './actionTypes'

export const getTeamGroupTree = (full) => {
    return {
        type: types.GET_TEAM_GROUP_TREE,
        full
    }
}

export const getTeamGroupTreeSuccess = (teamGroup) => {
    return {
        type: types.GET_TEAM_GROUP_TREE_SUCCESS,
        teamGroup
    }
}

export const getTeamGroupTreeError = () => {
    return {
        type: types.GET_TEAM_GROUP_TREE_ERROR
    }
}
