import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseye, faRandom, faSortAmountDown, faChartLine } from '@fortawesome/free-solid-svg-icons'
import { FullTableCell, RankEvolution, Table, TableBody, TableCell, TableChip, TableHead, TableHeadCell, TableRow, TableRowHighlight } from '../../../../components'
import * as Resources from '../../../../Resources'
import {useIntl} from 'react-intl'
import '../../../../helpers/NumberHelper'
import _ from 'lodash'

const TeamGoalRankList = ({ranks, teamId, account, goal, ...props}) => {
    const intl = useIntl()
    const hasPoints = _.get(goal, 'levels.length', 0) > 0
    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeadCell colSpan={2}>
                            <FontAwesomeIcon icon={faSortAmountDown} />
                        </TableHeadCell>
                        <TableHeadCell>{intl.formatMessage({id: "admin.goal.rank_list.player_column"})}</TableHeadCell>
                        <TableHeadCell style={{textAlign: 'center'}}>
                            <FontAwesomeIcon icon={faBullseye} />
                        </TableHeadCell>
                        { hasPoints && goal.allow_ranking_points && (

                          <TableHeadCell>{Resources.TEAM_GOAL_RANK_LIST_POINTS_COLUMN}</TableHeadCell>
                        )}
                        <TableHeadCell>
                            <FontAwesomeIcon icon={faRandom} />
                        </TableHeadCell>
                        { goal.allow_ranking_latest_value && (

                          <TableHeadCell style={{textAlign: 'center'}}>
                            <FontAwesomeIcon icon={faChartLine} />
                          </TableHeadCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    { ranks.map(rank => {
                        const selected = rank.team ? rank.team.id == teamId : false
                        const color = !selected ? 'default' : 'primary'
                        const TableRowComponent = selected ? TableRowHighlight : TableRow
                        return (
                            <TableRowComponent>
                                <FullTableCell style={{backgroundColor: rank.team.color.hex, width: 4}} />
                                <TableCell>
                                    <TableChip label={rank.rank ? rank.rank : '-'} />
                                </TableCell>
                                <TableCell color={color}>{rank.team.name}</TableCell>
                                <TableCell color={color} style={{textAlign: 'center'}}>
                                  <div>
                                    {rank.progression.toPercentage()}
                                  </div>
                                  {(_.get(account, 'role.code') === 'A' || (_.get(account, 'role.code') === 'M' && _.get(account, 'team.id') === _.get(rank, 'team.id'))) && (
                                    <div style={{fontSize: 10, opacity: 0.8}}>
                                      {rank.counter}&nbsp;/&nbsp;{rank.target}
                                    </div>
                                  ) }


                                </TableCell>
                                { hasPoints && goal.allow_ranking_points && (
                                  <TableCell color={color}>{rank.points}</TableCell>
                                )}
                                <TableCell>
                                    <RankEvolution evolution={rank.evolution} />
                                </TableCell>
                                { goal.allow_ranking_latest_value && (
                                  <TableCell color={color} style={{textAlign: 'center', color: rank.latest_result ? '#00E58D' : 'auto'}}>
                                    {rank.latest_result ? `+${rank.latest_result}` : 0}
                                  </TableCell>
                                )}
                            </TableRowComponent>
                        )
                    }) }
                </TableBody>
            </Table>
        </div>
    )
}

export default TeamGoalRankList
