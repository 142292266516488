import React from 'react'
import {Grid} from '@material-ui/core'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {AccentText, Badge, BoldSpan, Button, Card, DefaultText, DefaultTitle, ErrorText} from '../../../../components'
import { useIntl } from 'react-intl'
import '../../../../helpers/StringHelper'
import * as Resources from '../../../../Resources'

const PointSummary = ({points, usedPoints, validatedValues, waitingPoints, onTrackingClick, orders, ...props}) => {
    const intl = useIntl()
    const usablePoints = points - usedPoints - waitingPoints
    const usablePointsText = intl.formatMessage({id: "point_summary.usable_points_value"}).format(usablePoints)
    const alignItems = isWidthUp('sm') ? 'flex-end' : 'flex-start'

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <DefaultTitle>{intl.formatMessage({id: "point_summary.title"})}</DefaultTitle>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <DefaultText>{intl.formatMessage({id: "point_summary.points_label"})} : <BoldSpan component='span'>{intl.formatMessage({id: "point_summary.points_value"}).format(points)}</BoldSpan></DefaultText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DefaultText>{intl.formatMessage({id: "point_summary.used_points_label"})} : <BoldSpan component='span'>{intl.formatMessage({id: "point_summary.used_points_value"}).format(usedPoints)}</BoldSpan></DefaultText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DefaultText>{intl.formatMessage({id: "point_summary.validated_values_label"})} : <BoldSpan component='span'>{intl.formatMessage({id: "point_summary.validated_values_value"}).format(validatedValues)}</BoldSpan></DefaultText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DefaultText>{intl.formatMessage({id: "point_summary.waiting_points_label"})} : <BoldSpan component='span'>{intl.formatMessage({id: "point_summary.waiting_points_value"}).format(waitingPoints)}</BoldSpan></DefaultText>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm='auto'>
                                <Grid container direction='column' justify='space-between' alignItems={alignItems} style={{height: '100%'}}>
                                    <Grid item>
                                        {usablePoints >= 0 && <AccentText>{intl.formatMessage({id: "point_summary.usable_points_label"})} : <BoldSpan>{usablePointsText}</BoldSpan></AccentText>}
                                        {usablePoints < 0 && <ErrorText>{intl.formatMessage({id: "point_summary.usable_points_label"})} : <BoldSpan>{usablePointsText}</BoldSpan></ErrorText>}
                                    </Grid>
                                    {onTrackingClick && <Grid item>
                                        <Badge badgeContent={orders} color='secondary'>
                                            <Button onClick={onTrackingClick}>{intl.formatMessage({id: "point_summary.orders_button"})}</Button>
                                        </Badge>
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default withWidth()(PointSummary)
