import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth'
import { SubHeader } from './components'
import '../../../../helpers/DateHelper'
import { ChallengeCondition, TeamChallengeRankList } from '../../components'
import {IconButton, MainLayoutComponent, Dialog, DialogActions, DialogContent, DialogTitle, Button, ProgressButton} from '../../../../components'
import { Menu, MenuItem, ListItemText, ListItemIcon } from '@material-ui/core'
import {useIntl, injectIntl} from 'react-intl'
import * as Resources from '../../../../Resources'
import * as teamChallengeDetailActions from '../../../../services/TeamChallenges/TeamChallengeDetail/actions'
import * as teamChallengeGoalListActions from '../../../../services/TeamChallengeGoals/TeamChallengeGoalList/actions'
import * as teamChallengeRankListAction from '../../../../services/TeamChallengeRanks/TeamChallengeRankList/actions'
import * as challengeDeleteActions from '../../../../services/Challanges/ChallengeDelete/actions'
import {Tooltip} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCopy, faEdit, faSlidersH, faTrash, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'

const styles = {
    iconMargin: {
        marginLeft: 16
    }
};

class TeamChallengeDetail extends MainLayoutComponent {
    constructor(props) {
        super(props);
        const { account } = this.props.accountDetail;
        this.initialized = false;
        this.state = {
            page: account.hasChallengeRankAccess && account.hasTeamRankAccess ? 0 : 1,
            mobileMenuAnchor: null
        }
    }

    handleDuplicate() {
        const { challenge } = this.props.teamChallengeDetail;
        this.props.history.push(`/challenges/duplication/${challenge.sourceId}`)
    }

    handleEdit() {
        const { challenge } = this.props.teamChallengeDetail;
        this.props.history.push(`/challenges/modification/${challenge.sourceId}`)
    }

    async onDelete() {
        const { challenge } = this.props.teamChallengeDetail;

        await this.props.challengeDeleteActions.deleteChallenge(challenge);
        this.props.history.goBack();
    }
    setDeletePromptOpen(isOpen) {
      this.setState({
        ...this.state,
        deletePromptOpen: isOpen
      })
    }

    handlePageChange(page) {
        this.setState({
            ...this.state,
            page: page
        })
    }

    setMobileMenuAnchor = (el) => {
      this.initialized = false
      this.setState({
        ...this.state,
        mobileMenuAnchor: el
      })
    }

    handleResize = () => {
      if(this.mobileScreen !== this.props.width) {
        this.initialized = false
        this.mobileScreen = this.props.width
      }
    }

    componentDidMount() {
        const {intl} = this.props
        const { account } = this.props.accountDetail;
        const id = this.props.match.params.id;

        this.props.handleTitle(account.challengeWording || intl.formatMessage({id: "challenge.title"}));
        this.props.handleSubHeader(<SubHeader onChange={this.handlePageChange.bind(this)} activateRank={account.hasChallengeRankAccess && account.hasTeamRankAccess} />);
        this.props.handleMaxWidth('md');
        this.props.activateReturn();
        this.props.teamChallengeDetailActions.getTeamChallengeDetail(id);
        this.props.teamChallengeGoalListActions.getTeamChallengeGoalList(id);
        this.props.teamChallengeRankListAction.getTeamChallengeRankList(id)
        window.addEventListener('resize', this.handleResize);
        this.mobileScreen = this.props.width
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { intl } = this.props
        const { challenge } = this.props.teamChallengeDetail;

        const mobileScreen = isWidthDown('xs', this.props.width)

        if (!this.initialized && challenge) {
            const { account } = this.props.accountDetail;
            const { classes } = this.props;
            this.initialized = true;


            const teamIds = _.get(account, 'team.id') ? [_.get(account, 'team.id')] : _.get(account, 'team_group.allTeamIds')
            const includesManagerTeam = challenge.participantTeamIds.length === 1 && challenge.participantTeamIds.filter(id => teamIds.includes(id)).length >= 0
            const canEdit = account.hasManagerChallengeEditAccess && includesManagerTeam || account.role.code === 'A'

            const desktopMenu = (
              <div>
                {
                  canEdit && (
                    <Tooltip title={intl.formatMessage({id: "common.duplicate"})}>
                      <IconButton size={'small'} onClick={this.handleDuplicate.bind(this)}><FontAwesomeIcon icon={faCopy}/></IconButton>
                    </Tooltip>
                  )
                }
                { canEdit && challenge.end.toDate2().getTime() > new Date().getTime() &&
                  (
                    <React.Fragment>
                      <Tooltip title={intl.formatMessage({id: "common.delete"})}>
                        <IconButton size={'small'} onClick={() => this.setDeletePromptOpen(true)} className={classes.iconMargin}><FontAwesomeIcon icon={faTrash}/></IconButton>
                      </Tooltip>
                      <Tooltip title={intl.formatMessage({id: "common.edit"})}>
                        <IconButton size={'small'} onClick={this.handleEdit.bind(this)} className={classes.iconMargin}><FontAwesomeIcon icon={faEdit}/></IconButton>
                      </Tooltip>
                    </React.Fragment>
                  )
                }

              </div>
            )
            const open = Boolean(this.state.mobileMenuAnchor)
            const arrowIcon = open ? faChevronUp : faChevronDown
            const mobileMenu = (
              <div>
                <IconButton
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  size={'small'}
                  onClick={(event) => this.setMobileMenuAnchor(open ? null : event.currentTarget)}
                  className={classes.iconMargin}
                >
                  <FontAwesomeIcon icon={arrowIcon}/>
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={this.state.mobileMenuAnchor}
                  open={open}
                  onClose={() => this.setMobileMenuAnchor()}
                  elevation={0}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  style={{marginTop: 30}}
                >
                  {
                    canEdit && (
                      <MenuItem onClick={this.handleDuplicate.bind(this)}>
                        <ListItemIcon style={{color: '#333', minWidth: 0, marginRight: 10}}>
                          <FontAwesomeIcon icon={faCopy}/>
                        </ListItemIcon>
                        <ListItemText>
                          {intl.formatMessage({id: "common.duplicate"})}
                        </ListItemText>
                      </MenuItem>
                    )
                  }
                  { canEdit && challenge.end.toDate2().getTime() > new Date().getTime() &&
                    (
                      <React.Fragment>
                        <MenuItem onClick={() => this.setDeletePromptOpen(true)}>
                          <ListItemIcon style={{color: '#333', minWidth: 0, marginRight: 10}}>
                            <FontAwesomeIcon icon={faTrash}/>
                          </ListItemIcon>
                          <ListItemText>
                            {intl.formatMessage({id: "common.delete"})}
                          </ListItemText>
                        </MenuItem>
                        <MenuItem onClick={this.handleEdit.bind(this)}>
                          <ListItemIcon style={{color: '#333', minWidth: 0, marginRight: 10}}>
                            <FontAwesomeIcon icon={faEdit}/>
                          </ListItemIcon>
                          <ListItemText>
                            {intl.formatMessage({id: "common.edit"})}
                          </ListItemText>
                        </MenuItem>

                      </React.Fragment>
                    )
                  }

                </Menu>
              </div>
            )
            this.props.handleButtons(
              mobileScreen ? mobileMenu : desktopMenu
            );
        }
    }

    render() {
        const { account } = this.props.accountDetail;
        const { challenge } = this.props.teamChallengeDetail;
        const { goals } = this.props.teamChallengeGoalList;
        const { ranks, loading } = this.props.teamChallengeRankList;

        if(!account.hasChallengeAccess) {
          return <Redirect to={'/'} />
        }

        return (
            <div>
                { account.hasChallengeRankAccess && account.hasTeamRankAccess && this.state.page == 0 && challenge && ranks && <TeamChallengeRankList ranks={ranks} teamId={challenge.teamId} /> }
                { this.state.page == 1 && challenge && goals && <ChallengeCondition challenge={challenge} goals={goals} /> }
                <Dialog open={this.state.deletePromptOpen} onClose={() => this.setDeletePromptOpen(false)}>
                    <DialogTitle>Êtes-vous sûr de vouloir supprimer ce challenge ?</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => this.setDeletePromptOpen(false)} color='secondary'>Non</Button>
                        <ProgressButton type='button' text='Oui' loading={loading} onClick={this.onDelete.bind(this)}/>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = ({ accountDetail, teamChallengeDetail, teamChallengeGoalList, teamChallengeRankList }) => ({
    accountDetail,
    teamChallengeDetail,
    teamChallengeGoalList,
    teamChallengeRankList
});

const mapDispatchToProps = (dispatch) => ({
    teamChallengeDetailActions: bindActionCreators(teamChallengeDetailActions, dispatch),
    teamChallengeGoalListActions: bindActionCreators(teamChallengeGoalListActions, dispatch),
    teamChallengeRankListAction: bindActionCreators(teamChallengeRankListAction, dispatch),
    challengeDeleteActions: bindActionCreators(challengeDeleteActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(injectIntl(TeamChallengeDetail))))
