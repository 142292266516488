import instance from '../instance'

const baseUrl = 'users/';

const users = {
    create(user) {
        user.isActive = true;
        return instance.post(baseUrl, user)
    },
    detail(id) {
        const url = `${baseUrl}${id}/`;
        return instance.get(url)
    },
    import(request) {
        const url = `${baseUrl}import/`;
        return instance.post(url, request)
    },
    export(request, isActive) {
        const url = `${baseUrl}export?isActive=${isActive}`;
        return instance.get(url, request)
    },
    list(isActive) {
        const url = `${baseUrl}?isActive=${isActive}`;
        return instance.get(url)
    },
    update(user) {
        const url = `${baseUrl}${user.id}/`;
        return instance.patch(url, {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            role: user.role,
            team: user.team,
            locale: user.locale
        })
    },
    updateActivation(id, isActive) {
        const url = `${baseUrl}${id}/`;
        return instance.patch(url, { isActive })
    },
    updatePassword(id, password) {
        if(id) {
          const url = `${baseUrl}${id}/password/`;
          return instance.post(url, { password })
        }
        return new Promise()
    },
    updateTeam(id, team) {
        const url = `${baseUrl}${id}/`;
        return instance.patch(url, { team })
    },
    updateTeamGroup(id, teamGroup) {
        const url = `${baseUrl}${id}/`;
        return instance.patch(url, { team_group: teamGroup })
    },
    saveConnection() {
        const url = `${baseUrl}save-connection/`;
        return instance.post(url)
    }
};

export default users
