import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { AdministratorCollaboratorSelector, MainLayoutComponent, ManagerCollaboratorSelector } from '../../../../components'
import {useIntl, injectIntl} from 'react-intl'

class BadgeHome extends MainLayoutComponent {
    componentDidMount() {
        const {intl} = this.props
        const { account } = this.props.accountDetail;
        this.props.handleTitle(account.badgeWording || intl.formatMessage({id: "badge.title"}))
    }

    handleClick(id) {
        this.props.history.push(`/badges/collaborator/${id}`)
    }

    render() {
        const { account } = this.props.accountDetail;

        if(!account.hasBadgeAccess) {
          return <Redirect to={'/'} />
        }
        return (
            <div>
                { account.role.code == 'C' && <Redirect to={`/badges/collaborator/${account.id}`} /> }
                { account.role.code == 'M' && <ManagerCollaboratorSelector onClick={this.handleClick.bind(this)} /> }
                { (account.role.code == 'A' || account.role.code == 'S') && <AdministratorCollaboratorSelector contextUrl="badges/" onClick={this.handleClick.bind(this)} /> }
            </div>
        )
    }
}

const mapStateToProps = ({ accountDetail }) => ({
    accountDetail
});

export default connect(mapStateToProps)(injectIntl(BadgeHome))
