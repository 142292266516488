import instance from '../instance'
import '../../../helpers/DateHelper'
import _ from 'lodash'
const baseUrl = 'team-groups/';

const teamGroups = {
    list() {
      return instance.get(baseUrl)
    },

    tree(full) {

      return instance.get(`${baseUrl}tree/${full ? '?full=true' : ''}`)
    },

    create(teamGroup) {
      return instance.post(baseUrl, teamGroup)
    },

    update(teamGroup) {
        const url = `${baseUrl}${teamGroup.id}/`;
        return instance.put(url, teamGroup)
    },
    delete(id) {
        const url = `${baseUrl}${id}/`;
        return instance.delete(url)
    },
};

export default teamGroups
