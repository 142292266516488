import * as types from './actionTypes';
import initialState from '../../../store/initialState';

const CollaboratorBadgeSummaryDetail = (state = initialState.collaboratorBadgeSummaryDetail, action) => {
    switch (action.type) {
        case types.GET_COLLABORATOR_BADGE_SUMMARY:
            return {...state, summary: null, loading: true, hasError: false};

        case types.GET_COLLABORATOR_BADGE_SUMMARY_SUCCESS:
            return {...state, summary: action.summary, loading: false, hasError: false};

        case types.GET_COLLABORATOR_BADGE_SUMMARY_ERROR:
            return {...state, summary: null, loading: false, hasError: false};

        default:
            return state;
    }
};

export default CollaboratorBadgeSummaryDetail
