import React, { useState } from 'react'
import { Grid, IconButton, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { useIntl } from 'react-intl'
import { Collaborator, Team, DefaultTitle, Button, TeamThumb, TeamGroup, Card } from '../../..'

import _ from 'lodash'

const styles = {
  thumbnail: {
    borderRadius: 20,
    zIndex: 10,
  },
  title: {
    fontSize: 17,
    textAlign: 'center',
    margin: '5px 0'
  },
  boxWrapper: {
    padding: '15px',
    borderRadius: '6px',
    background: "#f7f8fc",
    height: '100%'
  },
  item: {
    marginBottom: 10,
    position: 'relative',
    zIndex: 10,
    maxWidth: 'calc(100vw - 100px)',
    "&:last-of-type": {
      marginBottom: '0'
    }
  },
  itemIcon: {
    position: 'absolute',
    right: 10,
    top: '50%',
    marginTop: '-10px',
    zIndex: 40
  },
  teamItemIcon: {
    // right: 30,
    // top: 22,
    // zIndex: 10
  },
  addIcon: {
    color: '#00E58D'
  },
  deleteIcon: {
    color: '#E50000'
  },
  panelWrapper: {
    position: 'relative',
    width: '100%',
    marginBottom: '18px',
    "&:last-of-type": {
      marginBottom: '0'
    }
  },

  panel: {
      backgroundColor: 'initial',
      borderRadius: 'initial',
      boxShadow: 'none',
      position: 'relative',
      '&.MuiExpansionPanel-root:before': {
        display: 'none'
      },
      '& .MuiExpansionPanelSummary-expandIcon': {
        position: 'absolute',
        left: '135px',
        top: '22px',
      },
      '& .MuiExpansionPanelSummary-expandIcon.Mui-expanded': {
        top: '16px',
      },
      '& .MuiExpansionPanelSummary-root': {
        zIndex: 20,
        height: '64px',
        marginRight: '42px',
      },
  },
  panelGroup: {
    position: 'relative',
    '& .MuiExpansionPanelSummary-expandIcon': {
      left: '160px',
    },
  },

  panelSummary: {
      marginTop: "-80px",
      padding: 'initial',
      position: 'relative'
  },
  panelSummaryContent: {
    position: 'absolute',

  },
  panelDetails: {
      padding: "10px 0 0 15px",
      zIndex: 5
  }
}

const TransferList = ({ listIn, selected, onChange, enableCollaboratorSelect, ...props }) => {
    const intl = useIntl()
    const { classes } = props
    const [selectedList, setSelectedList] = useState(selected || [])
    const defaultChoices = () => {
      const result = _.compact(listIn.map(team => {
          const collaborators = team.collaborators.filter(c => selectedList.filter(c2 => c.id === c2.id) <= 0)

          if(collaborators.length > 0) {
            return Object.assign(
              {},
              team,
              { collaborators: collaborators }
            )
          }
        }
      ))
      return result
    }

    const [choices, setChoices] = useState(defaultChoices() || [])


    const selectItem = (item) => {
      if(_.indexOf(selectedList, item) < 0) {
        setSelectedList([item, ...selectedList])
      }
    }

    const removeItem = (item) => {
      if(_.indexOf(selectedList.map(i => i.id), item.id) >= 0) {
        setSelectedList(selectedList.filter(selectedItem => selectedItem.id !== item.id))
      }
    }

    const removeList = (items) => {
      setSelectedList(selectedList.filter(selectedItem => items.map(item => item.id).indexOf(selectedItem.id) < 0))
    }

    const addList = (items) => {
      setSelectedList([...items, ...selectedList])
    }

    const emptySelected = () => {
      setSelectedList([])
    }

    const getListByTeam = (collaborators) => {
      return _.compact(listIn.map(team => {
        const selectedCollaborators = team.collaborators.filter(team_collaborator => _.intersection(team.collaborators.map(c => c.id), collaborators.map(c => c.id)).indexOf(team_collaborator.id) >= 0)

        if(selectedCollaborators.length > 0) {
          return Object.assign(
            {},
            team,
            { collaborators: selectedCollaborators }
          )
        }
      }))
    }

    React.useEffect(() => {
      onChange(selectedList)
      setChoices(defaultChoices())
    }, [selectedList])

    React.useEffect(() => {
      if(_.differenceBy(_.sortBy(selected, 'id'), _.sortBy(selectedList, 'id'), 'id').length > 0 || selected.length !== selectedList.length) {
        setSelectedList(selected)
      }
    }, [selected])

    const selectedListByTeam = getListByTeam(selectedList)
    const selectedTeamGroups = _.uniqBy(selectedListByTeam.map(team => team.parent), 'id')
    const choicesTeamGroups = _.uniqBy(choices.map(team => team.parent), 'id')

    // console.log(selectedList);
    return (
      <Grid container direction="column" spacing={1}>

        <Grid item xs>
          <Grid container direction="row" spacing={4} justify="space-between">
            <Grid item xs={12} sm={6} container direction="column">

                <Grid item>
                  <DefaultTitle className={ classes.title }>
                    {intl.formatMessage({id: 'transfer_list.selection'})}
                  </DefaultTitle>
                </Grid>
                <Grid xs item className={ classes.boxWrapper }>
                  { choicesTeamGroups.map(teamGroup => {
                    const teamGroupChoices = choices.filter(team => team.parent.id === teamGroup.id)
                    return (
                      <React.Fragment>
                        <div className={ classes.panelWrapper }>
                          <div style={{position: 'static'}}>
                            <div className={ classes.item }>
                              <Card className={ classes.thumbnail }>
                                <TeamGroup team={ Object.assign(teamGroup, {teams: teamGroupChoices}) } hideTeamGroupUsers teamNumber/>
                              </Card>
                            </div>

                            <ExpansionPanel className={`${classes.panel}`}>
                              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={`${classes.panelSummary} ${classes.panelGroup}`}>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails className={classes.panelDetails}>
                                <Grid container >
                                  { teamGroupChoices.map((choice, choiceKey) => (

                                    <div className={ classes.panelWrapper }>
                                      <div style={{position: 'static'}}>
                                        <div className={ classes.item }>
                                          <TeamThumb team={ choice } />
                                          <IconButton size='small' onClick={() => addList(choice.collaborators)} className={ classes.itemIcon } >
                                            <FontAwesomeIcon icon={faPlus} className={ classes.addIcon } />
                                          </IconButton>
                                        </div>
                                        { enableCollaboratorSelect && (

                                          <ExpansionPanel className={classes.panel}>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails className={classes.panelDetails}>
                                              <Grid container key={choiceKey}>
                                                { choice.collaborators.map((collaborator, collaboratorKey) => (
                                                  <Grid item className={ classes.item } style={{ width: '100%' }}>
                                                    <Collaborator key={collaboratorKey} collaborator={collaborator} noAnimation />
                                                    <IconButton size='small' onClick={() => selectItem(collaborator)} className={ classes.itemIcon } >
                                                      <FontAwesomeIcon icon={faPlus} className={ classes.addIcon } />
                                                    </IconButton>
                                                  </Grid>
                                                )) }
                                              </Grid>
                                            </ExpansionPanelDetails>
                                          </ExpansionPanel>
                                        )}
                                      </div>
                                    </div>
                                  )) }

                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>

                          </div>
                        </div>

                      </React.Fragment>
                  )})}

                </Grid>

            </Grid>
            <Grid item xs={12} sm={6} container direction="column">

                <Grid item>
                  <DefaultTitle className={ classes.title }>
                    {intl.formatMessage({id: 'transfer_list.participants'})}
                  </DefaultTitle>
                </Grid>
                <Grid xs item className={ classes.boxWrapper }>
                  { selectedTeamGroups.map(teamGroup => {
                    const teamGroupSelected = selectedListByTeam.filter(team => team.parent.id === teamGroup.id)
                    return (

                      <React.Fragment>
                        <div className={ classes.panelWrapper }>
                          <div style={{position: 'static'}}>
                            <div className={ classes.item }>
                              <Card className={ classes.thumbnail }>
                                <TeamGroup team={ Object.assign(teamGroup, {teams: teamGroupSelected}) } hideTeamGroupUsers teamNumber/>
                              </Card>
                            </div>

                            <ExpansionPanel className={`${classes.panel}`}>
                              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={`${classes.panelSummary} ${classes.panelGroup}`}>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails className={classes.panelDetails}>
                                <Grid container >

                                  { teamGroupSelected.map((team, teamKey) => (
                                    <React.Fragment>
                                      <div className={ classes.panelWrapper }>
                                        <div style={{position: 'static'}}>
                                          <div className={ classes.item }>
                                            <TeamThumb team={ team }/>
                                            <IconButton size='small' onClick={() => removeList(team.collaborators)} className={ `${classes.itemIcon} ${classes.teamItemIcon}` } >
                                              <FontAwesomeIcon icon={faMinus} className={ classes.deleteIcon } />
                                            </IconButton>
                                          </div>
                                          { enableCollaboratorSelect && (
                                            <ExpansionPanel className={classes.panel}>
                                              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
                                              </ExpansionPanelSummary>
                                              <ExpansionPanelDetails className={classes.panelDetails}>
                                                <Grid container key={teamKey}>
                                                  { team.collaborators.map((collaborator, collaboratorKey) => (
                                                    <Grid item className={ classes.item } style={{ width: '100%' }}>
                                                      <Collaborator key={collaboratorKey} collaborator={collaborator} noAnimation />
                                                      <IconButton size='small' onClick={() => removeItem(collaborator)} className={ classes.itemIcon } >
                                                        <FontAwesomeIcon icon={faMinus} className={ classes.deleteIcon } />
                                                      </IconButton>
                                                    </Grid>
                                                  )) }
                                                </Grid>
                                              </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                          )}
                                        </div>
                                      </div>


                                    </React.Fragment>
                                  )) }
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>

                          </div>
                        </div>

                      </React.Fragment>
                  )})}
                </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    )
}

export default withStyles(styles)(TransferList)
